import React, { useState } from "react";
import { Button, ColorPicker, Form, Input, Select, Spin, message } from "antd";
import { Image as Images } from "../Images";
import { CREATE_CAR } from "../../gqloperations/cars/add-car-mutation";
import { isApolloError, useMutation } from "@apollo/client";
import { connect, useDispatch } from "react-redux";
import { setSuccessAlert } from "../../redux/successModal/successModal.action";
import Years from "../../utils/years.json";
import "../../assets/styles/add-car.scss";

const AddCar = (props) => {
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const dispatch = useDispatch();
  const shopId = localStorage.getItem("shopId");
  const [image, setImage] = useState("");
  const [exteriorColor, setExteriorColor] = useState("");
  const [interiorColor, setInteriorColor] = useState("");
  const [createCar, { loading }] = useMutation(CREATE_CAR);

  const onFinish = (values) => {
    // props.handleNext();
    console.log("value", values);
    values.mileage = String(values.mileage);
    values.shopId = shopId;
    createCar({ variables: values })
      .then((res) => {
        console.log("res", res);
        dispatch(
          setSuccessAlert({
            successAlertMsg: "Your Restoration has been added successfully.",
            successAlertOpen: true,
          })
        );
        // localStorage.setItem("carId", res?.data?.createCarFromShop?.car?.id);
        props.handleNext(res?.data?.createCarFromShop?.car?.id);
      })
      .catch((e) => {
        if (isApolloError(e)) {
          for (const gqlError of e.graphQLErrors) {
            console.log("gq", gqlError);
            message.error(gqlError.message);
          }
        }
      });
  };

  const handleFormChange = () => {
    const hasErrors =
      !(
        form.isFieldTouched("heroImageBase64") &&
        form.isFieldTouched("chassis") &&
        form.isFieldTouched("engine") &&
        form.isFieldTouched("fuel") &&
        form.isFieldTouched("mileage") &&
        form.isFieldTouched("transmission") &&
        form.isFieldTouched("bodyStyle") &&
        form.isFieldTouched("driveType") &&
        form.isFieldTouched("make") &&
        form.isFieldTouched("model") &&
        form.isFieldTouched("vin") &&
        form.isFieldTouched("year") &&
        form.isFieldTouched("exteriorColor") &&
        form.isFieldTouched("interiorColor")
      ) ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;

    setDisabledSave(hasErrors);
  };

  const handleChange = (name, e, type) => {
    const TestRegx = {
      number: new RegExp(`^[0-9]*$`),
      alphaNumaric: new RegExp(`^[a-zA-Z0-9s ]*$`),
      letters: new RegExp(`^[a-zA-Z ]*$`),
      all: new RegExp(`^[ A-Za-z0-9_@./#&+-]*$`),
      float: new RegExp(`^\[0-9]{1,5}[.]?\[0-9]{0,2}$`),
    };
    if (TestRegx[type].test(e.target.value)) {
      form.setFieldValue(name, e.target.value.trim());
      form.setFields([{ name: name, touched: true }]);
    } else {
      form.setFieldValue(name, e.target.value.slice(0, -1));
    }
    handleFormChange();
  };

  const handleFileUpload = (event) => {
    // clean up earliest files
    let myFiles = {};
    // set state of files to false until each of them is processed

    const files = event?.nativeEvent.srcElement?.files;
    const filePromises = Object.entries(files).map((item) => {
      return new Promise((resolve, reject) => {
        const [, file] = item;
        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = function (event) {
          // Convert file to Base64 string
          // btoa is built int javascript function for base64 encoding
          myFiles["picture"] = btoa(event?.target.result);

          resolve();
        };
        reader.onerror = function () {
          reject();
        };
      });
    });
    Promise.all(filePromises)
      .then(() => {
        if (!myFiles.hasOwnProperty("picture")) {
          form.setFieldValue(
            "heroImageBase64",
            `data:image/png;base64,${image}`
          );
          setImage("");
        }
        if (myFiles?.picture) {
          form.setFieldValue(
            "heroImageBase64",
            `data:image/png;base64,${myFiles?.picture}`
          );
          setImage(myFiles?.picture);
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("something wrong happened");
      });
  };

  return (
    <div className="row">
      <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mx-auto text-center basic-details-auth">
        <h4>Add a Restoration</h4>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-8 mx-auto">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p style={{ color: "#D4D4D4" }}>Let's add a customers Car</p>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8 mx-auto common-form">
        <Spin
          className="full-screen-center-loader"
          tip={"Adding restoration.."}
          spinning={loading}
        >
          <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            // requiredMark={false}
            onFieldsChange={handleFormChange}
            form={form}
            scrollToFirstError
            initialValues={
              {
                // chassis: "Backbone",
                // engine: "XZ+",
                // fuel: "Diesel",
                // mileage: 15,
                // transmission: "Automatic",
                // bodyStyle: "SUV",
                // comment: "Test Comments",
                // driveType: "All Wheel Drive",
                // exteriorColor: "red",
                // interiorColor: "black",
                // make: "Mercedes",
                // model: "Mercedes AMG G-63",
                // vin: "NFVIDSBUSHG588",
                // year: "2013",
              }
            }
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="add-car-form"
          >
            <div className="row">
              <div className="col-12">
                <Form.Item
                  label="Car Profile Image"
                  name="heroImageBase64"
                  className="text-center"
                  rules={[
                    {
                      required: true,
                      message: "Please Upload Image!",
                    },
                  ]}
                  onChange={() => setImage(image)}
                >
                  <div className="upload-btn-wrapper">
                    {
                      <img
                        className="profile-file-upload-handle"
                        src={
                          image !== ""
                            ? `data:image/png;base64,${image}`
                            : "/assets/icons/profile-icon-white.png"
                        }
                        alt="logo"
                        width={140}
                        height={140}
                      />
                    }
                    <img
                      className="edit-image-icon"
                      src="/assets/icons/edit-image-icon.svg"
                      alt="edit image icon"
                    />
                    <input
                      className="profile-file-upload"
                      onChange={(e) => handleFileUpload(e)}
                      type="file"
                      name="myfile"
                      accept=".jpg, .jpeg, .png"
                    />
                  </div>
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item
                  label="Year"
                  name="year"
                  rules={[
                    {
                      required: true,
                      message: "Please input Year!",
                    },
                  ]}
                >
                  <Select
                    suffixIcon={
                      <img
                        src={Images.select_arrow_down}
                        alt=""
                        className="img-fluid"
                      />
                    }
                    placeholder="Select Year"
                    options={Years}
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item
                  label="Make"
                  name="make"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input Make!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Make" />
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item
                  label="Model"
                  name="model"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input Model!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Model" />
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input Description!",
                    },
                  ]}
                >
                  <Input.TextArea
                    className="textArea-address"
                    placeholder="Add Description"
                  />
                </Form.Item>
              </div>
              <div className="col-12 position-relative">
                <Form.Item
                  label="VIN"
                  name="vin"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input VIN!",
                    },
                  ]}
                >
                  <Input placeholder="Enter VIN" />
                </Form.Item>
                <div className="small-text-info">
                  <span>*Note: </span>
                  Only visible to Shop and Owner, not a public field.
                </div>
              </div>
              <div className="col-12 position-relative">
                <Form.Item
                  label="Chassis"
                  name="chassis"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input Chassis!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Chassis" />
                </Form.Item>
                <div className="small-text-info">
                  <span>*Note: </span>
                  Only visible to Shop and Owner, not a public field.
                </div>
              </div>
              <div className="col-12">
                <Form.Item
                  label="Mileage"
                  name="mileage"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input Mileage!",
                    },
                  ]}
                >
                  <Input
                    min={0}
                    onChange={(e) => handleChange("mileage", e, "float")}
                    placeholder="Enter Mileage"
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item
                  label="Exterior Color"
                  name="exteriorColor"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input Exterior Color!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Exterior Color" />
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item
                  label="Interior Color"
                  name="interiorColor"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input Interior Color!",
                    },
                  ]}
                >
                  <Input placeholder="Enter Interior Color" />
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item
                  label="Drive Type"
                  name="driveType"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please Select Drive Type!",
                    },
                  ]}
                >
                  <Select
                    suffixIcon={
                      <img
                        src={Images.select_arrow_down}
                        alt=""
                        className="img-fluid"
                      />
                    }
                    placeholder="Select Drive Type"
                    options={[
                      {
                        value: "Rear Wheel Drive",
                        label: "Rear Wheel Drive",
                      },
                      {
                        value: "Front Wheel Drive",
                        label: "Front Wheel Drive",
                      },
                      {
                        value: "All Wheel Drive",
                        label: "All Wheel Drive",
                      },
                    ]}
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item
                  label="Transmission"
                  name="transmission"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please Select Transmission!",
                    },
                  ]}
                >
                  <Select
                    suffixIcon={
                      <img
                        src={Images.select_arrow_down}
                        alt=""
                        className="img-fluid"
                      />
                    }
                    placeholder="Select Transmission"
                    options={[
                      {
                        value: "Manual",
                        label: "Manual",
                      },
                      {
                        value: "Automatic",
                        label: "Automatic",
                      },
                      {
                        value: "Sequential",
                        label: "Sequential",
                      },
                    ]}
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item
                  label="Body Style"
                  name="bodyStyle"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please Select Body Style!",
                    },
                  ]}
                >
                  <Select
                    suffixIcon={
                      <img
                        src={Images.select_arrow_down}
                        alt=""
                        className="img-fluid"
                      />
                    }
                    placeholder="Select Body Style"
                    options={[
                      {
                        value: "Coupe",
                        label: "Coupe",
                      },
                      {
                        value: "Sedan",
                        label: "Sedan",
                      },
                      {
                        value: "Convertible",
                        label: "Convertible",
                      },
                      {
                        value: "Truck",
                        label: "Truck",
                      },
                      {
                        value: "Station Wagon",
                        label: "Station Wagon",
                      },
                      {
                        value: "SUV",
                        label: "SUV",
                      },
                      {
                        value: "Off-road",
                        label: "Off-road",
                      },
                      {
                        value: "Other",
                        label: "Other",
                      },
                    ]}
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item
                  label="Engine"
                  name="engine"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please enter Engine!",
                    },
                  ]}
                >
                  <Input maxLength={20} placeholder="Engine" />
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item
                  label="Fuel"
                  name="fuel"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please Select Fuel Type!",
                    },
                  ]}
                >
                  <Select
                    suffixIcon={
                      <img
                        src={Images.select_arrow_down}
                        alt=""
                        className="img-fluid"
                      />
                    }
                    placeholder="Select Fuel Type"
                    options={[
                      {
                        value: "Gas",
                        label: "Gas",
                      },
                      {
                        value: "Diesel",
                        label: "Diesel",
                      },
                      {
                        value: "Electric",
                        label: "Electric",
                      },
                      {
                        value: "Hybrid",
                        label: "Hybrid",
                      },
                    ]}
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                <Form.Item
                  label="Add Comments"
                  name="comments"
                  // rules={[
                  //   {
                  //     required: true,
                  //     whitespace: true,
                  //     message: "Please input Add Comments!",
                  //   },
                  // ]}
                >
                  <Input.TextArea
                    className="textArea-address"
                    placeholder="Add Comments....."
                  />
                </Form.Item>
              </div>
              <div className="col-12">
                <Button
                  loading={loading}
                  className="theme-common-btn subscribe-btn w-100"
                  disabled={loading}
                  type="primary"
                  htmlType="submit"
                >
                  Continue
                </Button>
              </div>
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCar);
