import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { Image as Images } from "../../Images";
import { Link, useNavigate } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import axios from "axios";
import { GetCurrentManager } from "../../../gqloperations/getManager";

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
   
 const [disabledSave, setDisabledSave] = useState(true);

 const [form] = Form.useForm();

 const handleFormChange = () => {
  const hasErrors = !form.isFieldsTouched(true) ||
  form.getFieldsError().filter(({ errors }) => errors.length)
    .length > 0;
  setDisabledSave(hasErrors);
}

const onFinish = (values) => {
  console.log('Success:', values);
  userLogin(values);
};
const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};
  const userLogin = async (userData) => {
    setLoading(true);
    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_API_URL}v1/managers/sessions`,
        userData
      );
      const { data } = resp;
      console.log("data", data)
      localStorage.setItem("userDetails", JSON.stringify(data));
      localStorage.setItem("token", data.auth_token);
      localStorage.setItem("shopId", data.shop_id);
      localStorage.setItem("state", JSON.stringify(data?.state))
      message.success("Login successful");
      if(data?.shop_id === null || data?.state?.state === null){
        window.location.replace("/managers/create-profile?state=0"); 
      }else if(data.shop_id !== null && data?.state?.state < 2){
        window.location.replace(`/managers/create-profile?state=${data?.state?.state}`)
      }else if(data.shop_id !== null && data?.state?.state >= 2){
        window.location.replace("/managers/home"); 
      }
    } catch {
      message.error(
        "Something went wrong, please check your password or email"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-form-container mx-auto position-relative h-100">
      <div className="my-5 pb-5 auth-logo-section">
        <Link to={"/"}>
          <img src={Images.auth_logo} alt="" className="img-fluid" />
        </Link>
      </div>
      <div className="common-form mt-5 mt-md-4">
      <div className="basic-details-auth mb-5">
            <h4 className="mt-5">Sign in</h4>
            <p className="fw-normal" >Sign into your iONClassic shop account.</p>
      </div>
        <Form
          name="login-form"
          onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            onFieldsChange={handleFormChange}
            form={form}
            requiredMark={false}
            autoComplete="off"
        >
              <Form.Item
                label="Email"
                name="email"
                rules={[{
                  type: 'email',
                  message: 'Please enter a valid E-mail.',
                },
                {
                  required: true,
                  message: 'Please enter your E-mail',
                }]}
              >
                <Input placeholder="Enter Email" autoComplete={false} />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {required: true, message: "Please input your password!" },
                  {min:6, message:"Password must be of atlest 6 characters"},
                ]}
              >
                <Input.Password placeholder="Enter Password" autoComplete={false} />
              </Form.Item>
              <Form.Item className="text-end">
                <Link to={"/managers/reset-password"} className="auth-forgot-pwd">
                  Forgot Password?
                </Link>
              </Form.Item>
              <Form.Item>
              <Button
                className="theme-common-btn w-100"
                // disabled
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={
                  disabledSave
                }
              >
                Sign In
              </Button> 
              </Form.Item>
        </Form>
      </div>
      <div className="auth-footer-details">
        <p className="fw-normal">
          Don’t have an account: <Link to="/managers/sign-up">Sign Up</Link>
        </p>
      </div>
    </div>
  );
};

export default LoginForm;
