import { gql } from "@apollo/client";

export const ADD_CAR_IMAGE = gql`
  mutation addImageFromShop(
    $carId: ID!
    $imageBase64: [String!]!
    $carImageCategoryId: ID!
  ) {
    addImageFromShop(
      input: {
        carId: $carId
        imageBase64: $imageBase64
        carImageCategoryId: $carImageCategoryId
      }
    ) {
      carImage {
        id
        imageUrl
        carImageCategoryId
      }
    }
  }
`;
