import "./App.scss";
import { ConfigProvider, theme } from "antd";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import Login from "./components/auths/Login";
import SignUp from "./components/auths/SignUp";
import CreateProfile from "./components/CreateProfile";
import PrivatePath from "./utils/privatePath";
import ResetPassword from "./components/auths/ResetPassword";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import EditDetails from "./components/editdetails/EditDetails";
import Mechanics from "./components/mechanics/Mechanics";
import ViewVehicleDetails from "./components/ViewVehicleDetails/ViewVehicleDetails";
import Subscription from "./components/subscription/Subscription";
import Home from "./components/Home";
import Faq from "./components/Faq";
import ContactUs from "./components/ContactUs";
import "../src/assets/styles/responsive.css";
import AddMechanics from "./components/mechanics/addYourMechanics";
import ChangePassword from "./components/auths/ChangePassword";
import PrivacyPolicy from "./components/subscription/PrivacyPolicy";
import EnduserAgreement from "./components/subscription/EnduserAgreement";
import TermsCondition from "./components/subscription/TermsCondition";
import PublicPath from "./utils/publicPath";
import AddRestorationPage from "./pages/add-restoration";
import HomePage from "./pages/Home";
import ModalComponent from "./components/successModal";
import { connect } from "react-redux";
import { removeSuccessAlert } from "./redux/successModal/successModal.action";
import EditCar from "./pages/edit-restoration/EditCar";
import Form from "./components/common/Form";
import FormTwo from "./components/common/FormTwo";
import CarCategoriesList from "./pages/car-image-categories/car-categories-list";
import CarCategoryImages from "./pages/car-image-categories/car-category-images";
import EditUploadImage from "./pages/edit-restoration/EditUploadImage";
import ViewSlideShowHighLight from "./pages/view-slideshow";
import CardDetails from "./components/cardDetails/CardDetails";
import { Elements } from "@stripe/react-stripe-js"; 
import { loadStripe } from "@stripe/stripe-js";
import stripeConfig from "../../ion_classic_reactweb/src/components/profile-components/stripeConfig";


// const queryClient = new QueryClient()
const token = localStorage.getItem("token");
const client = new ApolloClient({
  // eslint-disable-next-line no-undef
  uri: process.env.REACT_APP_GRAPHQL_URL,
  cache: new InMemoryCache(),
  headers: {
    Authorization: `${token}` || "",
  },
});
const stripePromise = loadStripe(stripeConfig.apiKey);

function App({ successAlert }) {
  return (
    // <QueryClientProvider client={queryClient}>
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          components: {
            Input: {
              colorTextPlaceholder: "#0093ff",
            },
          },
        },
      }}
    >
      <ApolloProvider client={client}>
        <BrowserRouter>
          {/* <Router> */}
          <Routes>
            <Route
              path="/"
              element={
                <PublicPath>
                  <Home />
                </PublicPath>
              }
              exact
            />
            <Route
              path="/faq"
              element={
                <PublicPath>
                  <Faq />
                </PublicPath>
              }
              exact
            />
            <Route
              path="/contact-us"
              element={
                <PublicPath>
                  <ContactUs />
                </PublicPath>
              }
              exact
            />
            <Route
              path="/end-user-Agreement"
              element={
                <PublicPath>
                  <EnduserAgreement />
                </PublicPath>
              }
              exact
            />
            <Route
              path="/PrivacyPolicy"
              element={
                <PublicPath>
                  <PrivacyPolicy />
                </PublicPath>
              }
              exact
            />
            <Route
              path="/terms&conditions"
              element={
                <PublicPath>
                  <TermsCondition />
                </PublicPath>
              }
              exact
            />
            <Route
              path="/managers/sign-in"
              element={
                <PublicPath>
                  <Login />
                </PublicPath>
              }
              exact
            />
            <Route
              path="/managers/sign-up"
              element={
                <PublicPath>
                  <SignUp />
                </PublicPath>
              }
              exact
            />
            <Route
              path="/managers/home"
              element={
                <PrivatePath>
                  <HomePage />
                </PrivatePath>
              }
              exact
            />

            <Route
              path="/managers/password/edit"
              element={<ChangePassword />}
              exact
            />
            <Route
              path="/managers/reset-password"
              element={
                <PublicPath>
                  <ResetPassword />
                </PublicPath>
              }
              exact
            />
            <Route
              path="/managers/manage-subcription"
              element={
                <PrivatePath>
                  <Elements stripe={stripePromise}> 
                  <Form />
                </Elements>
                </PrivatePath>
              }
              exact
            />
             <Route
              path="/managers/manage-credit-card"
              element={
                <PrivatePath>
                   <Elements stripe={stripePromise}> 
                  <CardDetails/>
                  </Elements>
                </PrivatePath>
              }
              exact
            />
            <Route
              path="/managers/manage-subcription-form"
              element={
                <PrivatePath>
                  <FormTwo />
                </PrivatePath>
              }
              exact
            />
            <Route
              path="/managers/edit-details"
              element={
                <PrivatePath>
                  <EditDetails />
                </PrivatePath>
              }
              exact
            />

            <Route path="/managers/mechanics" element={<Mechanics />} exact />
            <Route
              path="/managers/add-mechanics"
              element={
                <PrivatePath>
                  <AddMechanics />
                </PrivatePath>
              }
              exact
            />
            <Route
              path="/managers/subscription"
              element={
                <PrivatePath>
                  <Subscription />
                </PrivatePath>
              }
              exact
            />
            <Route
              path="/managers/create-profile"
              element={
                <PrivatePath>
                  <CreateProfile />
                </PrivatePath>
              }
              exact
            />
            <Route
              path="/managers/add-restorations"
              element={
                <PrivatePath>
                  <AddRestorationPage />
                </PrivatePath>
              }
              exact
            />

            <Route
              path="/managers/home/details"
              element={
                <PrivatePath>
                  <ViewVehicleDetails />
                </PrivatePath>
              }
              exact
            />
            <Route
              path="/managers/home/details/view-slideshow"
              element={
                <PrivatePath>
                  <ViewSlideShowHighLight />
                </PrivatePath>
              }
              exact
            />
            <Route
              path="/managers/home/details/edit-details"
              element={
                <PrivatePath>
                  <EditCar />
                </PrivatePath>
              }
              exact
            />
            <Route
              path="/managers/home/details/categories"
              element={
                <PrivatePath>
                  <CarCategoriesList />
                </PrivatePath>
              }
              exact
            />
            <Route
              path="/managers/home/details/edit-images"
              element={
                <PrivatePath>
                  <EditUploadImage />
                </PrivatePath>
              }
              exact
            />
            <Route
              path="/managers/home/details/categories/images"
              element={
                <PrivatePath>
                  <CarCategoryImages />
                </PrivatePath>
              }
              exact
            />
          </Routes>
          <ModalComponent />
          {/* </Router> */}
        </BrowserRouter>
      </ApolloProvider>
    </ConfigProvider>
    // </QueryClientProvider>
  );
}

const mapStateToProps = (state) => {
  return {
    successAlert: state.successAlert,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeSuccessAlert: () => dispatch(removeSuccessAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
