import React from "react";
import "./paymentCard.scss";
import { Row, Col } from "antd"; // Import Ant Design components
import BrandIcon from "../brandIcon";

import { Image as Images } from "../../../components/Images";

const PaymentCard = ({ cardNumber, holderName, brand, expiration }) => {
  const cardStyles = {
    width: "398.148px",
    height: "250px",
    flexShrink: "0",
    backgroundImage: `url(${Images.paymentCardIcon})`,
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: "20px",
    borderRadius: "15px",
    boxSizing: "border-box",
    color: "#fff",
  };

  const rowStyles = {
    display: "flex",
    marginTop: "1.5rem",
    justifyContent: "space-between",
    alignItems: "center",
  };
  const brandIconContainerStyles = {
    width: "60px",
    height: "40px",
    display: "flex",
    alignItems: "center",
  };
  const imageChip = {
    width: "51.833px",
    height: "51.833px",
    marginBottom: "1rem",
  };
  const imagePaypass = {
    width: " 39.619px",
    height: "29.63px",
    marginLeft: "20rem",
  };

  return (
    <div style={cardStyles}>
      <div>
        <img src={Images.payPass} alt="PayPass" style={imagePaypass} />
        <img src={Images.chip} alt="Chip" style={imageChip} />
      </div>

      <div className="card-number">{cardNumber}</div>
      <div style={rowStyles}>
        <div className="card-holder">{holderName}</div>
        <div className="card-expiration">{expiration}</div>
        <div style={brandIconContainerStyles}>
          <BrandIcon brand={brand} />
        </div>
      </div>
    </div>
  );
};

export default PaymentCard;
