import { gql } from "@apollo/client";

export const UPDATE_SHOP_INFO = gql`
  mutation updateShopMutation(
    $id: ID!
    $companyName: String
    $address: String
    $city: String
    $state: String
    $zip: String
    $country: String
    $ownersName: String
    $phoneNumber: String!
    $profileImageBase64: String
    $services: [ServiceInput!]
    $clientMutationId: String
  ) {
    updateShop(
      input: {
        id: $id
        companyName: $companyName
        address: $address
        city: $city
        state: $state
        zip: $zip
        country: $country
        ownersName: $ownersName
        phoneNumber: $phoneNumber
        profileImageBase64: $profileImageBase64
        services: $services
        clientMutationId: $clientMutationId
      }
    ) {
      shop {
        id
        companyName
        address
        city
        state
        zip
        country
        ownersName
        phoneNumber
        profileImageUrl
      }
    }
  }
`;
