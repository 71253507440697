import React from "react";
import { Image as Images } from "../../components/Images";

const BrandIcon = ({ brand }) => {
  const brandIcons = {
    visa: Images.visa, // Replace with your image URLs
    mastercard: Images.mastercard,
    amex: Images.americanexpress,
    discover: Images.discover,
    jcb : Images.jcb,
    discover:Images.discover,
    unionpay:Images.unionpay,
    diners :Images.dinerclub
    // Add more brand names and their corresponding image URLs here
  };

  const iconSrc = brandIcons[brand];

  return <img src={iconSrc} alt={brand} />;
};

export default BrandIcon;
