import React, { useState } from "react";
import { Button, Carousel } from "antd";
import { Image as Images } from "../Images";

const BannerLeftSlider = () => {
  const carouselRef = React.createRef();
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselData = [
    "slider_banner_img_1",
    "slider_banner_img_2",
    "slider_banner_img_3",
    "slider_banner_img_4",
  ];

  const onChange = (currentSlide) => {
    console.log(currentSlide);
    setCurrentSlide(currentSlide);
  };

  return (
    <>
      <div className="slider-div-home position-relative">
        <div className="position-relative">
          <div className="overlay-shadow position-absolute" />
          <div className="slider-counter-div position-absolute">
            <ul className="list-inline">
              <li className="list-inline-item">
                <Button
                  onClick={() => {
                    !(currentSlide === 0) &&
                      carouselRef.current.goTo(currentSlide - 1);
                  }}
                  className="bg-transparent border-0 p-0 shadow-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="87"
                    height="12"
                    viewBox="0 0 87 12"
                    fill="none"
                  >
                    <path
                      id="Arrow 2"
                      opacity="0.5"
                      d="M0.469673 5.46967C0.176773 5.76256 0.176773 6.23744 0.469673 6.53033L5.24264 11.3033C5.53554 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53554 0.403806 5.24264 0.696699L0.469673 5.46967ZM87 5.25L1 5.25V6.75L87 6.75V5.25Z"
                      fill={currentSlide <= 0 ? "#D4D4D4" : "#FFFFFF"}
                      style={{ opacity: currentSlide > 0 && 1 }}
                    />
                  </svg>
                </Button>
              </li>
              <li className="list-inline-item">
                <span>0{currentSlide + 1}</span> / 0{carouselData.length}
              </li>
              <li className="list-inline-item">
                {console.log("carouselRef", carouselRef)}
                <Button
                  onClick={() => {
                    !(currentSlide === carouselData.length - 1) &&
                      carouselRef.current.goTo(currentSlide + 1);
                  }}
                  className="bg-transparent border-0 p-0 shadow-none"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="172"
                    height="12"
                    viewBox="0 0 172 12"
                    fill="none"
                  >
                    <path
                      d="M171.53 6.53035C171.823 6.23745 171.823 5.76258 171.53 5.46968L166.757 0.696714C166.464 0.40382 165.99 0.40382 165.697 0.696714C165.404 0.989607 165.404 1.46448 165.697 1.75737L169.939 6.00001L165.697 10.2427C165.404 10.5355 165.404 11.0104 165.697 11.3033C165.99 11.5962 166.464 11.5962 166.757 11.3033L171.53 6.53035ZM-6.55671e-08 6.75L171 6.75001L171 5.25001L6.55671e-08 5.25L-6.55671e-08 6.75Z"
                      fill={
                        currentSlide === carouselData.length - 1
                          ? "#D4D4D4"
                          : "#FFFFFF"
                      }
                      style={{
                        opacity:
                          currentSlide === carouselData.length - 1 ? 0.5 : 1,
                      }}
                    />
                  </svg>
                  {/* <img
                    src={Images.arrow_white_right_big}
                    alt=""
                    className="img-fluid"
                  /> */}
                </Button>
              </li>
            </ul>
          </div>
          <Carousel
            loop={false}
            afterChange={onChange}
            dots={false}
            enableSnap={true}
            ref={carouselRef}
          >
            {carouselData?.map((crImg, index) => {
              return (
                <div key={index}>
                  <img
                    width={"100%"}
                    src={Images[crImg]}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
        <div className="carousel-img-container">
          <div className="d-flex carousel-img-track">
            {carouselData?.map((crImg, index) => {
              return (
                <img
                  key={index}
                  onClick={() => carouselRef.current.goTo(index, "dontAnimate")}
                  src={Images[crImg]}
                  alt=""
                  className="img-fluid"
                />
              );
            })}
          </div>
          <button
            onClick={() => carouselRef.current.next()}
            className="arrow-right-slick position-absolute"
          >
            <img
              src={Images.fill_rounded_right_arrow_white}
              alt=""
              className="img-fluid"
            />
          </button>
        </div>
      </div>
    </>
  );
};

export default BannerLeftSlider;
