import React, { useState } from "react";
import "../../assets/styles/auths.scss";
import "../../assets/styles/form.scss";
import { Image as Images } from "../Images";
import { Button, Form, Input, message } from "antd";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const [emailSent, setEmailSent] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabledSave(hasErrors);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    onSubmit(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSubmit = (values) => {
    setDisabledSave(true);
    const obj = {
      ...values,
      template: "reset_password",
    };
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}v1/managers/forgot_password`, obj)
      .then((resp) => {
        message.success("Please check your email.");
        setEmailSent(true);
        setDisabledSave(false);
        console.log(resp, "resp");
      })
      .catch((err) => {
        message.error(err.response.data.errors[0]);
      })
      .finally(() => {
        setIsLoading(false);
        setDisabledSave(false);
      });
  };
  return (
    <div className="container-fluid auths-main-fluid auths-forgot-password">
      <div className="row mx-0 position-relative">
        <div className="auth-shadow-effect">
          <img src={Images.auth_shadow_effect} alt="" className="img-fluid" />
        </div>
        {!emailSent ? (
          <div className="auth-left-div">
            <div className="text-center auth-logo-section">
              <a href="#">
                <img src={Images.auth_logo} alt="" className="img-fluid" />
              </a>
            </div>
            <div className="text-center basic-details-auth mt-5 pt-5">
              <h4>Reset Password</h4>
              <p>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia.
              </p>
            </div>
            <div className="common-form common-form-forgot-pwd h-100 position-relative">
              <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete={false | "off"}
                layout="vertical"
                onFieldsChange={handleFormChange}
                form={form}
                requiredMark={false}
              >
                <div className="row">
                  <div className="col-12">
                    <Form.Item
                      label={false}
                      name="email"
                      rules={[
                        { type: "email", message: "Please enter valid email!" },
                        { required: true, message: "Please input your email!" },
                      ]}
                    >
                      <Input placeholder="Enter Your Email" />
                    </Form.Item>
                  </div>
                  <div className="col-12">
                    <Button
                      className="theme-common-btn w-100"
                      loading={isLoading}
                      type="primary"
                      htmlType="submit"
                      disabled={disabledSave}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </Form>
              <div className="text-center auth-footer-details footer-forgot-pwd">
                <p>
                  Already have an account:{" "}
                  <Link to="/managers/sign-in">Sign In</Link>
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-12 col-sm-6 mx-auto auth-left-div vh-100">
            <div className="row h-100 align-items-center">
              <div className="basic-details-confirm">
                <p>
                  We have shared reset password link on your email address,
                  please confirm it.
                </p>
                <Button
                  className="theme-common-btn w-100"
                  type="primary"
                  htmlType="submit"
                  onClick={() => navigate("/managers/sign-in")}
                >
                  Back to Login
                </Button>
              </div>
            </div>
          </div>
        )}

        {/* submit forgot email then show this screen*/}
      </div>
    </div>
  );
};

export default ResetPassword;
