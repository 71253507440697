import React, { useState ,useEffect} from "react";
import { Button, Form, Input, Select, message } from "antd";
import { isApolloError, useMutation } from "@apollo/client";
import { SEND_INVITE_MECHANICS } from "../../gqloperations/mechanics/send-invite.mutation";
import CountryData from "../../utils/country_dial_info.json";
import { preventNavigationBack, updateSignUpStatus } from "../../utils/helper";
import { useNavigate } from "react-router-dom";

const AddYourMechanics = (props) => {
  useEffect(() => {
    preventNavigationBack();
    return () => {
      window.onpopstate = null;
    };
  }, []);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [addMore, setAddMore] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const [countryCode, setCountryCode] = useState("US +1");
  const [sendInvitation, { data, loading, error }] = useMutation(
    SEND_INVITE_MECHANICS
  );
  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabledSave(hasErrors);
  };

  const onFinish = (values) => {
    values.phoneNumber = countryCode.split(" ")[1] + values.phoneNumber;
    sendInvitation({ variables: values })
      .then((response) => {
        console.log("res", response);
        if (addMore) {
          message.success("Invitation Sent successfully!");
          form.resetFields();
          console.log("a", addMore);
        } else {
          message.success("Invitation Sent successfully!");
          handleGoToNext();
        }
      })
      .catch((e) => {
        // Handle error isApolloError
        if (isApolloError(e)) {
          for (const gqlError of e.graphQLErrors) {
            console.log("gq", gqlError);
            message.error(gqlError.message);
          }
        }
      });
  };

  const handleChange = (name, e, type) => {
    const TestRegx = {
      number: new RegExp(`^[0-9]*$`),
      alphaNumaric: new RegExp(`^[a-zA-Z0-9s ]*$`),
      letters: new RegExp(`^[a-zA-Z ]*$`),
    };
    if (TestRegx[type].test(e.target.value)) {
      form.setFieldValue(name, e.target.value.trim());
    } else {
      form.setFieldValue(name, e.target.value.slice(0, -1));
    }
  };

  const handleGoToNext = async () => {
    const state = JSON.parse(localStorage.getItem("state"));
    const form = new FormData();
    form.append("state_id", state?.id);
    form.append("state", state.state + 1);

    try {
      const res = await updateSignUpStatus(form);
      console.log("Update Status Result:", res);

      if (res?.data?.message === "State update successfully") {
        state.state = Number(state?.state) + 1;
        localStorage.setItem("state", JSON.stringify(state));

        navigate(`/managers/create-profile?state=${3}`);
      }
    } catch (error) {
      console.error("Error updating state:", error);
    }
  };

  return (
    <div className="row">
      <Button
        type="link"
        onClick={() => {
          handleGoToNext();
        }}
        className="skip-btn"
      >
        Skip
      </Button>

      <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8 mx-auto text-center basic-details-auth">
        <h4>Add Your Mechanics</h4>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-8 mx-auto">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p className="fw-normal">Let's add your first Mechanic!</p>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8 mx-auto common-form">
        <Form
          name="basic"
          form={form}
          onFieldsChange={handleFormChange}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          requiredMark={false}
          autoComplete="off"
        >
          <div className="row">
            <div className="col-12">
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: "Please input your First Name!",
                  },
                ]}
              >
                <Input
                  onChange={(e) => handleChange("firstName", e, "letters")}
                  placeholder="Enter First Name"
                />
              </Form.Item>
            </div>
            <div className="col-12">
              <Form.Item
                label="Last Name"
                name="lastName"
                onChange={(e) => handleChange("lastName", e, "letters")}
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: "Please input your Last Name!",
                  },
                ]}
              >
                <Input placeholder="Enter Last Name" />
              </Form.Item>
            </div>
            <div className="col-12">
              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    min: 10,
                    max: 10,
                    required: true,
                    whitespace: true,
                    message: "Please input your Phone Number!",
                  },
                ]}
                mask="... ... ...."
              >
                {console.log("country code", countryCode)}
                <Input
                  maxLength={10}
                  mask="... ... ...."
                  className="Intl-phone-Input"
                  placeholder="XXX XXX XXXX"
                  onChange={(e) => handleChange("phoneNumber", e, "number")}
                  addonBefore={
                    <Select
                    showSearch
                    onChange={(val) => setCountryCode(val)}
                    className="tel-add-on"
                    defaultValue="US +1"
                    filterOption={(input, option) =>
                      input &&
                      option.label &&
                      option.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onKeyPress={(e) => {
                      // Allow digits (0-9) and the '+' character, prevent default for other keys
                      if (!/^[0-9+]$/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  >
                      {CountryData?.map((item, index) => {
                        return (
                          <Select.Option
                            key={index}
                            value={item?.code + " " + item?.dial_code}
                            label={item?.dial_code}
                          >
                            <i className="flag">{item?.flag}</i>{" "}
                            {item?.dial_code}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  }
                />
              </Form.Item>
            </div>
            <div className="col-12">
              <Button
                className="theme-common-btn subscribe-btn w-100"
                disabled={disabledSave}
                onClick={() => setAddMore(false)}
                type="primary"
                htmlType="submit"
              >
                Invite And proceed
              </Button>
            </div>
            <div className="col-12">
              <Button
                htmlType="submit"
                onClick={() => setAddMore(true)}
                disabled={disabledSave}
                className="add-more-btn w-100"
              >
                + Add More
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddYourMechanics;
