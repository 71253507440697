import { gql, useQuery } from "@apollo/client";
export const GET_SHOP_INFO = gql`
  query getShopInfo($id: ID!) {
    shop(id: $id) {
      profileImageUrl
      companyName
      ownersName
      address
      city
      state
      zip
      country
      phoneNumber
      id
      facebookUrl
      instagramUrl
      youtubeUrl
      snapchatLink
    }
  }
`;
export const GetShopInfo = (id) => {
  const { data, loading, error } = useQuery(GET_SHOP_INFO, {
    variables: {
      id,
    },
    fetchPolicy: "network-only",
  });

  return {
    shopData: data,
    shopLoading: loading,
    shopError: error,
  };
};
