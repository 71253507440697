import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Image as Images } from "../Images";
import { Button, Dropdown } from "antd";
import Popup2 from "../popup/Popup2";
import "../../assets/styles/header.scss";
import { CloseCircleOutlined, MenuOutlined } from "@ant-design/icons";
import { GetShopProfileImage } from "../../gqloperations/getshopProfileQuery";

const AfterLoginHeader = () => {
  const shopId = localStorage.getItem("shopId");
  const [isActive, setIsActive] = useState(false);
  const [visible, setVisible] = useState(false);
  console.log("shopId", shopId);
  const { data, loading, error } = GetShopProfileImage(shopId);
  const handleToggleSidebar = () => {
    setIsActive((current) => !current);
  };

  const handleLogoutBtn = () => {
    localStorage.clear();
    window.location.replace("/managers/sign-in");
  };
  const items = [
    {
      label: (
        <Link to={"/managers/edit-details"}>
          <img src={Images.profile_icon_white} alt="" className="img-fluid" />
          Profile
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <Link to={"/managers/manage-subcription"}>
          <img
            src={Images.manage_subscription_icon_white}
            alt=""
            className="img-fluid"
          />{" "}
          Manage Subscription
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <Link to={"/managers/manage-credit-card"}>
          <img
            src={Images.credit_card_icon_white}
            alt=""
            className="img-fluid"
          />{" "}
          Manage Credit Card
        </Link>
      ),
      key: "2",
    },
    {
      label: (
        <Button
          onClick={() => {
            setVisible(true);
          }}
          className="logout-btn"
        >
          <img src={Images.log_out_icon_red} alt="" className="img-fluid" /> Log
          Out
        </Button>
      ),
      key: "3",
    },
  ];
  return (
    <>
      <div className={`sidebar-custom ${isActive ? "sidebar-toggle" : ""}`}>
        <Button
          onClick={handleToggleSidebar}
          className="bg-transparent border-0 p-0 h-auto close-btn-sidebar"
        >
          <CloseCircleOutlined />
        </Button>
        <div className="row mx-0">
          <div className="col-12">
            <ul className="list-inline mb-0">
              <li>
                <NavLink to={"/managers/home"}>Home</NavLink>
              </li>
              <li>
                <NavLink to={"/managers/mechanics"}>Mechanics</NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid custom-header-main">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12 col-sm-12 col-md-2 col-xl-6 col-lg-6 position-relative ">
              <Link to={"/managers/home"}>
                <img
                  src={Images.logo_main_white}
                  alt=""
                  className="img-fluid"
                />
              </Link>
              <Button
                onClick={handleToggleSidebar}
                className="toggle-btn d-flex d-md-none d-sm-block d-xl-none d-lg-none"
              >
                <MenuOutlined />
              </Button>
              <div className="profile-mobile-div d-block d-sm-block d-md-none d-lg-none d-xl-none">
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                  overlayClassName="profile-dropdown"
                  placement="bottom"
                  arrow
                >
                  <Button
                    className="profile-details-btn"
                    onClick={(e) => e.preventDefault()}
                  >
                    <img
                      src={
                        data?.shop?.profileImageUrl === null
                          ? Images.login_user_img
                          : data?.shop?.profileImageUrl
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </Button>
                </Dropdown>
              </div>
            </div>
            <div className="col-12 col-sm-4 col-md-10 col-xl-6 col-lg-6 d-none d-md-block d-xl-block d-lg-block text-end">
              <ul className="mb-0 list-inline nav-menu-list d-flex align-items-center justify-content-end">
                <li className="list-inline-item">
                  <NavLink className="text-uppercase" to={"/managers/home"}>
                    Home
                  </NavLink>
                </li>
                <li className="list-inline-item">
                  <NavLink
                    className="text-uppercase"
                    to={"/managers/mechanics"}
                  >
                    Mechanics
                  </NavLink>
                </li>
                <li className="list-inline-item">
                  <Dropdown
                    menu={{
                      items,
                    }}
                    trigger={["click"]}
                    overlayClassName="profile-dropdown"
                    placement="bottom"
                    arrow
                  >
                    <Button
                      className="profile-details-btn px-0 py-2"
                      onClick={(e) => e.preventDefault()}
                    >
                      <img
                        src={
                          data?.shop?.profileImageUrl === null
                            ? Images.login_user_img
                            : data?.shop?.profileImageUrl
                        }
                        alt=""
                        className="img-fluid"
                      />
                    </Button>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Popup2
          title={"Would you like to logout"}
          description={
            "You are logging of your Shop Console.we are here when you ready to log back in.Until then continue to use your connected phones and tablets to add manage picture of your customers cars."
          }
          visible={visible}
          // handleOk={() => handleLogoutBtn()}
          // handleCancel={() => }
          buttons={[
            {
              text: "Cancel",
              func: () => setVisible(false),
              className: "cancel-button",
            },
            {
              text: "Logout",
              func: () => handleLogoutBtn(),
              className: "subscription-button",
            },
          ]}
        ></Popup2>
      </div>
    </>
  );
};

export default AfterLoginHeader;
