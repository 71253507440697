import axios from "axios";

export const imageToBase64 = (event) => {
  let myFiles = {};
  // set state of files to false until each of them is processed
  console.log(event);
  const files = event?.nativeEvent.srcElement?.files;
  const filePromises = Object.entries(files).map((item) => {
    return new Promise((resolve, reject) => {
      const [, file] = item;
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = function (event) {
        // Convert file to Base64 string
        // btoa is built int javascript function for base64 encoding
        myFiles = btoa(event?.target.result);
        resolve();
      };
      reader.onerror = function () {
        console.log("can't read the file");
        reject();
      };
    });
  });

  const tempF = Promise.all(filePromises)
    .then(() => {
      console.log("ready to submit");
      console.log("file", myFiles);
      return myFiles;
    })
    .catch((error) => {
      console.log(error);
      console.log("something wrong happened");
    });

  myFiles = tempF;
  return myFiles;
};
export const updateSignUpStatus = async (payload) => {
  const response = await axios
    .post(`${process.env.REACT_APP_API_URL}v1/update_state`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const createSignUpStatus = async (payload) => {
  const response = await axios
    .post(`${process.env.REACT_APP_API_URL}v1/state`, payload)
    .then((res) => {
      console.log(" create res status", res);
      if (res?.data?.state) {
        localStorage.setItem("state", JSON.stringify(res?.data?.state));
      }
      return res;
    })
    .catch((err) => {
      return err;
    });
  return response;
};

export const handleNavigation = () => {
  const state = JSON.parse(localStorage.getItem("state"));
  const shopId = localStorage.getItem("shopId");
  console.log("shopId ,state", shopId, state);
  if (shopId === "null" || state?.state === "null") {
    window.location.replace("/managers/create-profile?state=0");
  } else if (shopId !== "null" && +state?.state < 2) {
    window.location.replace(`/managers/create-profile?state=${state?.state}`);
  }
};

export const preventNavigationBack = () => {
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
    window.history.go(1);
  };
};


export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
