import React from "react";
import "../../assets/styles/auths.scss";
import "../../assets/styles/form.scss";
import { Image as Images } from "../Images";
import LoginForm from "./login/LoginForm";
import LoginSliderRight from "./login/LoginSliderRight";

const Login = () => {
  return (
    <div className="container-fluid auths-main-fluid p-0">
      <div className="row mx-0 position-relative">
        <div className="auth-shadow-effect">
          <img src={Images.auth_shadow_effect} alt="" className="img-fluid" />
        </div>
        <div className="col-12 col-sm-6 auth-left-div">
          <LoginForm />
        </div>
        <div className="col-12 col-sm-6 auth-right-div">
          <LoginSliderRight />
        </div>
      </div>
    </div>
  );
};

export default Login;
