import React, { useEffect, useState, useRef } from "react";
import { Button, Dropdown, Modal, Space, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import AfterLoginHeader from "../../components/common/AfterLoginHeader";
import { Image as Images } from "../../components/Images";
import { Breadcrumb } from "antd";
import { GetCarCategoriesImageList } from "../../gqloperations/cars/get-car-categories-image.mutation";
import { DeleteOutlined, EyeFilled } from "@ant-design/icons";
import { DELETE_CAR_IMAGE } from "../../gqloperations/cars/delete-car-image-mutation";
import { useMutation } from "@apollo/client";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "../../assets/styles/categories.scss";
import Popup2 from "../../components/popup/Popup2";

export default function CarCategoryImages() {
  const transformComponentRef = useRef("transformComponentRef");

  const navigate = useNavigate();
  const id = useLocation().search;
  const searchParams = new URLSearchParams(id);
  const { CarImageData, loading, error } = GetCarCategoriesImageList(
    searchParams.get("id")
  );
  const [imageData, setImageData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [deleteCatId, setDeleteId] = useState("");
  const [visibleImageUrl, setVisibleImageUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteImageFromShop] = useMutation(DELETE_CAR_IMAGE);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    console.log("data", CarImageData);
    console.log("searchParams", searchParams.get("name"));
    if (CarImageData) {
      const imageArr = CarImageData?.carImageCategories?.filter((item) => {
        return item.name === searchParams.get("name");
      });
      setImageData(imageArr[0]);
    }
  }, [CarImageData]);

  const handleDeleteImage = () => {
    const payload = {
      carId: searchParams.get("id"),
      carImageId: deleteCatId,
    };
    console.log("carImageId !", deleteCatId);
    deleteImageFromShop({ variables: payload })
      .then((res) => {
        console.log("res", res);
        if (res?.data?.deleteImageFromShop?.success) {
          message.success("Deleted successfuly!");
          setVisible(false);
          navigate(-1);
        }
      })
      .catch((err) => {
        message.error("something went wrong, please try again!");
      });
  };

  return (
    <div>
      <AfterLoginHeader />
      <div className="container-fluid categories-main-fluid pt-5">
        <div className="container">
          <div className="mb-3">
            <Breadcrumb
              className="iON-breadcrumb"
              separator=">"
              items={[
                {
                  title: (
                    <p
                      onClick={() =>
                        navigate(
                          `/managers/home/details?id=${CarImageData?.id}`
                        )
                      }
                    >
                      {CarImageData?.model}
                    </p>
                  ),
                },
                {
                  title: (
                    <p
                      onClick={() =>
                        navigate(
                          `/managers/home/details/categories?id=${CarImageData?.id}`
                        )
                      }
                    >
                      Category
                    </p>
                  ),
                },
                {
                  title: searchParams.get("name"),
                },
              ]}
            />
          </div>
          <div className="row categories-row-heading">
            <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
              <h1
                className="mb-0"
                style={{ fontFamily: "Manrope", textTransform: "capitalize" }}
              >
                {searchParams.get("name")}
              </h1>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
              <ul className="list-inline mb-0 text-end">
                <li className="list-inline-item">
                  <Button
                    className="add-restoration-btn border-0"
                    onClick={() =>
                      navigate(
                        `/managers/home/details/edit-images?id=${CarImageData?.id}`
                      )
                    }
                  >
                    <img
                      src={Images.add_icon_white}
                      alt=""
                      className="img-fluid"
                    />
                    Add Images
                  </Button>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div className="row">
              {console.log("imageData", imageData)}
              {imageData?.images?.length > 0 &&
                imageData?.images?.map((item, index) => {
                  return (
                    <>
                      <div key={index} className="col-6 col-md-3 mb-3">
                        <div className="category-image-container">
                          <>
                            <Dropdown
                              menu={{
                                items: [
                                  {
                                    label: (
                                      <Button
                                        onClick={() => {
                                          setVisible(true);
                                          setDeleteId(item?.id);
                                        }}
                                        type="link"
                                        danger
                                      >
                                        <DeleteOutlined /> Delete
                                      </Button>
                                    ),
                                    key: "1",
                                  },
                                ],
                              }}
                              overlayClassName="img-menu"
                              trigger={["click"]}
                              placement="bottomLeft"
                            >
                              <Space>
                                <a onClick={(e) => e.preventDefault()}>
                                  <img
                                    className="menu-icon"
                                    src="/assets/icons/menu-dot-icon.svg"
                                  />
                                </a>
                              </Space>
                            </Dropdown>
                            <img className="cover-image" src={item.imageUrl} />
                            <div className="image-label-top text-capitalize">
                              <Button
                                onClick={() => {
                                  setVisibleImageUrl(item.imageUrl);
                                  setIsModalOpen(true);
                                  transformComponentRef?.current?.resetTransform();
                                }}
                                type="link"
                              >
                                <EyeFilled />
                              </Button>
                            </div>
                          </>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            <Popup2
              title={"Are you sure you want to delete image?"}
              description={""}
              visible={visible}
              buttons={[
                {
                  text: "Cancel",
                  func: () => setVisible(false),
                  className: "cancel-button",
                },
                {
                  text: "Delete",
                  func: () => handleDeleteImage(),
                  className: "subscription-button",
                },
              ]}
            ></Popup2>
            <Modal
              title=""
              className="image-modal"
              centered={true}
              open={isModalOpen}
              onOk={handleOk}
              footer={false}
              onCancel={handleCancel}
            >
              <TransformWrapper ref={transformComponentRef} initialScale={1}>
                <TransformComponent>
                  <img
                    className="image-fluid w-100"
                    src={visibleImageUrl}
                    alt=""
                  />
                </TransformComponent>
              </TransformWrapper>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}
