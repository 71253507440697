import { gql } from "@apollo/client";

export const CREATE_CAR = gql`
  mutation createCarMutation(
    $year: String
    $make: String
    $model: String
    $vin: String
    $chassis: String
    $exteriorColor: String
    $interiorColor: String
    $driveType: String
    $transmission: String
    $bodyStyle: String
    $engine: String
    $fuel: String
    $mileage: String
    $shopId: ID!
    $isPublic: Boolean
    $heroImageBase64: String
    $description: String
    $comments: String
  ) {
    createCarFromShop(
      input: {
        year: $year
        make: $make
        model: $model
        vin: $vin
        chassis: $chassis
        exteriorColor: $exteriorColor
        interiorColor: $interiorColor
        driveType: $driveType
        transmission: $transmission
        bodyStyle: $bodyStyle
        engine: $engine
        fuel: $fuel
        mileage: $mileage
        shopId: $shopId
        isPublic: $isPublic
        heroImageBase64: $heroImageBase64
        description: $description
        comments: $comments
      }
    ) {
      car {
        id
      }
    }
  }
`;
