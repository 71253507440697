import React from "react";
import CarList from "./CarList";

const CarListView = ({ cars }) => {
  return (
    <>
      <div>
        {cars.length > 0 ? (
          cars?.map((eachCar) => {
            return (
              <CarList
                id={eachCar.id}
                heroImageUrl={eachCar.heroImageUrl}
                model={eachCar.model}
                description={eachCar.description}
                make={eachCar.make}
                shopOwner={eachCar.shopOwner}
                year={eachCar.year}
                qrCodeUrl={eachCar.qrCodeUrl}
                qrCodeDocUrl={eachCar?.qrCodeDocUrl}
              />
            );
          })
        ) : (
          <div className="no-car-msg">{/* <p>No car available</p> */}</div>
        )}
      </div>
    </>
  );
};

export default CarListView;
