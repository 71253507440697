import React, { useState, useEffect, useRef } from "react";
import Header from "./../common/Header";
import Footer from "./../common/Footer";
import { Typography, List, Button, BackTop, Avatar } from "antd";
import { UpCircleOutlined } from "@ant-design/icons";
import "../../assets/styles/enduseragrrement.scss";
// import "../assets/styles/contact.scss";
// import "../assets/styles/form.scss";
import ScrollToTop from "react-scroll-to-top";


const { Title } = Typography;



const data = [
  {
    title: "Ant Design Title 1",
  },
  {
    title: "Ant Design Title 2",
  },
  {
    title: "Ant Design Title 3",
  },
  {
    title: "Ant Design Title 4",
  },
];

const termsData = [
  {
    title:
      "License. iONclassic hereby grants to User a limited, non-transferable, non-sublicensable, revocable license to access and use the iONclassic Materials solely in accordance with the terms herein until this Agreement is terminated by either party, and otherwise in accordance with such terms and conditions as may be set forth during the registration process. Except for the limited licenses granted hereunder, iONclassic reserves all rights not expressly granted and no such additional rights may be implied.",
  },
  {
    title:
      "Term. This Agreement shall last for the duration of the Subscription Term associated with the Subscription Plan you select at the time of your registration, unless otherwise terminated by the parties in accordance with the terms herein and with such terms and conditions as may be set forth during the registration process. This Agreement shall also be terminated in the event of either party’s breach hereof upon ten (10) days written notice thereof to the breaching party by the non-breaching party unless within such ten (10) day period the breaching party cures such breach.",
  },
  {
    title:
      "Payment. User shall pay to iONclassic such fees as are set forth during the registration process (plus any taxes imposed by law).",
  },
  {
    title:
      "Ownership and Use Limitations. he Platform and associated rights thereto are and shall remain the property of iONclassic. User shall obtain no interest therein other than the limited license granted hereunder. User shall not sell, rent, transfer, reproduce, publicly display, modify, adapt, translate, or create derivative works of, the Platform. User shall not reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code form of, the Platform. User shall not reproduce the functionality of the Platform or engage any third party to do so. User shall maintain the confidentiality of the Platform and its features and shall promptly notify iONclassic of any disclosure thereof to any unauthorized third party.",
  },
  {
    title:
      "License to iONclassic. User hereby grants to iONclassic the limited right and license to reproduce and display User’s name(s), brand(s), logo(s), and trademark(s) as a part of iONclassic’s customer portfolio on iONclassic’s Platform and promotional materials with customary prominence and otherwise in accordance with reasonable usage guidelines therefor. If User does not upload User’s logo during the applicable portion of the registration process, iONclassic may, in furtherance hereof, manually obtain a copy thereof from User’s publicly available materials.",
  },
  {
    title:
      "Content. The Platform allows Users to share User Content to the Platform and to interact with other Users. You are responsible for the User Content that you post on or through the Platform, including its legality, reliability, and appropriateness. While using the Platform, you shall not: (i) post inappropriate content not in furtherance of the receiving or providing of the Platform; (ii) distribute or post spam, unsolicited, or bulk electronic communications, chain letters, or pyramid schemes; (iii) post unlawful, false, defamatory, threatening, hateful, or obscene content (including personal information). You retain any and all of your rights to any User Content you submit, on or through the Platform, and you are responsible for protecting those rights. iONclassic takes no responsibility and assumes no liability for User Content you post on or through the Platform. iONclassic has the right but not the obligation: (i) to monitor and edit all User Content provided by you; and (ii) to terminate your account and refuse future access to the Platform if warranted, in iONclassic’s sole discretion. Though iONclassic strives to encourage a respectful User experience, iONclassic is not responsible for the conduct of any User on or away from the Platform. You agree to use caution in all interactions with other Users.",
  },
  {
    title:"Limitation of Liability; Disclaimer of Warranties. USER’S SOLE AND EXCLUSIVE REMEDY FOR ANY DAMAGE OR LOSS ARISING IN CONNECTION WITH THE PLATFORM OR IONCLASSIC’S PERFORMANCE HEREUNDER SHALL BE RETURN OF A PRORATED PORTION OF THE FEES PAID BY USER, WHICH USER HEREBY AGREES IS A FAIR AND EQUITABLE REMEDY. UNDER NO CIRCUMSTANCES SHALL IONCLASSIC BE LIABLE TO USER OR ANY OTHER PERSON OR ENTITY FOR ANY LOSS OF USE, REVENUE OR PROFIT, LOST OR DAMAGED DATA, OR OTHER COMMERCIAL OR ECONOMIC LOSS, OR FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, STATUTORY, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES WHATSOEVER RELATED TO USER’S USE OR RELIANCE UPON THE PLATFORM, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR IF SUCH DAMAGES ARE FORESEEABLE. THIS LIMITATION SHALL APPLY EVEN IN THE EVENT OF A FUNDAMENTAL OR MATERIAL BREACH OR A BREACH OF THE FUNDAMENTAL OR MATERIAL TERMS OF THIS AGREEMENT. THE PLATFORM IS MADE AVAILABLE ON AN “AS IS” BASIS ONLY AND IONCLASSIC MAKES NO WARRANTIES, CONDITIONS, INDEMNITIES, OR REPRESENTATIONS, EXPRESS OR IMPLIED, WHETHER BY STATUTE, COMMON LAW, CUSTOM, USAGE, OR OTHERWISE AS TO ANY MATTERS, INCLUDING WITHOUT LIMITATION NON-INFRINGEMENT OF THIRD PARTY RIGHTS, TITLE, INTEGRATION, ACCURACY, SECURITY, AVAILABILITY, SATISFACTORY QUALITY, MERCHANTABILITY, OR FITNESS FOR ANY PARTICULAR PURPOSE."
  },
  {
     title:"Indemnity. User shall indemnify, defend, and hold harmless iONclassic from and against all actual and threatened third party claims, actions, damages, and losses of every kind arising in connection with any act or omission of User and User’s personnel."
  },
];

const logData = ["Log Information:"];
const device = ["Device Information:"];
const location = ["Location Information:"];
const photo = ["Photo Location Information:"];

const Data = [
  "Content. The Platform allows Users to share User Content to the Platform and to interact with other Users. You are responsible for the User Content that you post on or through the Platform, including its legality, reliability, and appropriateness. While using the Platform, you shall not: (i) post inappropriate content not in furtherance of the receiving or providing of the Platform; (ii) distribute or post spam, unsolicited, or bulk electronic communications, chain letters, or pyramid schemes; (iii) post unlawful, false, defamatory, threatening, hateful, or obscene content (including personal information). You retain any and all of your rights to any User Content you submit, on or through the Platform, and you are responsible for protecting those rights. iONclassic takes no responsibility and assumes no liability for User Content you post on or through the Platform. iONclassic has the right but not the obligation: (i) to monitor and edit all User Content provided by you; and (ii) to terminate your account and refuse future access to the Platform if warranted, in iONclassic’s sole discretion. Though iONclassic strives to encourage a respectful User experience, iONclassic is not responsible for the conduct of any User on or away from the Platform. You agree to use caution in all interactions with other Users.",
  "Limitation of Liability; Disclaimer of Warranties. USER’S SOLE AND EXCLUSIVE REMEDY FOR ANY DAMAGE OR LOSS ARISING IN CONNECTION WITH THE PLATFORM OR IONCLASSIC’S PERFORMANCE HEREUNDER SHALL BE RETURN OF A PRORATED PORTION OF THE FEES PAID BY USER, WHICH USER HEREBY AGREES IS A FAIR AND EQUITABLE REMEDY. UNDER NO CIRCUMSTANCES SHALL IONCLASSIC BE LIABLE TO USER OR ANY OTHER PERSON OR ENTITY FOR ANY LOSS OF USE, REVENUE OR PROFIT, LOST OR DAMAGED DATA, OR OTHER COMMERCIAL OR ECONOMIC LOSS, OR FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, STATUTORY, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES WHATSOEVER RELATED TO USER’S USE OR RELIANCE UPON THE PLATFORM, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR IF SUCH DAMAGES ARE FORESEEABLE. THIS LIMITATION SHALL APPLY EVEN IN THE EVENT OF A FUNDAMENTAL OR MATERIAL BREACH OR A BREACH OF THE FUNDAMENTAL OR MATERIAL TERMS OF THIS AGREEMENT. THE PLATFORM IS MADE AVAILABLE ON AN “AS IS” BASIS ONLY AND IONCLASSIC MAKES NO WARRANTIES, CONDITIONS, INDEMNITIES, OR REPRESENTATIONS, EXPRESS OR IMPLIED, WHETHER BY STATUTE, COMMON LAW, CUSTOM, USAGE, OR OTHERWISE AS TO ANY MATTERS, INCLUDING WITHOUT LIMITATION NON-INFRINGEMENT OF THIRD PARTY RIGHTS, TITLE, INTEGRATION, ACCURACY, SECURITY, AVAILABILITY, SATISFACTORY QUALITY, MERCHANTABILITY, OR FITNESS FOR ANY PARTICULAR PURPOSE.",
  "Indemnity. User shall indemnify, defend, and hold harmless iONclassic from and against all actual and threatened third party claims, actions, damages, and losses of every kind arising in connection with any act or omission of User and User’s personnel.",
  "General. This Agreement may be amended solely by written agreement executed by both parties; provided, however, that User’s use of the Platform shall be further governed by the Terms of Service provisions set forth on the Platform, which may be changed by iONclassic from time to time. This Agreement shall be governed, construed, and enforced in accordance with the laws of the State of Florida (without regard for its conflicts of laws principles). Any disputes between the parties arising hereunder shall be subject to the provisions as may be set forth in the Terms of Service. Should any dispute or claim require resolution in a court of law, the parties hereby agree to submit personal jurisdiction to the competent state and federal courts within Hillsborough County, Florida, and waive any objections thereto. Equitable remedies shall not be available, except to enforce the provisions of Section 4 hereof. If any part of this Agreement is found void and unenforceable, it will not affect the validity of the balance of this Agreement, which shall remain valid and enforceable according to its terms. The failure of either party to exercise any right or the waiver by either party of any breach, shall not prevent a subsequent exercise of such right or be deemed a waiver of any subsequent breach of the same of any other provision hereof. This Agreement shall not prejudice the statutory rights of any party dealing as a consumer. This is the entire agreement between the parties relating to the Platform and it supersedes any prior representations, discussions, undertakings, communications, or advertising relating to the Platform.",
];

const EnduserAgreement = () => {
  
  
  const [isMenuVisible, setMenuVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setMenuVisible(scrollTop < lastScrollTop || scrollTop < 40);
      setLastScrollTop(scrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);
  

  return (
    <>
     <div className={`menu ${isMenuVisible ? 'reveal' : 'hide'}`}>
        <Header /> 
     </div>
     
      <div className="container-fluid enduseragreement-content">
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="enduser-header">
              <Title level={2}>
                iONclassic, LLC End User License Agreement
              </Title>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-md-12">
            <Title level={5} className="enduser-title">
              This <span>End User License Agreement</span> (“Agreement”) is made
              and entered into by and between <span>iONclassic, LLC,</span> a
              Florida limited liability company (“iONclassic”), and the person
              or entity on whose behalf the iONclassic customer registration
              process has been completed (“User”, “you”, “your”), as of the date
              thereof (the “Effective Date”). By downloading and using the
              Platform and other Services offered on the Platform, as
              applicable, the parties agree to all of the provisions hereof,
              including the Terms of Service and Privacy Policy as set forth on
              iONclassic’s Platform, both of which are hereby incorporated
              herein by reference. All terms not defined herein shall have the
              meaning set forth in the Terms of Service and Privacy Policy.
            </Title>
           
            <List className="enduserList"
              itemLayout="horizontal"
              dataSource={termsData}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta avatar={`${index + 1}.`} title={item.title} />
                </List.Item>
              )}
            />
            <Title level={5} className="enduser-title">
            General. This Agreement may be amended solely by written agreement executed by both parties; provided, however, that User’s use of the Platform shall be further governed by the Terms of Service provisions set forth on the Platform, which may be changed by iONclassic from time to time. This Agreement shall be governed, construed, and enforced in accordance with the laws of the State of Florida (without regard for its conflicts of laws principles). Any disputes between the parties arising hereunder shall be subject to the provisions as may be set forth in the Terms of Service. Should any dispute or claim require resolution in a court of law, the parties hereby agree to submit personal jurisdiction to the competent state and federal courts within Hillsborough County, Florida, and waive any objections thereto. Equitable remedies shall not be available, except to enforce the provisions of Section 4 hereof. If any part of this Agreement is found void and unenforceable, it will not affect the validity of the balance of this Agreement, which shall remain valid and enforceable according to its terms. The failure of either party to exercise any right or the waiver by either party of any breach, shall not prevent a subsequent exercise of such right or be deemed a waiver of any subsequent breach of the same of any other provision hereof. This Agreement shall not prejudice the statutory rights of any party dealing as a consumer. This is the entire agreement between the parties relating to the Platform and it supersedes any prior representations, discussions, undertakings, communications, or advertising relating to the Platform.
            </Title>
          </div>
        </div>
      </div>
      <Footer />
    
      <ScrollToTop className="scroll"/>
    </>
  );
};

export default EnduserAgreement;
