import { gql } from "@apollo/client";
export const GET_MECHANICS = gql`
  mutation getMechanic($shopId: String!) {
    findMechanic(input: { shopid: $shopId }) {
      mechanics {
        firstName
        lastName
        phoneNumber
        id
      }
    }
  }
`;
