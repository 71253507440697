import React, { useEffect, useState } from "react";
import "../assets/styles/auths.scss";
import "../assets/styles/form.scss";
import { Image as Images } from "./Images";
import { Progress, Button, Spin } from "antd";
import AddRestoration from "./profile-components/AddRestoration";
import CreateProfileForm from "./profile-components/CreateProfileForm";
import SelectPlansCardDetails from "./profile-components/SelectPlansCardDetails";
import AddYourMechanics from "./profile-components/AddYourMechanics";
import { useLocation, useNavigate } from "react-router-dom";
// import EditDetails from "./editdetails/EditDetails";
import { SIGNUP_USER } from "../gqloperations/mutations";

const CreateProfile = () => {
  const navigate = useNavigate();
  const step = useLocation().search;
  const searchParams = new URLSearchParams(step);
  const shopId = localStorage.getItem("shopId");
  const [currentStep, setCurrentStep] = useState(searchParams.get("state"));
  const [loading, setLoading] = useState(false)
  const state = JSON.parse(localStorage.getItem("state"));
  const stateNew = localStorage.getItem("values");
  // localStorage.getItem("values",values)
  

  useEffect(() => {
    setLoading(true)
    if(state?.state >= 4){
      setLoading(false)
      window.location.replace('/managers/home')
    }else{
      setLoading(false)
      setCurrentStep(searchParams.get("state"))
    }
  },[searchParams.get("state"), shopId])

  
  
  const stepperArr = [
    {
      key: 1,
      component: <CreateProfileForm />,
    },
    {
      key: 2,
      component: <SelectPlansCardDetails />,
    },
    {
      key: 3,
      component: <AddYourMechanics />,
    },
    {
      key: 4,
      component: <AddRestoration />
      ,
    },
  ];
  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  console.log("stateNew",stateNew)
  return (
    <Spin spinning={loading} tip="Loading" size="large">
    <div className="container-fluid auths-main-fluid auths-main-profile overflow-auto">
      <div className="row mx-0 position-relative">
        <div className="auth-shadow-effect">
          <img src={Images.auth_shadow_effect} alt="" className="img-fluid" />
        </div>
        <div className="col-12 col-sm-12 col-xl-9 col-lg-12 col-md-10 mx-auto auth-left-div">
          <div className="row">
            <div className="col-12 text-center auth-logo-section">
              <a href="#">
                <img src={Images.auth_logo} alt="" className="img-fluid" />
              </a>
            </div>
            <div className="progress-steps-div col-12 col-sm-5 mx-auto">
              <Progress percent={25 * (+currentStep + 1)} />
              <div className="steps-count">
                <span className="active-count">0{+currentStep + 1}</span>\
                <span className="normal-count">04</span>
              </div>
            </div>
            <div className="col-12">
              {currentStep < stepperArr.length && React.cloneElement(
                stepperArr[+currentStep]?.component,
                { handleNextStep: handleNextStep },
                null
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    </Spin>
  );
};

export default CreateProfile;
