import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Image as Images } from "../Images";
import { Button } from "antd";
import "../../assets/styles/header.scss";
import { CloseCircleOutlined, MenuOutlined } from "@ant-design/icons";

const Header = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const handleToggleSidebar = () => {
    setIsActive((current) => !current);
  };

  return (
    <>
      <div className={`sidebar-custom ${isActive ? "sidebar-toggle" : ""}`}>
        <Button
          onClick={handleToggleSidebar}
          className="bg-transparent border-0 p-0 h-auto close-btn-sidebar"
        >
          <CloseCircleOutlined />
        </Button>
        <div className="row mx-0">
          <div className="col-12">
            <ul className="list-inline mb-0">
              <li>
                <NavLink to={"/"}>Home</NavLink>
              </li>
              <li>
                <NavLink to={"/faq"}>Faq</NavLink>
              </li>
              <li>
                <NavLink to={"/contact-us"}>Contact Us</NavLink>
              </li>
            </ul>
            <ul className="list-inline mb-0 auth-btn-ul">
              <li className="list-inline-item">
                <Button
                  className="sign-in-btn d-flex align-items-center justify-content-center text-uppercase"
                  onClick={() => navigate("/managers/sign-in")}
                >
                  Sign In
                </Button>
              </li>
              <li className="list-inline-item">
                <Button
                  className="sign-up-btn d-flex align-items-center justify-content-center text-uppercase"
                  onClick={() => navigate("/managers/sign-up")}
                >
                  Sign Up
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid custom-header-main">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12 col-sm-4 col-md-5 col-xl-4 col-lg-4 d-none d-md-block d-xl-block d-lg-block">
              <ul className="mb-0 list-inline nav-menu-list">
                <li className="list-inline-item">
                  <NavLink className="text-uppercase" to={"/"}>
                    Home
                  </NavLink>
                </li>
                <li
                  className="list-inline-item"
                  style={{ marginRight: "5rem" }}
                >
                  <NavLink className="text-uppercase" to={"/faq"}>
                    Faq
                  </NavLink>
                </li>
                <li className="list-inline-item">
                  <NavLink className="text-uppercase" to={"/contact-us"}>
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-12 col-md-2 col-xl-4 col-lg-4 position-relative text-md-center tex-lg-center text-xl-center ">
              <Link to={" "}>
                <img
                  src={Images.logo_main_white}
                  alt=""
                  className="img-fluid"
                />
              </Link>
              <Button
                onClick={handleToggleSidebar}
                className="toggle-btn d-flex d-md-none d-sm-block d-xl-none d-lg-none"
              >
                <MenuOutlined />
              </Button>
            </div>
            <div className="col-12 col-sm-4 col-md-5 col-xl-4 col-lg-4 d-none d-md-block d-xl-block d-lg-block">
              <ul className="list-inline mb-0 text-end auth-btn-ul">
                <li className="list-inline-item me-3">
                  <Button
                    className="sign-in-btn d-flex align-items-center justify-content-center text-uppercase"
                    style={{
                      background: "none",
                      borderColor: "#fff",
                    }}
                    onClick={() => navigate("/managers/sign-in")}
                  >
                    Sign In
                  </Button>
                </li>
                <li className="list-inline-item">
                  <Button
                    style={{ background: "#000000", borderColor: "#000000" }}
                    className="sign-up-btn d-flex align-items-center justify-content-center text-uppercase"
                    onClick={() => navigate("/managers/sign-up")}
                  >
                    Sign Up
                  </Button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
