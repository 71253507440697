import React, { useState, useEffect } from "react";
import Header from "./../common/Header";
import Footer from "./../common/Footer";
import { Typography, List } from "antd";


import "../../assets/styles/terms.scss"
import ScrollToTop from "react-scroll-to-top";
// import "../assets/styles/contact.scss";
// import "../assets/styles/form.scss";

const { Title } = Typography;

const data = [
  {
    title:
  "Create an account and subscribe to our digital photography management and storage services, media hosting or other services;"
  },
  {
    title:
  "Purchase products or services; "
},
{title:
  "Participate in a survey, contest or sweepstakes;"},
  {title:
  "Contact us for any reason, such as to request help with the Services or to provide us with your comments/feedback; or ",
  },
  {title:
  "Post information in connection with your photos and galleries (e.g., in a photo caption) or in other forums or interactive areas of the Services.  ",
  },
];

const logData = ["Log Information:"];
const def = [
  "DefinitionsThe following terms shall have the meanings ascribed to them here.",
];

const Data = [
  {title: 
    "Account means the account associated with your email address, as applicable.",
  },
  {title:
  
    "“Intellectual Property Rights” means the account associated with your email address, as applicable."},
  {title:
  
    "Payment. User shall pay to iONclassic such fees as are set forth during the registration process (plus any taxes imposed by law)."
  },
  {title:
    "“iONclassic,” “we,” “our,” “company,” or “us” means iONclassic, LLC, a Florida limited liability company."
  },
  {title:
    "“User(s),” “you,” or “your” means an individual who opens an Account and uses the Platform. Users may register an Account on behalf of a legal entity. If you are registering on behalf of a legal entity: (i) these Terms are a contract between us on the one hand and you as an individual together with that entity, and use of the terms “User,” “you,” and “your” herein shall refer collectively to you as an individual together with that entity; and (ii) you represent and warrant that you are an authorized representative of that entity entity with the authority to bind that entity to these Terms."
  },
];
const dev = [
  {
    title:
  
  "you are less than eighteen (18) years or age or otherwise legally incompetent to enter into a binding contract;",
  },
  {
    title:
  
  "you are a person who is barred from using the Platform under the laws of the United States or any other applicable jurisdiction – meaning, for example and without limitation, that you appear on the U.S. Treasury Department’s list of Specially Designated Nationals or face any other similar prohibition;",
  },
  {
    title:
  
  "you are suspended from using the Platform; or"
  },
  {title:
  "you do not hold a valid email address."
  },
];
const will = [{
  title:

  "will not infringe upon or misappropriate any copyright, patent, trademark, trade secret, or other Intellectual Property Right or proprietary right or right of publicity or privacy of any third party;"
},
{
  title:

  "will not violate any applicable law or regulation;"}
  ,
  {
    title:
  
  "will not otherwise violate the provisions of Sections 4 and 6."},
];
const user = [
  {
    title:
  
  "User Content that is unlawful, defamatory, hateful, harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory, fraudulent or otherwise objectionable or harmful;"
  },
  {
    title:
  
  "User Content that is obscene, pornographic, indecent, lewd, sexually suggestive, including without limitation photos, videos or other User Content containing nudity;"
  },
  {
    title:
  
  "User Content that would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party, endanger national security, or that would otherwise create liability or violate any local, state, national or international law;"
  },
  {
    title:
  
  "User Content that impersonates any person or entity or otherwise misrepresents your affiliation with a person or entity;"
},
  {
    title:
  
  "unsolicited messages containing promotions, political campaigning, advertising or solicitations; and"
},
  {
    title:
  
  "private information of any third party, including, without limitation: addresses, phone numbers, email addresses, social security numbers and credit card numbers."},
];
const infre = [
  {
    title:
  
  "infringe any state or federal laws or regulations, third party rights, or any policies that are posted on the Platform;"
  },
  {
    title:
  
  "fail to deliver payment for Services delivered to you;"
  },
  {title:
  "take any action that may undermine the iONclassic feedback or reputation systems (such as displaying, importing, or exporting feedback information or using it for purposes unrelated to the Platform);"
  },
  {title:
  "transfer, sell, or rent your iONclassic account (including User Content) and Username to another party without our consent;"
},
{title:
  "access or use another User’s account without their express consent;"
},
  {
    title:
  
  "distribute or post spam, unsolicited, or bulk electronic communications, chain letters, or pyramid schemes;"
},
{title:
  "distribute viruses, harmful code, phishing scams, or any other content or technologies, or otherwise take any other actions, that do or are intended to harm iONclassic, the Platform, or any Users or third parties, or their interests or property (including their Intellectual Property Rights, privacy rights, and publicity rights);"
},
{
  title:

  "“frame,” “mirror,” or otherwise incorporate any part of the Platform into any other website, app, or system;"
},
{
  title:

  "modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used by us in connection with the Platform;"
},
];

const iden = [
  {
    title:
  
  "Identify the copyrighted work that you claim has been infringed. If you believe multiple copyrighted works to have been infringed, you may provide a representative list of the copyrighted works."},
  {title:
  "Identify the Platform material you claim is infringing, including at a minimum, if applicable, the URL of the link shown on the Platform or the location within the Platform where such material is presented."},
  {title:
  "Provide your mailing address, telephone number, and, if available, email address."},
  {title:
  "Include both of the following statements in the body of the Notice:"},
];
const ident = [{
  title:
  "Identification of the material that has been removed or to which access has been disabled on the Platform and the location at which the material appeared before it was removed or access to it was disabled:"},
  {title:
  "A statement as follows: “I hereby state under penalty of perjury that I have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled.”"},
  {title:
  "Your name, address, telephone number and, if available, email address:"},
  {title:
  "A statement as follows: “I hereby consent to the jurisdiction of the Federal District Court for the judicial district in which my address is located or, if my address is outside of the United States, for any judicial district in which iONclassic may be found, and I will accept service of process from the complaining party who notified iONclassic of the alleged infringement or an agent of such person.”"},
  {title:
  "Your physical or electronic signature."},
];
const deter = [{
  title:

  "if we determine that you have breached, or are acting in breach of, these Terms;"},
  {title:
  "if we determine that you have infringed any third party’s legal rights (resulting in actual or potential claims), including infringing Intellectual Property Rights;"},
  {title:
  "you do not respond to account verification requests;"},
  {title:
  "you do not complete account verification when requested;"},
  {title:
  "to manage any risk of loss to us, a User, or any other person; or"},
  {title:
  "for other reasons we may elect."},
];
const any = [
   {
    title:
   
  "any indirect, special, incidental, or consequential damages that may be incurred by you;"},
  {title:
  "any loss of income, business, or profits (whether direct or indirect) that may be incurred by you;"},
  {title:
  "any claim, damage, or loss that may be incurred by you as a result of any of your transactions or interactions involving the Platform;"},
  {title:
  "any loss, claim, or damages of whatsoever kind of nature, whether direct, indirect, general, or special, arising out of or relating to the conduct of any User or anyone else in connection with the use of the Platform, including, without limitation, death, bodily injury, emotional distress, or any other damages resulting from communications or meetings with other Users or persons User meets through the Services or Platform; or"},
  {title:
  "any services provided by any third party."},
];

const TermsCondition = () => {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
      
  const [isMenuVisible, setMenuVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setMenuVisible(scrollTop < lastScrollTop || scrollTop < 40);
      setLastScrollTop(scrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);
  return (
    <>
      <div className={`menu ${isMenuVisible ? 'reveal' : 'hide'}`}>
        <Header /> 
     </div>
      <div className="container-fluid enduseragreement-content" >
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="terms-main-header">
                <Title className="terms-header" level={2}>iONclassic Terms & Conditions of Use</Title>
                <Title className="terms-header" level={5}>Date of Last Revision: Sept. 18, 2019</Title>

                
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-12">
              <Title className="terms-header" level={5}>
                These Terms of Service (“Terms”) govern your access to and use
                of the website, mobile application, and services, including
                without limitation, merchandise, products, and Subscriptions (as
                defined herein) (collectively “Services”) (together with the
                Services, “Platform”), provided by iONclassic, LLC. These Terms
                constitute a binding agreement between iONclassic, LLC, and you
                or any company that you represent. By accessing or using the
                Platform, you accept and agree to these Terms. If you do not
                accept and agree to these Terms, you are not permitted to use
                the Platform.
              </Title>
              <Title className="terms-header" level={5}>
                iONclassic (as defined herein) reserves the right, in its sole
                and absolute discretion, to amend, supplement, or otherwise
                modify these Terms from time to time. Such modified Terms will
                take effect immediately for new users and upon the passing of 30
                days for existing users. As an existing user, your continued use
                of the Platform after modified Terms have taken effect shall
                constitute your acceptance of such modified Terms.
              </Title>
              <Title className="terms-header" level={5}>
                1.<b>Definitions</b>The following terms shall have the meanings
                ascribed to them here.
              </Title>
              <List className="terms-list"
              itemLayout="horizontal"
              dataSource={Data}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta avatar={`${index + 1}.`} title={item.title} />
                </List.Item>
              )}
            />
              <Title className="terms-header" level={5}>2.<b>Overview</b></Title>
              <Title className="sub-header" level={5}>
                iONclassic is an online venue providing Users a Platform for the
                digital documentation of restoration for classic automobiles,
                motorcycles, and boats. Users with an active Account may post
                information, pictures, videos, and messages regarding their
                restoration activities. Users must register for an Account with
                their email address or as otherwise provided herein in order to
                use the Platform. We facilitate the connection among Users
                through their Accounts, and we provide the means for them to
                communicate with each other.
              </Title>
              <Title className="terms-header" level={5}>3.<b>Eligibility</b></Title>
              <Title className="sub-header" level={5}>
                In order to use the Platform, you must create an Account by
                providing Company with an electronic mail address and other
                information (“Registration Data”). We may, at our absolute
                discretion, refuse to register any person or entity as a User.
                You represent and warrant that all Registration Data and other
                Personal Information, as defined by iONclassic’s Privacy Policy,
                you provide us from time to time is truthful, accurate, current,
                and complete, and you agree not to misrepresent your identity or
                your Registration Data or Personal Information. You agree to
                promptly notify us of changes to your Personal Information by
                updating your Account through the Platform. Company assumes no
                duty to verify such information. If you provide any information
                that is untrue, inaccurate, not current, or incomplete, or
                Company has reasonable grounds to suspect that such information
                is untrue, inaccurate, not current, or incomplete, Company has
                the right to suspend or terminate your Account and refuse any
                and all current or future use of the Platform (or any portion
                thereof). You must not use the Platform, and you are not
                eligible to and may not register for an Account if:
              </Title>
             
              <List className="terms-list"
              itemLayout="horizontal"
              dataSource={dev}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta avatar={`${index + 1}.`} title={item.title} />
                </List.Item>
              )}
            />
              <Title className="terms-header" level={5}>4.<b>Proprietary Rights; Limited License</b></Title>
              <Title className="sub-header" level={5}>
                With the exception of User Content (as defined herein), as
                between you and us, we own, solely and exclusively, all rights,
                title, and interest in and to the Platform, code, data, and
                other proprietary materials thereon, the design, organization,
                and the compilation of the content, code, data, and materials
                (collectively, “Materials”) making up the Platform, including
                without limitation all Intellectual Property Rights therein.
                Your use of the Platform does not grant you ownership of any
                Materials you may access on or through the Platform. You may
                access and view the Materials on the Platform, and may use or
                copy such content for your personal use only. Unless otherwise
                noted herein or on the Platform, use of the Platform is strictly
                for your personal use. iONclassic may provide Users with the
                opportunity to download certain of portions of its Materials, to
                include protected trademarks, from the Platform. If you download
                or otherwise obtain such trademarks, iONClassic grants you a
                limited right to: (i) use such trademarks for the sole purpose
                of referencing the Platform; and (ii) to use only such
                trademarks that are made available with the permission of
                iONclassic. You may not: (i) alter or change the appearance of
                such trademarks, (ii) use such trademarks in any manner that
                suggests iONclassic sponsors or endorses a product, service,
                promotion, contest; or (iii) use such Marks for any other
                purpose deemed by iONclassic to be inappropriate. iONclassic may
                revoke your right to such trademarks at any time at our sole
                discretion.
              </Title>
              <Title className="terms-header" level={5}>5.<b>User Content</b></Title>
              <Title className="sub-header" level={5}>
                You retain all Intellectual Property rights in and to any
                content that you post on or otherwise enter into the Platform,
                including without limitation, photos, videos, messages, various
                forms of communication as made available to you through the
                Platform from time to time (collectively, “User Content”). You
                grant iONclassic a worldwide, perpetual, irrevocable,
                royalty-free, non-exclusive right and license to exercise any
                and all copyright, trademark, publicity, and database rights you
                have in the content, in all media known now or in the future.
                You acknowledge and agree that we: (i) act only as a forum for
                the online distribution and publication of User Content; (ii)
                make no warranty that User Content will be made available on the
                Platform; (iii) we may limit the amount of User Content you may
                post and may charge a fee related to User Content at any time,
                in our sole discretion; (iv) have the right (but not the
                obligation) to take any action deemed appropriate by us with
                respect to any User Content, including without limitation the
                monitoring, posting, and removal of User Content; (v) have no
                responsibility or liability for the deletion or failure to store
                any User Content, whether or not the User Content was actually
                made available on the Platform; (vi) exercise no editorial
                control over any User Content available on the Platform; and
                (vii) may reject, approve, or modify your User Content at our
                sole discretion.
              </Title>
              <Title className="terms-header" level={5}>
                You represent and warrant that your User Content:
              </Title>
              
              <List className="terms-list"
              itemLayout="horizontal"
              dataSource={will}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta avatar={`${index + 1}.`} title={item.title} />
                </List.Item>
              )}
            />
              <Title className="terms-header" level={5}>
                6.<b>You agree that: </b>(i) you are solely responsible for your User
                Content; and (ii) you will refrain from posting, transmitting,
                or otherwise publishing any of the following:
              </Title>
              
              <List className="terms-list"
              itemLayout="horizontal"
              dataSource={user}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta avatar={`${index + 1}.`} title={item.title} />
                </List.Item>
              )}
            />
              <Title className="terms-header" level={5}>
                7.Using the Platform’s functionality for deleting, hiding, or
                otherwise disposing of information does not imply permanent
                deletion of User Content or information.
              </Title>
              <Title className="terms-header" level={5}>8.<b>Using iONclassic</b></Title>
              <Title className="terms-header" level={5}>
                Unless otherwise specifically indicated in these Terms or on the
                Platform, while using the Platform, you must not:
              </Title>
              <List className="terms-list"
              itemLayout="horizontal"
              dataSource={infre}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta avatar={`${index + 1}.`} title={item.title} />
                </List.Item>
              )}
            />
              <Title className="terms-header" level={5}>9.<b>Links to Third-Party Materials</b></Title>
              <Title className="sub-header" level={5}>
                The Platform may display, include, or make available links to
                other websites and related third-party products and services,
                including through third-party advertising (collectively,
                “Third-Party Materials”). iONclassic is not responsible for the
                content, accuracy, or opinions provided by Third-Party
                Materials, and Third-Party Materials are not investigated,
                monitored, or checked for accuracy or completeness by us. No
                Third-Party Materials are approved or endorsed by iONclassic. If
                you access Third-Party Materials, you do so at your own risk and
                should review such third-parties’ policies for your better
                understanding. iONclassic is not responsible or liable for any
                loss or damage of any kind incurred as the result of your direct
                dealings with third-party providers or otherwise resulting from
                the presence of Third-Party Materials on the Platform.
              </Title>
              <Title className="terms-header" level={5}>10.<b>Subscriptions</b></Title>
              <Title className="sub-header" level={5}>
                The Platform may display, include, or make available links to
                other websites and related third-party products and services,
                including through third-party advertising (collectively,
                “Third-Party Materials”). iONclassic is not responsible for the
                content, accuracy, or opinions provided by Third-Party
                Materials, and Third-Party Materials are not investigated,
                monitored, or checked for accuracy or completeness by us. No
                Third-Party Materials are approved or endorsed by iONclassic. If
                you access Third-Party Materials, you do so at your own risk and
                should review such third-parties’ policies for your better
                understanding. iONclassic is not responsible or liable for any
                loss or damage of any kind incurred as the result of your direct
                dealings with third-party providers or otherwise resulting from
                the presence of Third-Party Materials on the Platform.
              </Title>
              <Title className="terms-header" level={5}>11.<b>Fees</b></Title>
              <Title className="sub-header" level={5}>
                Depending on your User designation, you may be charged fees to
                purchase Services offered on the Platform. If you are charged
                fees, you will have an opportunity to review and accept the fees
                that you will be charged, which we may change from time to time
                and will update by placing on our Platform. We may choose to
                temporarily change the fees for our Services for promotional
                events (for example, temporary discounts) or new Services, and
                such changes are effective when we post a temporary promotional
                event or new Service on the Platform, or as notified through
                promotional correspondence. You must provide certain information
                in order to facilitate purchases, including a credit card number
                and other related payment information (“Payment Information“) as
                required by iONclassic and our third party payment processor.
                Our handling of your Payment Information shall be governed by
                iONclassic’s Privacy Policy. You agree to pay iONclassic all
                charges for any purchases made through the Platform inclusive of
                all taxes or other fees that may be imposed on your payments, as
                displayed to you at the time of payment. You authorize
                iONclassic to charge your chosen payment method for such
                purchases. You authorize iONclassic to correct any billing
                errors or mistakes even if iONclassic has already requested or
                received payment. In the event your payment method information
                changes or is updated, you authorize iONclassic to obtain such
                changed or updated information from any relevant service
                provider as may be reasonably necessary to continue your use of
                the Platform uninterrupted. Unless otherwise provided herein,
                charges for purchases are non-refundable, but you may ask for a
                refund at any time by sending written notice to
                help@ionclassic.com, or by mailing or delivering written notice
                to iONclassic, LLC, [ADDRESS]. We may make refunds upon your
                request in our sole discretion. A chargeback or reversed payment
                initiated by you may result in iONclassic’s election to
                terminate your account immediately in its sole discretion.
              </Title>
              <Title className="terms-header" level={5}>12.<b>Taxes</b></Title>
              <Title className="sub-header" level={5}>
                You are responsible for paying any taxes that accrue to you by
                your use of the Platform, including any goods and services or
                value-added taxes. You acknowledge that you must comply with
                your obligations under tax provisions in your jurisdiction,
                including, without limitation, any and all applicable state and
                federal taxes.
              </Title>
              <Title className="terms-header" level={5}>13.<b>Notification of Copyright Infringement</b></Title>
              <Title className="sub-header" level={5}>
                iONclassic respects the intellectual property rights of others
                and encourages its users to do the same. In accordance with the
                Digital Millennium Copyright Act (“DMCA”), iONclassic will
                respond expeditiously to notices of alleged infringement that
                are reported to iONclassic’s Designated Copyright Agent. If you
                are a copyright owner, authorized to act on behalf of one, or
                authorized to act under any exclusive right under copyright,
                please report alleged copyright infringements taking place on or
                through the Platform by completing the following notice
                (“Notice”) and delivering it via email to help@iONclassic.com or
                by U.S. mail to the address provided below.
              </Title>
              <Title level={2}>DMCA NOTICE OF ALLEGED INFRINGEMENT</Title>
              <List className="terms-list"
              itemLayout="horizontal"
              dataSource={iden}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta avatar={`${index + 1}.`} title={item.title} />
                </List.Item>
              )}
            />
              <Title className="terms-sub-child-header" level={5}>
                (i) “I hereby state that I have a good faith belief that the
                disputed use of the copyrighted material is not authorized by
                the copyright owner, its agent, or the law (e.g., as a fair
                use)”; and
              </Title>
              <Title className="terms-sub-child-header" level={5}>
                (ii) “I hereby state that the information in this Notice is
                accurate and, under penalty of perjury, that I am the owner, or
                authorized to act on behalf of the owner, of the copyright or of
                an exclusive right under the copyright that is allegedly
                infringed.”
              </Title>
              <Title className="terms-sub-header" level={5}>
                5.Provide your full legal name and your electronic or physical
                signature.
              </Title>
              <Title className="terms-header" level={5}>
                14.In addition to an email delivery, please deliver this Notice,
                with all items completed, to: iONclassic, LLC, ATTN: iONclassic
                Copyright Agent, [ADDRESS].
              </Title>
              <Title level={3} className="terms-sub-header">Counter Notices</Title>
              <Title className="terms-sub-header" level={5}>
                A poster of allegedly infringing material may make a counter
                notice pursuant to Sections 512(g)(2) and 512(g)(3) of the DMCA.
                When iONclassic receives a counter notice, it may reinstate the
                material in question. To provide a counter notice to us, please
                return the following form to the iONclassic Copyright Agent.
              </Title>
              <Title className="terms-sub-header" level={5}>COUNTER NOTICE</Title>
              <List className="terms-list"
              itemLayout="horizontal"
              dataSource={ident}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta avatar={`${index + 1}.`} title={item.title} />
                </List.Item>
              )}
            />
              <Title className="terms-header" level={5}>
                15.The Counter Notice should be delivered to iONclassic, LLC,
                ATTN: iONclassic Copyright Agent, [ADDRESS].
              </Title>
              <Title className="terms-sub-header" level={5}>
                No act or omission of iONclassic in furtherance of the
                provisions of this Section 11 shall give rise to any liability
                of iONclassic with respect to any content posted by any User or
                other third party.
              </Title>
              <Title className="terms-header" level={5}>16.<b>Inactive Accounts</b></Title>
              <Title className="terms-sub-header" level={5}>
                We reserve the right to close an inactive User Accounts that
                have not been logged into for a period of time greater than six
                (6) months
              </Title>
              <Title className="terms-header" level={5}>17.<b>Right to Refuse Service</b></Title>
              <Title className="terms-sub-header" level={5}>
                We may close, suspend, or limit your access to your Account
                without reason or without limitation:
              </Title>
              <List className="terms-list terms-sub-child-header"
              itemLayout="horizontal"
              dataSource={deter}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta avatar={`${index + 1}.`} title={item.title} />
                </List.Item>
              )}
            />
              <Title className="terms-header" level={5}>18.<b>Remedies</b></Title>
              <Title className="sub-header" level={5}>
                Without limiting our other remedies, to the extent you have
                breached these Terms, you must pay us all fees incurred prior to
                termination of your Account and reimburse us for all losses,
                costs, and reasonable expenses (including legal fees) related to
                investigating such breach and collecting such fees. The damages
                that iONclassic will sustain as a result of your breach of these
                Terms will be substantial and will potentially include without
                limitation fines and other related expenses imposed on us by our
                payment processors which damages may be extremely difficult and
                impracticable to ascertain. If you breach these Terms, we may
                pursue all available legal remedies to recover our losses.
              </Title>
              <Title className="terms-header" level={5}>19.<b>Closing Your Account</b></Title>
              <Title className="sub-header" level={5}>
                You may close your Account at any time. Account closure is
                subject to your paying any outstanding fees or amounts owing on
                the Account. iONclassic will retain your personal information
                only as long as necessary to perform the Services owed to you
                under these Terms or collect payment for such Services, but
                closing your account does not necessarily imply deletion or
                removal of all of the information we hold.
              </Title>
              <Title className="terms-header" level={5}>20.<b>Indemnity</b></Title>
              <Title className="sub-header" level={5}>
                You agree to indemnify, defend, and hold harmless Company and
                its officers, directors, managers, members, employees, agents,
                affiliates, successors, and assigns from and against any and all
                losses, damages, liabilities, deficiencies, claims, actions,
                judgments, settlements, interest, awards, penalties, fines,
                costs, or expenses of whatever kind, including without
                limitation reasonable attorneys’ fees, arising from or relating
                to your use or misuse of the Platform, your breach of these
                Terms, or the User Content you submit or make available through
                the Platform.
              </Title>
              <Title className="terms-header" level={5}>21.<b>Security</b></Title>
              <Title className="sub-header" level={5}>
                You must immediately notify us upon becoming aware of any
                unauthorized access or any other security breach to the Platform
                or your Account and do everything possible to mitigate the
                unauthorized access or security breach (including preserving
                evidence and notifying appropriate authorities). Your User
                Account is yours only, and you must not share your password with
                others. You are solely responsible for securing your password
                and for all activities that occur under your Account. We will
                not be liable for any loss or damage arising from unauthorized
                access of your Account resulting from your failure to secure
                your password. We use commercially reasonable security measures
                to protect your Account and User Content though we cannot
                guarantee absolute security of your Account. iONclassic is not
                responsible or liable for any third party access to or use of
                your Account or the User Content you post. In the event of an
                unauthorized access or security breach of any type, notify us as
                soon as possible by telephone at [PHONE] between the hours of
                [TIME FRAME] on [DAYS] or in writing at help@ionclassic.com.
              </Title>
              <Title className="terms-header" level={5}>22.<b>Disclaimer of Warranties</b></Title>
              <Title className="sub-header" level={5}>
                OUR SERVICES, THE PLATFORM, AND ALL CONTENT ON IT, ARE provided
                to you “AS IS” and with all faults and defects without warranty
                of any kind. To the maximum extent permitted under applicable
                law, Company on its own behalf and on behalf of its affiliates
                and its and their respective licensors and service providers,
                expressly disclaims all warranties, whether express, implied,
                statutory, or otherwise, with respect to the Platform, including
                all implied warranties of merchantability, fitness for a
                particular purpose, title, and non-infringement, and warranties
                that may arise out of course of dealing, course of performance,
                usage, or trade practice. Without limitation to the foregoing,
                Company provides no warranty or undertaking, and makes no
                representation of any kind that the Platform will meet your
                requirements, achieve any intended results, be compatible, or
                work with any other software, applications, systems, or
                services, operate without interruption, meet any performance or
                reliability standards, or be error-free, or that any errors or
                defects can or will be corrected. Some jurisdictions do not
                allow the exclusion of or limitations on implied warranties or
                the limitations on the applicable statutory rights of a
                consumer, so some or all of the above exclusions and limitations
                may not apply to you.
              </Title>
              <Title className="terms-header" level={5}>23.<b>Limitation of Liability</b></Title>
              <Title className="sub-header" level={5}>
                In no event shall we, our related entities, our affiliates, or
                staff be liable under any cause of action, including without
                limitation tort, breach of contract, or warranty for:
              </Title>
              <List className="terms-list terms-sub-child-header"
              itemLayout="horizontal"
              dataSource={any}
              renderItem={(item, index) => (
                <List.Item>
                  <List.Item.Meta avatar={`${index + 1}.`} title={item.title} />
                </List.Item>
              )}
            />
              <Title className="terms-header" level={5}>
                24.The limitations on our liability to you above shall apply
                whether or not we, our related entities, our affiliates, or
                staff have been advised of the possibility of such losses or
                damages arising.
              </Title>
              <Title className="terms-sub-header" level={5}>
                Notwithstanding the above provisions, nothing in these Terms is
                intended to limit or exclude any liability on the part of us and
                our affiliates and related entities where and to the extent that
                applicable law prohibits such exclusion or limitation. In no
                event shall iONclassic’s total aggregate liability, under or in
                relation to any warranty or condition implied by law, be more
                than the aggregate sum total of fees paid by you to iONclassic,
                if any, for your access to the Platform or your purchase of
                products on the Platform in the last twelve (12) months. As some
                jurisdictions do not allow some of the exclusions or limitations
                as established above, some of these exclusions or limitations
                may not apply to you. In that event, the liability will be
                limited as far as legally possible under the applicable law. We
                may plead these Terms in bar to any claim, action, proceeding,
                or suit brought by you against us for any matter arising out of
                any transaction or otherwise in respect of these Terms.
              </Title>
              <Title className="terms-header" level={5}>25.<b>Notices</b></Title>
              <Title className="sub-header" level={5}>
                Legal notices will be served to the email address you provide to
                iONclassic during the registration process. Notice will be
                deemed given 24 hours after email is sent, unless the sending
                party is notified that the email address is invalid or that the
                email has not been delivered. Alternatively, we may give you
                legal notice by mail to the address provided by you during the
                registration process. In such case, notice will be deemed given
                three days after the date of mailing. Any notices to iONclassic
                must be given by registered U.S. Mail to iONclassic, LLC, ATTN:
                iONclassic, [ADDRESS].
              </Title>
              <Title className="terms-header" level={5}>26.<b>Governing Law; Disputes</b></Title>
              <Title className="sub-header" level={5}>
                These Terms will be governed in all respects by the laws of the
                state of Florida, USA without regard to the internal law of
                Florida regarding conflicts of laws. Other than as provided
                herein, with respect to any suit, action or proceeding relating
                hereto, each party hereby irrevocably submits to the exclusive,
                personal jurisdiction of the courts of competent jurisdiction
                located in Hillsborough County, Florida, and waives any
                objection thereto. The prevailing party to any dispute shall be
                entitled to reimbursement of its fees and costs from the other
                party. EACH PARTY WAIVES, TO THE FULLEST EXTENT PERMITTED BY
                LAW, ANY RIGHT IT MAY HAVE TO A TRIAL BY JURY IN ANY ACTION
                ARISING HEREUNDER.
              </Title>
              <Title className="terms-sub-header" level={5}>
                Alternatively, for any claim, iONclassic, in its sole
                discretion, may elect to resolve the dispute in a cost effective
                manner through binding, non-appearance-based arbitration. If
                iONclassic elects arbitration, such arbitration will be
                initiated through an established alternative dispute resolution
                (“ADR”) provider, which is to be mutually selected from a panel
                of ADR providers that iONclassic will provide to you. The ADR
                provider and the parties must comply with the following rules:
                (i) he arbitration shall be conducted by telephone, online, or
                be solely based on written submissions; (ii) the arbitration
                shall not involve any personal appearance by the parties or
                witnesses; and (iii) any judgment on the award rendered by the
                arbitrator may be entered in any court of competent
                jurisdiction.
              </Title>
              <Title className="terms-sub-header" level={5}>
                All claims you bring against iONclassic must be resolved in
                accordance with these Terms. All claims filed or brought
                contrary to this Agreement shall be considered improperly filed
                and a breach of these Terms. You shall not pursue any claims
                arising under this Agreement on a class action or other
                representative basis, and will not seek to coordinate or
                consolidate any arbitration or action hereunder with any other
                proceeding. iONclassic’s failure to act with respect to a breach
                by you or others does not waive our right to act with respect to
                subsequent or similar breaches.
              </Title>
              <Title className="terms-sub-header" level={5}>
                Notwithstanding the foregoing, the parties are not required to
                arbitrate any dispute in which either party seeks equitable or
                other relief for: (1) the alleged infringement of Intellectual
                Property Rights; or (ii) allegedly criminal conduct under laws
                of the United States or any state thereof.
              </Title>
              <Title className="terms-header" level={5}>27.<b>Privacy</b></Title>
              <Title className="terms-sub-header" level={5}>
                You acknowledge that when you download, install, or use the
                Platform, iONclassic may use automatic means (including, for
                example, cookies and web beacons) to collect information about
                your device and about your use of the Platform. You also may be
                required to provide certain information about yourself as a
                condition to downloading, installing, or using the Platform or
                certain of its features or functionality, and the Platform may
                provide you with opportunities to share information about
                yourself with others. All information we collect through or in
                connection with the Platform, including without limitation your
                Registration Data and Payment Information, is subject to our
                Privacy Policy at [LINK], which is incorporated herein and made
                a part hereof. If you object to your information being
                transferred or used in this way, then you must not use the
                Platform. By downloading, installing, using, and providing
                information to or through the Platform, you consent to all
                actions taken by us with respect to your information in
                compliance with the Privacy Policy.
              </Title>
              <Title className="terms-sub-header" level={5}>
                iONclassic is the sole owner of the Personal Information
                collected through the Platform, including without limitation any
                information that you give us by email or by direct contact with
                you. We use your information as described in the iONclassic
                Privacy Policy. If you object to your information being
                transferred or used in accordance with the Terms herein or the
                terms of the Privacy Policy, then you must not use the Platform.
                For the avoidance of doubt, your name and some portion of your
                Personal Information, as relevant, shall be used for
                identification purposes in the normal course of conducting
                business. This may include use on statements and any other
                relevant access points where you conduct business on the
                Platform. iONclassic may use your Personal Information to
                contact you regarding offers from our affiliates from time to
                time, unless you opt out of such notifications. Otherwise, we
                will not sell or share your Personal Information with any third
                party other than as necessary to provide you the Platform.
              </Title>
              <Title className="terms-header" level={5}>General</Title>
              <Title className="terms-sub-header" level={5}>
                These Terms, including any addenda hereto, supersedes any other
                agreement between you and the Company and contains the entire
                understanding of the parties relating to the subject matter
                hereof. The invalidity or unenforceability of any provision of
                these Terms shall not affect the validity or enforceability of
                any other provision. Any invalid or unenforceable provision
                shall be deemed severed from these Terms to the extent of its
                invalidity or unenforceability, and the remainder of these Terms
                shall otherwise remain in full force and effect. Our failure to
                enforce any part of this document is not a waiver of any of our
                rights to later enforce that or any other part of this document.
                The waiver of any breach of any term, covenant, or condition
                herein contained, or our failure to seek redress for the
                violation of, or to insist upon the strict performance of, any
                covenant or condition of these Terms shall not be deemed to be a
                waiver of such term, covenant or condition, or any subsequent
                breach of the same. We may assign any of our rights and
                obligations under this document from time to time. Headings are
                for reference purposes only and in no way define, limit,
                construe or describe the scope or extent of such section.
              </Title>
               
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
      <ScrollToTop className="scroll" />
    </>
  );
};

export default TermsCondition;
