import React, {useState} from 'react'
import Slider from 'react-slick'
import { Image as Images } from "./Images";


function ShopHighLight() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

    const ShopsHighlights = [
        {
          img: "ShopProfilePages1",
          tagline: "USE TAGLINE, USE TAGLINE, USE TAGLINE 1",
          btnLabel: "MARS MOTORS & WORKS",
          btnLink: "",
        },
        {
          img: "ShopProfilePages2",
          tagline: "USE TAGLINE, USE TAGLINE, USE TAGLINE 2",
          btnLabel: "MARS MOTORS & WORKS",
          btnLink: "",
        },
        {
          img: "ShopProfilePages3",
          tagline: "USE TAGLINE, USE TAGLINE, USE TAGLINE 3",
          btnLabel: "MARS MOTORS & WORKS",
          btnLink: "",
        },
        {
          img: "ShopProfilePages4",
          tagline: "USE TAGLINE, USE TAGLINE, USE TAGLINE 4",
          btnLabel: "MARS MOTORS & WORKS",
          btnLink: "",
        },
        {
          img: "ShopProfilePages5",
          tagline: "USE TAGLINE, USE TAGLINE, USE TAGLINE 6",
          btnLabel: "MARS MOTORS & WORKS",
          btnLink: "",
        },
        {
          img: "ShopProfilePages6",
          tagline: "USE TAGLINE, USE TAGLINE, USE TAGLINE 7",
          btnLabel: "MARS MOTORS & WORKS",
          btnLink: "",
        },
        {
          img: "ShopProfilePages7",
          tagline: "USE TAGLINE, USE TAGLINE, USE TAGLINE 8",
          btnLabel: "MARS MOTORS & WORKS",
          btnLink: "",
        },
        {
          img: "ShopProfilePages8",
          tagline: "USE TAGLINE, USE TAGLINE, USE TAGLINE 9",
          btnLabel: "MARS MOTORS & WORKS",
          btnLink: "",
        },
      ];

  return (
    <div className="container-fluid shops-highlights-fluid">
          <div className="container mx-auto common-heading">
            <h6 className="text-uppercase text-lg-start">Shops Highlights</h6>
          </div>
            <Slider arrows={false} asNavFor={nav2} ref={(slider1) => setNav1(slider1)} infinite={true} loop={true} 
            dots={false} 
             adaptiveHeight={true}>
              {ShopsHighlights?.map((shop, index) => {
                return (
                  <div key={index} className="position-relative">
                    <img
                      
                      src={`/assets/images/shop/png/${shop?.img}.png`}
                      alt={shop?.img}
                      className="img-fluid shopHighlightImg"
                    />
                  </div>
                );
              })}
            </Slider>
            <Slider
            responsive={[
              {
                breakpoint: 1650,
                settings: {
                  slidesToShow:7
                }
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow:3
                }
              }
            ]}
            asNavFor={nav1}
            ref={(slider2) => setNav2(slider2)}
            nextArrow={
              <img
                src={Images.primary_secondary_right_icon}
                alt=""
                className="img-fluid"
              />}
            prevArrow={
                <img
                  src={Images.primary_secondary_left_icon}
                  alt=""
                  className="img-fluid" 
                />}
             arrows={true} className='slider-nav'  
             slidesToShow={7} 
             focusOnSelect={true} centerMode={true} infinite={true} loop={true}  dots={false} 
             adaptiveHeight={true}>
              {ShopsHighlights?.map((shop, index) => {
                return (
                  <div key={index} className="position-relative">
                    <img
                      src={`/assets/images/shop/png/${shop?.img}.png`}
                      alt={shop?.img}
                      className="img-fluid shopHighlightImg"
                    />
                  </div>
                );
              })}
            </Slider>
            
      </div>
  )
}

export default ShopHighLight