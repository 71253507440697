import React from "react";
import { Button } from "antd";
import { Image as Images } from "../Images";
import { Link } from "react-router-dom";
const DownloadApp = () => {
  
  return (
    <>
      <div className="ion-app-details-card row mx-0 align-items-center">
        <div className="py-5 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <h4 className="text-uppercase">
            Download the <span>iON Classic App today!</span>
          </h4>
          <ul className="list-inline mb-0">
            <li className="me-4 list-inline-item">
              <Button className="bg-transparent p-0 border-0 h-auto">
              <Link to={"https://apps.apple.com/us/app/ion-classic/id1482774542"} target="_blank" >
              <img
                  src={Images.app_store_logo}
                  alt={""}
                  className="img-fluid"
                />
               </Link>
                
                
              </Button>
              
            </li>
            <li className="list-inline-item">
              <Button className="bg-transparent p-0 border-0 h-auto"
              
              >
             <Link to={"https://play.google.com/store/apps/details?id=com.ionclassic&pli=1"} target="_blank">
             <img
                  src={Images.google_play_store_logo}
                  alt={""}
                  className="img-fluid"
                  
                />
             </Link>
                
                 
              </Button>
            </li>
          </ul>
        </div>
        <div className="align-self-end col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div>
            <img
              className="img-fluid app-stores-ss"
              src="/assets/images/apps-mockup-ss.png"
              alt="ss"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadApp;
