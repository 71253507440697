import React, { useState } from "react";
import "../../assets/styles/auths.scss";
import "../../assets/styles/form.scss";
import { Image as Images } from "../Images";
import { Button, Form, Input, message } from "antd";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ApolloError, isApolloError, useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "../../gqloperations/auth/change-password-mutations";

const ChangePassword = () => {
  const token = useLocation().search;
  const searchParams = new URLSearchParams(token);
  localStorage.setItem("token", searchParams.get("reset_password_token"));
  const [isLoading, setIsLoading] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [changePasswordMutation, { data, loading, error }] =
    useMutation(CHANGE_PASSWORD);

  const [form] = Form.useForm();

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabledSave(hasErrors);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    onSubmit(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onSubmit = async (values) => {
    setDisabledSave(true);

    setIsLoading(true);
    changePasswordMutation({
      variables: values,
      context: {
        headers: {
          Authorization: searchParams.get("reset_password_token"),
        },
      },
    })
      .then(async (response) => {
        message.success("passsword changed successfully.");
        console.log("res", await response);
        setTimeout(() => {
          localStorage.removeItem("token");
          window.location.replace("/");
        }, 100);
      })
      .catch((e) => {
        console.log("err", e);
        if (isApolloError(e)) {
          for (const gqlError of e.graphQLErrors) {
            message.error(gqlError.message);
          }
        }

        // message.error(err.ApolloError.data.error);
      })
      .finally((response) => {
        console.log("response", response);
        setIsLoading(false);
        setDisabledSave(false);
      });
  };
  console.log("errorMessage", errorMessage);
  return (
    <div className="container-fluid auths-main-fluid auths-forgot-password">
      <div className="row mx-0 position-relative">
        <div className="auth-shadow-effect">
          <img src={Images.auth_shadow_effect} alt="" className="img-fluid" />
        </div>
        {!emailSent ? (
          <div className="auth-left-div">
            <div className="text-center auth-logo-section">
              <a href="#">
                <img src={Images.auth_logo} alt="" className="img-fluid" />
              </a>
            </div>
            <div className="text-center basic-details-auth mt-5 pt-5">
              <h4>Reset Password</h4>
              <p>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia.
              </p>
            </div>
            <div className="common-form common-form-forgot-pwd h-100 position-relative">
              <Form
                name="login-form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete={false | "off"}
                layout="vertical"
                onFieldsChange={handleFormChange}
                form={form}
                requiredMark={false}
              >
                <Form.Item
                  label={""}
                  name="currentPassword"
                  className="no-border"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your current password!",
                    },

                    {
                      min: 6,
                      message: "Password must be of atlest 6 characters",
                    },
                  ]}
                >
                  <Input.Password placeholder="Enter your current password" />
                </Form.Item>
                <Form.Item
                  label={""}
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your new password!",
                    },

                    {
                      min: 6,
                      message: "Password must be of atlest 6 characters",
                    },
                  ]}
                >
                  <Input.Password placeholder="Enter your new password" />
                </Form.Item>
                <Form.Item>
                  <Button
                    className="theme-common-btn w-100"
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    disabled={disabledSave}
                  >
                    Change Password
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        ) : (
          <div className="col-12 col-sm-6 mx-auto auth-left-div vh-100">
            <div className="row h-100 align-items-center">
              <div className="basic-details-confirm">
                <p>
                  We have reset your password please login with new password.
                </p>
                <Button
                  className="theme-common-btn w-100"
                  type="primary"
                  htmlType="submit"
                >
                  Back to Login
                </Button>
              </div>
            </div>
          </div>
        )}

        {/* submit forgot email then show this screen*/}
      </div>
    </div>
  );
};

export default ChangePassword;
