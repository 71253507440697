import { gql } from "@apollo/client";
// const { auth_token } = JSON.parse(localStorage.getItem("userDetails"));
export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(
      input: { currentPassword: $currentPassword, newPassword: $newPassword }
    ) {
      clientMutationId
    }
  }
`;
