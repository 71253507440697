import React ,{useEffect}from "react";
import { Button } from "antd";
import { Image as Images } from "../../Images";
import { preventNavigationBack } from "../../../utils/helper";

const SelectPlans = (props) => {
  // eslint-disable-next-line react/prop-types
  const { setCardVisible } = props;
  useEffect(() => {
    preventNavigationBack();
    return () => {
      window.onpopstate = null;
    };
  }, []);
  return (
    <div className="row">
      <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mx-auto text-center basic-details-auth">
        <h4>Select Plan</h4>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 mx-auto">
            <p>
              iONclassic Monthly subscription offers a cancel at anytime option
              with no long commitments.
            </p>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 mx-auto">
        <div className="row mx-0 plan-card-row">
          <div className="col-12">
            <h5 className="text-center">$09.95/month</h5>
            <p className="text-center">Popular plan</p>
            <ul className="list-inline">
              <li>
                <img
                  src={Images.check_icon_circle}
                  alt=""
                  className="img-fluid"
                />
                Unlimited cars, mechanics and pictures
              </li>
              <li>
                <img
                  src={Images.check_icon_circle}
                  alt=""
                  className="img-fluid"
                />
                Unique QR codes
              </li>
              <li>
                <img
                  src={Images.check_icon_circle}
                  alt=""
                  className="img-fluid"
                />
                Digital Showroom
              </li>
              <li>
                <img
                  src={Images.check_icon_circle}
                  alt=""
                  className="img-fluid"
                />
                Link social media accounts
              </li>
              <li>
                <img
                  src={Images.check_icon_circle}
                  alt=""
                  className="img-fluid"
                />
                Social Media features
              </li>
              <li>
                <img
                  src={Images.check_icon_circle}
                  alt=""
                  className="img-fluid"
                />
                eMail transfer for customer data
              </li>
            </ul>
            <Button
              className="get-started-btn"
              onClick={() => setCardVisible(false)}
            >
              Get started
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPlans;
