import { combineReducers } from "redux";
import { sessionReducer } from "redux-react-session";
import Loginreducer from "./auth/auth.reducer";
import alertReducer from "./alert/alert.reducer";
import SuccessAlertReducer from "./successModal/successModal.reducer";

const rootReducer = combineReducers({
  loginData: Loginreducer,
  session: sessionReducer,
  alert: alertReducer,
  successAlert: SuccessAlertReducer,
});

export default rootReducer;
