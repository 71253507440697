import React, { useState } from "react";
import { Button, Form, Input, message, Select } from "antd";
import { isApolloError, useMutation } from "@apollo/client";
import { SEND_INVITE_MECHANICS } from "../../gqloperations/mechanics/send-invite.mutation";
import { Image as Images } from "../Images";
import CountryData from "../../utils/country_dial_info.json";
import { useNavigate } from "react-router-dom";

function AddMechanics() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [countryCode, setCountryCode] = useState("US +1");
  const [sendInvitation, { data, loading, error }] = useMutation(
    SEND_INVITE_MECHANICS
  );

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabledSave(hasErrors);
  };

  const onFinish = (values) => {
    values.phoneNumber = countryCode.split(" ")[1] + values.phoneNumber;
    sendInvitation({ variables: values })
      .then((response) => {
        console.log("res", response);
        message.success("Invitation Sent successfully!");
        form.resetFields();
        setDisabledSave(true);
        navigate("/managers/mechanics");
      })
      .catch((e) => {
        // Handle error isApolloError
        if (isApolloError(e)) {
          for (const gqlError of e.graphQLErrors) {
            console.log("gq", gqlError);
            message.error(gqlError.message);
          }
        }
      });
  };

  const handleChange = (name, e, type) => {
    const TestRegx = {
      number: new RegExp(`^[0-9]*$`),
      alphaNumaric: new RegExp(`^[a-zA-Z0-9s ]*$`),
      letters: new RegExp(`^[a-zA-Z ]*$`),
    };
    if (TestRegx[type].test(e.target.value)) {
      console.log("true");
      form.setFieldValue(name, e.target.value.trim());
    } else {
      console.log("false");
      form.setFieldValue(name, e.target.value.slice(0, -1));
    }
  };

  return (
    <>
      <div className="container-fluid auths-main-fluid auths-main-profile overflow-auto">
        <div className="row mx-0 position-relative">
          <div className="auth-shadow-effect">
            <img src={Images.auth_shadow_effect} alt="" className="img-fluid" />
          </div>
          <div className="col-12 mx-auto auth-left-div">
            <div className="row">
              <div className="col-12 text-center auth-logo-section">
                <a href="/managers/home">
                  <img src={Images.auth_logo} alt="" className="img-fluid" />
                </a>
              </div>
              <div
                className="col-12 mechanics-main-container mx-auto"
                style={{ maxWidth: 600 }}
              >
                <div className="mx-auto text-center basic-details-auth">
                  <div className="mechanics-title text-center">
                    Add Your Mechanics
                  </div>
                  <div className="row">
                    <div className="col-12 mx-auto">
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      <p className="mechanics-sub-title">
                        Invite a Mechanic to join your shop. Mechanics can be
                        external subcontractors as well.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mx-auto common-form">
                  <Form
                    name="basic"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFieldsChange={handleFormChange}
                    requiredMark={false}
                    // onFinishFailed={onFinishFailed}
                    form={form}
                    autoComplete="off"
                  >
                    <div className="row">
                      <div className="col-12">
                        <Form.Item
                          label="First Name"
                          name="firstName"
                          rules={[
                            {
                              whitespace: true,
                              required: true,
                              message: "Please input First Name!",
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) =>
                              handleChange("firstName", e, "letters")
                            }
                            placeholder="Enter First Name"
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12">
                        <Form.Item
                          label="Last Name"
                          name="lastName"
                          rules={[
                            {
                              whitespace: true,
                              required: true,
                              message: "Please input Last Name!",
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) =>
                              handleChange("lastName", e, "letters")
                            }
                            placeholder="Enter Last Name"
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12">
                        <Form.Item
                          label="Phone Number"
                          name="phoneNumber"
                          rules={[
                            {
                              min: 10,
                              max: 10,
                              required: true,
                              whitespace: true,
                              message: "Please input your Phone Number!",
                            },
                          ]}
                          mask="... ... ...."
                        >
                          {console.log("country code", countryCode)}
                          <Input
                            maxLength={10}
                            mask="... ... ...."
                            className="Intl-phone-Input"
                            placeholder="XXX XXX XXXX"
                            onChange={(e) =>
                              handleChange("phoneNumber", e, "number")
                            }
                            addonBefore={
                              <Select
                                showSearch
                                onChange={(val) => setCountryCode(val)}
                                className="tel-add-on"
                                defaultValue="US +1"
                                filterOption={(input, option) =>
                                  input &&
                                  option.label &&
                                  option.label
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                onKeyPress={(e) => {
                                  // Allow digits (0-9) and the '+' character, prevent default for other keys
                                  if (!/^[0-9+]$/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              >
                                {CountryData?.map((item, index) => {
                                  return (
                                    <Select.Option
                                      key={index}
                                      value={item?.code + " " + item?.dial_code}
                                      label={item?.dial_code}
                                    >
                                      <i className="flag">{item?.flag}</i>{" "}
                                      {item?.dial_code}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            }
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12">
                        <Button
                          className="theme-common-btn subscribe-btn w-100"
                          disabled={disabledSave}
                          type="primary"
                          htmlType="submit"
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddMechanics;
