import React, { useEffect, useState } from "react";
import { GetCarDetails } from "../../gqloperations/cars/get-car-details.query.js";
import { useLocation, useNavigate } from "react-router-dom";
import tick from "../../assets/images/icons/Tick.svg";
import maskgroup from "../../assets/images/icons/maskgroup.svg";
import body from "../../assets/images/icons/body.svg";
import drivetype from "../../assets/images/icons/drivetype.svg";
import petrol from "../../assets/images/icons/petrol.svg";
import engine2 from "../../assets/images/icons/engine2.svg";
import transmission from "../../assets/images/icons/transmission.svg";
import Edit from "../../assets/images/icons/Edit.png";
import delete_icon from "../../assets/images/icons/delete_icon.svg";
import Frame from "../../assets/images/icons/Frame.svg";
import {
  Button,
  Dropdown,
  Select,
  Spin,
  message,
  Space,
  Modal,
  Input,
  Form,
} from "antd";
import AfterLoginHeader from "../common/AfterLoginHeader.js";
import Popup2 from "../popup/Popup2.js";
import { isApolloError, useMutation, useQuery } from "@apollo/client";
import { DELETE_CAR } from "../../gqloperations/cars/delete-car-mutation.js";
import { UPDATE_CAR } from "../../gqloperations/cars/update-car-mutation.js";
import "./ViewVehicleDetails.scss";
import { gql } from "@apollo/client";
import { DeleteOutlined } from "@ant-design/icons";
import { DELETE_ALL_CAR_IMAGE } from "../../gqloperations/cars/delete-all-car-image-mutation.js";
import { GET_CAR_CATEGORIES_IMAGE_LIST } from "../../gqloperations/cars/get-car-categories-image.mutation.js";
import { Image as Images } from "../../components/Images";
import { TRANSFER_RESTORATION } from "../../gqloperations/transferRestoration.js";
import { CHECK_CAR_OWNER } from "../../gqloperations/cars/car-owner-mutation.js";

function ViewVehicleDetails() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const id = useLocation().search;
  const searchParams = new URLSearchParams(id);
  const { CarData, loading, error } = GetCarDetails(searchParams.get("id"));
  // const { data } = GetCarImageCategoryList();

  const { data } = useQuery(GET_CAR_CATEGORIES_IMAGE_LIST, {
    variables: {
      id: searchParams.get("id"),
    },
    fetchPolicy: "network-only",
  });
  const [visible, setVisible] = React.useState(false);
  const [visible1, setVisible1] = React.useState(false);
  const [activeSubscriptionVisible, setActiveSubscriptionVisible] =
    useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const [Visibility, setVisibility] = React.useState(CarData?.car?.isPublic);
  const [openPrivacyPopup, setOpenPrivacyPopup] = useState(false);
  const [readBtn, setReadBtn] = React.useState(false);
  const [visibleDeleteCategory, setVisibleDeleteCategory] =
    React.useState(false);
  const [transPopup, setTransPopup] = React.useState(false);
  const [isOwner, setIsOwner] = React.useState();
  const [carCategories, setCarCategories] = React.useState([]);
  const [deleteIds, setDeleteIds] = React.useState("");
  const [disableTransferBtn, setDisableTransferBtn] = React.useState(false);
  const [deleteCar, {}] = useMutation(DELETE_CAR);
  const [updateCar, {}] = useMutation(UPDATE_CAR);
  const [checkCarOwner, {}] = useMutation(CHECK_CAR_OWNER);
  const [transferRestoration, {}] = useMutation(TRANSFER_RESTORATION);

  const [deleteAllImageFromCategory] = useMutation(DELETE_ALL_CAR_IMAGE);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  useEffect(() => {
    setVisibility(CarData?.car?.isPublic);
    checkCarOwner({ variables: { carId: searchParams.get("id") } })
      .then((res) => {
        console.log("Owner", res.data.carOwner.success);
        setIsOwner(res.data.carOwner.success);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [CarData, searchParams.get("id")]);

  const getImageCategoryIds = (id) => {
    const Ids = [];
    if (carCategories.length > 0) {
      const catObj = carCategories?.filter((item) => {
        return item?.id === id;
      });
      catObj[0].images.map((d) => {
        Ids.push(d?.id);
      });
      return Ids;
    }
  };

  useEffect(() => {
    const catImage = data?.car?.carImageCategories.filter((item) => {
      return item?.images?.length > 0;
    });
    setCarCategories(catImage);
  }, [data]);

  const handleVisibility = (val) => {
    setVisibility(val);
  };

  const handleDeleteCar = () => {
    deleteCar({ variables: { carId: searchParams.get("id") } })
      .then((res) => {
        console.log(res);
        message.success("Deleted successfully!");
        navigate("/managers/home");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCarPrivacy = () => {
    updateCar({
      variables: { id: searchParams.get("id"), isPublic: Visibility },
    })
      .then((res) => {
        console.log(res);
        message.success("Updated successfully!");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const MANAGERS_SUBSCRIPTION = gql`
    mutation {
      managerSubscription {
        success
      }
    }
  `;

  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `${token}`,
  };

  const [managersSubscription] = useMutation(MANAGERS_SUBSCRIPTION, {
    context: {
      headers,
    },
  });

  useEffect(() => {
    managersSubscription()
      .then((response) => {
        console.log("Subscription success:", response.data);
        setHasActiveSubscription(
          response.data?.managerSubscription?.success === true
        );
      })
      .catch((error) => {
        console.error("Error:", error);
        setHasActiveSubscription(false);
      });
  }, []);

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabledSave(hasErrors);
  };

  const handleDeleteImage = () => {
    console.log("id ~", deleteIds);
    const Ids = getImageCategoryIds(deleteIds);
    console.log("iddd", Ids);
    const payload = {
      ids: Ids,
    };

    deleteAllImageFromCategory({ variables: payload })
      .then((res) => {
        console.log("res", res);
        if (res?.data?.removeAllImage?.success) {
          setVisibleDeleteCategory(false);
          message.success("Images deleted successfully!");
          setTimeout(() => {
            window.location.reload();
          }, 150);
        }
      })
      .catch((err) => {});
  };

  const handleTransferRestoration = () => {
    form.submit();
  };
  const onFinish = (val) => {
    setDisableTransferBtn(true);
    console.log("formVal", val);
    transferRestoration({
      variables: { carId: searchParams.get("id"), email: val.email },
    })
      .then((res) => {
        if (res?.data?.managerTransferCar?.success) {
          form.resetFields();
          setDisableTransferBtn(false);
          setTransPopup(false);
          message.success("Restoration transferred successfully");
          setTimeout(() => {
            window.location.reload();
          }, 300);
        }
        console.log("res", res);
      })
      .catch((e) => {
        if (isApolloError(e)) {
          for (const gqlError of e.graphQLErrors) {
            console.log("gq", gqlError);
            setDisableTransferBtn(false);
            message.error(gqlError.message);
          }
        }
      });
  };

  if (loading)
    <div>
      <Spin tip="Loading..."></Spin>
    </div>;
  if (error)
    <div>
      <h5>Something went wrong.. please refresh your page.</h5>
    </div>;
  return (
    <div>
      <AfterLoginHeader />
      <div className="Viewvehicle_Container container">
        {/* <div className='navbar'> Navbar </div> */}
        <section className="header">
          <div className="vehicle_content">
            <div className="vehicle-heading">{CarData?.car?.model}</div>
            {CarData?.car?.isPublic !== Visibility && (
              <div
                onClick={() => setOpenPrivacyPopup(true)}
                className="tick-icon"
              >
                <img src={tick} width={20} height={20} />
              </div>
            )}
          </div>

          <div className="vehicle-text">
            <div className="text">
              {CarData?.car?.description?.length > 250 ? (
                <>
                  {!readBtn
                    ? CarData?.car?.description.slice(0, 250)
                    : CarData?.car?.description}{" "}
                  <span
                    className="theme-color"
                    onClick={() => setReadBtn(!readBtn)}
                  >
                    {readBtn ? "Read Less..." : "Read More..."}
                  </span>
                </>
              ) : (
                CarData?.car?.description
              )}
            </div>
            <div className="vehicle-dropdown">
              <span>Visibility</span>
              <span>
                {console.log("isPub", CarData?.car?.isPublic)}
                <Select
                  defaultValue={Visibility}
                  value={Visibility}
                  onChange={handleVisibility}
                  className="visiblity-select"
                  options={[
                    {
                      value: true,
                      label: "Public",
                    },
                    {
                      value: false,
                      label: "Private",
                    },
                  ]}
                ></Select>
              </span>
            </div>
          </div>
        </section>
        <section className="row viewvehical--body mt-5">
          <div className="col-lg-6 left--view--grid">
            <div className="vehicle_details">
              <div>
                <label>Make:</label>
                <label className="title-content text-white opacity-100 w-100 pe-3 ">
                  {CarData?.car?.make}
                </label>
              </div>
              <div>
                <label>Model:</label>
                <label className="title-content text-white opacity-100 w-100 pe-3 ">
                  {CarData?.car?.model}
                </label>
              </div>
              <div>
                <label>VIN:</label>
                <label className="title-content text-white opacity-100 w-100 pe-3 ">
                  {CarData?.car?.vin}
                </label>
              </div>
              <div>
                <label>Chassis:</label>
                <label className="title-content text-white opacity-100 w-100 pe-3 ">
                  {CarData?.car?.chassis}
                </label>
              </div>
              <div>
                <label>Owner:</label>
                <label className="title-content text-white opacity-100 w-100 pe-3 ">
                  {CarData?.car?.shopOwner}
                </label>
              </div>
            </div>

            <div className="Vehicle_Properties">
              <div className="mt-3 mb-5 vehicle_properties_containers">
                <div className="main_box">
                  <div className="image_container">
                    <img src={maskgroup} alt="meter" />
                  </div>
                  <div className="content_box">
                    <span className="content_name">Mileage</span>
                    <span>{CarData?.car?.mileage}</span>
                  </div>
                </div>

                <div className="main_box">
                  <div className="image_container">
                    <img src={engine2} alt="meter" />
                  </div>
                  <div className="content_box">
                    <span className="content_name">Engine</span>
                    <span>{CarData?.car?.engine}</span>
                  </div>
                </div>
                <div className="main_box">
                  <div className="image_container">
                    <img src={body} alt="meter" />
                  </div>
                  <div className="content_box">
                    <span className="content_name">Body Style</span>
                    <span>{CarData?.car?.bodyStyle}</span>
                  </div>
                </div>
                <div className="main_box">
                  <div className="image_container">
                    <img src={transmission} alt="meter" />
                  </div>
                  <div className="content_box">
                    <span className="content_name">Transmission</span>
                    <span>{CarData?.car?.transmission}</span>
                  </div>
                </div>
                <div className="main_box">
                  <div className="image_container">
                    <img src={petrol} alt="meter" />
                  </div>
                  <div className="content_box">
                    <span className="content_name">Fuel</span>
                    <span>{CarData?.car?.fuel}</span>
                  </div>
                </div>
                <div className="main_box">
                  <div className="image_container">
                    <img src={drivetype} alt="meter" />
                  </div>
                  <div className="content_box">
                    <span className="content_name">Drive Type</span>
                    <span>{CarData?.car?.driveType}</span>
                  </div>
                </div>
              </div>
              <div className="colors">
                <div className="mb-3">
                  <label className="fs-body fs-5 fw-light text-white-50 ">
                    Interior Color
                  </label>
                  <label class="title-content fs-4 fw-600 text-white w-100 pe-3 fs-5">
                    {CarData?.car?.interiorColor}
                  </label>
                </div>
                <div className="mb-3">
                  <label className="fs-body fs-5 fw-light text-white-50 ">
                    Exterior Color
                  </label>
                  <label class="title-content fs-4 fw-600 text-white w-100 pe-3 fs-5">
                    {CarData?.car?.exteriorColor}
                  </label>
                </div>
              </div>
            </div>
          </div>

          {carCategories?.length > 0 ? (
            <div className="col-lg-6 right--img-grid">
              {carCategories?.slice(0, 4).map((item, index) => {
                return (
                  <>
                    {item.images?.length > 0 ? (
                      <div key={index} className="image--container">
                        <>
                          {console.log("deleteIds", deleteIds)}
                          <Dropdown
                            menu={{
                              items: [
                                {
                                  label: (
                                    <Button
                                      // onClick={() => {
                                      //   setVisible(true);
                                      //   setDeleteImageIds(item);
                                      // }}

                                      onClick={() => {
                                        setVisibleDeleteCategory(true);
                                        setDeleteIds(item?.id);
                                      }}
                                      type="link"
                                      danger
                                    >
                                      <DeleteOutlined /> Delete
                                    </Button>
                                  ),
                                  key: "0",
                                },
                              ],
                            }}
                            overlayClassName="img-menu"
                            trigger={["click"]}
                            placement="bottomLeft"
                          >
                            <Space>
                              <a onClick={(e) => e.preventDefault()}>
                                <img
                                  className="menu-icon"
                                  src="/assets/icons/menu-dot-icon.svg"
                                />
                              </a>
                            </Space>
                          </Dropdown>
                          <img
                            //className="cover-image"
                            src={item.images[0].imageUrl}
                          />
                          {carCategories?.length - 3 > 0 && index === 3 ? (
                            <div
                              onClick={() =>
                                navigate(
                                  `/managers/home/details/categories?id=${searchParams.get(
                                    "id"
                                  )}`
                                )
                              }
                              className="See-all-btn"
                            >
                              <p>+{carCategories?.length - 3}</p>
                              See all{" "}
                            </div>
                          ) : (
                            <div
                              onClick={() =>
                                navigate(
                                  `/managers/home/details/categories/images?id=${searchParams.get(
                                    "id"
                                  )}&name=${item?.name}`
                                )
                              }
                              className="image--label text-capitalize"
                            >
                              <div>{item?.name}</div>
                            </div>
                          )}
                        </>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}{" "}
            </div>
          ) : (
            <div className="col-lg-6 no-image-container d-flex justify-content-end">
              {!loading && (
                <div>
                  <h4>No images added!</h4>
                  <Button
                    onClick={() => {
                      if (!hasActiveSubscription) {
                        setActiveSubscriptionVisible(true); // Show the pop-up for active subscribe
                      } else {
                        navigate(
                          `/managers/home/details/edit-images?id=${searchParams.get(
                            "id"
                          )}`
                        );
                      }
                    }}
                    className="add-restoration-btn border-0"
                  >
                    <img
                      src={Images.add_icon_white}
                      alt=""
                      className="img-fluid"
                    />
                    Add Images
                  </Button>
                </div>
              )}
            </div>
          )}
        </section>
        <div className="Vehicle_Buttons my-5">
          {isOwner && (
            <Button
              onClick={() => {
                if (!hasActiveSubscription) {
                  setActiveSubscriptionVisible(true); // Show the pop-up for active subscribe
                } else {
                  navigate(
                    `${window.location.pathname}/edit-details?id=${CarData?.car?.id}`,
                    { state: CarData }
                  );
                }
              }}
              className="btn btn-edit"
            >
              <img src={Edit} alt="edit" />
              Edit Details{" "}
            </Button>
          )}
          <Button
            // disabled={!CarData?.car?.carImages?.length > 0}

            onClick={() => {
              if (!hasActiveSubscription) {
                setActiveSubscriptionVisible(true); // Show the pop-up for active subscribe
              } else {
                navigate(
                  `${window.location.pathname}/view-slideshow?id=${CarData?.car?.id}`,
                  { state: CarData }
                );
              }
            }}
            className="btn btn-view"
          >
            <img src={Frame} alt="frame" /> View Slideshow{" "}
          </Button>
          {isOwner && (
            <Button
              onClick={() => {
                if (!hasActiveSubscription) {
                  setActiveSubscriptionVisible(true); // Show the pop-up for active subscribe
                } else {
                  setTransPopup(true);
                }
              }}
              className="btn btn-edit"
            >
              <img src={Edit} alt="edit" />
              Transfer Restoration{" "}
            </Button>
          )}
          {isOwner && (
            <Button
              onClick={() => {
                if (!hasActiveSubscription) {
                  setActiveSubscriptionVisible(true); // Show the pop-up for active subscribe
                } else {
                  setVisible(true);
                }
              }}
              className="btn btn-dlt"
            >
              <img src={delete_icon} alt="delete" /> Delete{" "}
            </Button>
          )}
        </div>
        <footer className="footer">
          <div>
            <h4> Comments</h4>
            <p>{CarData?.car?.comments}</p>
          </div>
        </footer>
      </div>
      <Popup2
        title={"Would you like to apply changes?"}
        description={""}
        visible={openPrivacyPopup}
        buttons={[
          {
            text: "Cancel",
            func: () => setOpenPrivacyPopup(false),
            className: "cancel-button",
          },
          {
            text: "Apply Changes",
            func: () => updateCarPrivacy(),
            className: "pop-up-subscribe-btn",
          },
        ]}
      ></Popup2>
      <Popup2
        title={"Are you sure you want to delete category?"}
        description={""}
        visible={visibleDeleteCategory}
        buttons={[
          {
            text: "Cancel",
            func: () => setVisibleDeleteCategory(false),
            className: "cancel-button",
          },
          {
            text: "Delete",
            func: () => handleDeleteImage(),
            className: "subscription-button",
          },
        ]}
      ></Popup2>
      <Popup2
        title={"Are you sure you want to delete your car profile?"}
        description={""}
        visible={visible}
        buttons={[
          {
            text: "No",
            func: () => {
              setVisible(false);
            },
            className: "cancel-button",
          },
          {
            text: "Yes",
            func: () => {
              setVisible(false);
              setVisible1(true);
            },
            className: "subscription-button",
          },
        ]}
      ></Popup2>
      <Popup2
        title={"Are you sure!"}
        description={"Click delete button, if you want to delete."}
        visible={visible1}
        buttons={[
          {
            text: "Cancel",
            func: () => {
              setVisible1(false);
            },
            className: "cancel-button",
          },
          {
            text: "Delete",
            func: () => {
              handleDeleteCar();
            },
            className: "subscription-button",
          },
        ]}
      ></Popup2>
      <Popup2
        title={"Subscribe!"}
        description={
          "Do subscribe to further add or manage content for your shop on the iONclassic platform."
        }
        visible={activeSubscriptionVisible}
        buttons={[
          {
            text: "Cancel",
            func: () => setActiveSubscriptionVisible(false),
            className: "cancel-button",
          },
          {
            text: "Ok",
            func: async () => {
              navigate("/managers/manage-subcription");
            },

            className: "primary-button",
          },
        ]}
      ></Popup2>

      {/* Transfer Restoration Popup */}
      <Modal
        className="popup text-center"
        // onOk={handleOk}
        // onCancel={handleCancel}
        open={transPopup}
        footer={null}
        closable={false}
        centered
      >
        <Form
          name="transferForm"
          preserve={true}
          form={form}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          onFieldsChange={handleFormChange}
        >
          <div className="popup-inner"></div>
          <h2 className="popup-title">Transfer restoration</h2>
          <p className="pop-desc">
            Please enter the email to transfer restoration
          </p>
          <div>
            <Form.Item
              label="Profile Image"
              name="email"
              className="text-center"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please enter a valid E-mail.",
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Enter Email"
                autoComplete="off"
              />
            </Form.Item>
          </div>
        </Form>
        <div className="button-container">
          <button
            onClick={() => setTransPopup(false)}
            className="cancel-button"
          >
            Cancel
          </button>
          <button
            disabled={disableTransferBtn}
            onClick={() => handleTransferRestoration()}
            className="primary-button"
          >
            Submit
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default ViewVehicleDetails;
