import React, { useEffect } from 'react'
import HomeCarsList from './HomeCarsList'
import { handleNavigation } from '../../utils/helper';

export default function HomePage() {

 useEffect(() => {
  handleNavigation();
 },[])

  return (
    <div>
        <HomeCarsList />
    </div>
  )
}
