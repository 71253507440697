import { gql, useQuery } from "@apollo/client";
export const GET_CAR_DETAILS = gql`
  query getCarDetails($id: ID!) {
    car(id: $id) {
      id
      year
      make
      model
      vin
      chassis
      exteriorColor
      interiorColor
      driveType
      transmission
      bodyStyle
      engine
      fuel
      mileage
      isPublic
      heroImageUrl
      description
      qrCodeUrl
      comments
      shopOwner
      carImageCategories {
        id
        images(carId: $id) {
          carImageCategoryId
          id
          imageUrl
        }
        name
      }
      carImages {
        carImageCategoryId
        id
        imageUrl
      }
    }
  }
`;
export const GetCarDetails = (id) => {
  const { data, loading, error } = useQuery(GET_CAR_DETAILS, {
    variables: {
      id,
    },
    fetchPolicy: "network-only",
  });

  return {
    CarData: data,
    loading,
    error,
  };
};
