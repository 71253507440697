import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import { Image as Images } from "../Images";
import { useNavigate } from "react-router-dom";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import "../../assets/styles/form.scss";
import "../../assets/styles/auths.scss";
import { Link } from "react-router-dom";
import Popup2 from "../popup/Popup2";
import CardDetailsPopup from "../popup/cardDetailsPopup";
import { useMutation } from "@apollo/client";
import { CANCEL_STRIPE_SUBS } from "../../gqloperations/profile/cancelStripeSubs";
import { gql } from "@apollo/client";

const Form = (props) => {
  const [visible, setVisible] = useState(false);
  const [showCardDetailsPopup, setShowCardDetailsPopup] = useState(false);
  const navigate = useNavigate();

  const [cancelShopStripeSubscription, { data, error, loading }] =
    useMutation(CANCEL_STRIPE_SUBS);


  const MANAGERS_SUBSCRIPTION = gql`
    mutation {
      managerSubscription {
        success
      }
    }
  `;

  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `${token}`,
  };

  const [managersSubscription] = useMutation(MANAGERS_SUBSCRIPTION, {
    context: {
      headers,
    },
  });
  const [subscriptionCanceled, setSubscriptionCanceled] = useState(null);
  useEffect(() => {
    managersSubscription()
      .then((response) => {
        console.log("Subscription success:", response.data);
        setSubscriptionCanceled(
          response.data?.managerSubscription?.success === false
        );
      })
      .catch((error) => {
        console.error("Error:", error);
        setSubscriptionCanceled(true);
      });
  }, []);

  // Render the button only when subscriptionCanceled is not null
  const renderButton = () => {
    if (subscriptionCanceled === null) {
      return "";
    } else if (subscriptionCanceled) {
      return (
        <Button
          className="get-started-btn"
          onClick={() => {
            localStorage.setItem("subscriptionCanceled", false);
            setShowCardDetailsPopup(true);
          }}
        >
          Subscribe
        </Button>
      );
    } else {
      return (
        <Button
          className="cancel-btn"
          onClick={() => {
            localStorage.setItem("subscriptionCanceled", true);
            setVisible(true);
          }}
        >
          Cancel
        </Button>
      );
    }
  };

  const handleCancelSubscription = async () => {
    // Handle cancellation logic here
    try {
      await cancelShopStripeSubscription({
        context: {
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
          },
        },
      });
      setSubscriptionCanceled(subscriptionCanceled);
      localStorage.setItem("subscriptionCanceled", true);
      setVisible(false);
      navigate(`/managers/home`);
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      setVisible(false);
      localStorage.setItem("subscriptionCanceled", false);

      message.error("Something went wrong,unable to unsubscribe!");
      // Handle error here
    }
  };
  

  return (
    <div className="container-fluid auths-main-fluid auths-main-profile "style={{ overflowX: 'hidden' }}>
      <div className="auth-shadow-effect">
        <img
          src="/static/media/auth-shadow-effect.b6a52928baa04fae196daaf7b7143c9f.svg"
          alt=""
          className="img-fluid"
        />
      </div>

      <div className="row mx-0 position-relative">
        <div
          onClick={() => navigate(`/managers/home`)}
          className="slideshow-cancel-btn"
        >
          <img src={Images.previous_arrow} alt="previous btn" />
        </div>
        <div className="col-12 col-sm-12 col-xl-9 col-lg-12 col-md-10 mx-auto auth-left-div">
          <div
            className="auth-logo-section"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Link to={"/"}>
              <img src={Images.auth_logo} alt="" className="img-fluid" />
            </Link>
          </div>

          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mx-auto text-center basic-details-auth">
              <h4>Select Plan</h4>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 mx-auto">
              <div className="row mx-0 plan-card-row">
                <div className="col-12">
                  <h5 className="text-center">$09.95/month</h5>
                  <p className="text-center">Popular plan</p>
                  <ul className="list-inline">
                  <li>
                      <img
                        src={Images.check_icon_circle}
                        alt=""
                        className="img-fluid"
                      />
                      Unlimited cars, mechanics and pictures
                    </li>
                    <li>
                      <img
                        src={Images.check_icon_circle}
                        alt=""
                        className="img-fluid"
                      />
                      Unique QR codes
                    </li>
                    <li>
                      <img
                        src={Images.check_icon_circle}
                        alt=""
                        className="img-fluid"
                      />
                      Digital Showroom
                    </li>
                    <li>
                      <img
                        src={Images.check_icon_circle}
                        alt=""
                        className="img-fluid"
                      />
                      Link social media accounts
                    </li>
                    <li>
                      <img
                        src={Images.check_icon_circle}
                        alt=""
                        className="img-fluid"
                      />
                      Social Media features
                    </li>
                    <li>
                      <img
                        src={Images.check_icon_circle}
                        alt=""
                        className="img-fluid"
                      />
                      eMail transfer for customer data
                    </li>
                  </ul>
                  {renderButton()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Conditionally render CardDetailsPopup */}
      <CardDetailsPopup
        visible={showCardDetailsPopup}
        setVisible={setVisible}
        setSubscriptionCanceled={setSubscriptionCanceled}
        subscriptionCanceled={subscriptionCanceled}
        handleOk={() => {
          setShowCardDetailsPopup((prev) => !prev);
        }}
        handleCancel={() => setShowCardDetailsPopup((prev) => !prev)}
      />
      <Popup2
        title={"Are you sure you want to cancel this subscription ?"}
        description={
          "Canceling your subscription will stop all payments and remove your ability to further add or manage content for your shop on the iONclassic platform."
        }
        visible={visible}
        buttons={[
          {
            text: "Cancel",
            func: () => setVisible(false),
            className: "cancel-button",
          },
          {
            text: "Cancel Subscription",
            func: () => handleCancelSubscription(),
            className: "subscription-button",
          },
        ]}
        
      ></Popup2>
    </div>
  );
};

export default Form;
