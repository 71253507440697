import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import "./popup.scss";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { CloseOutlined } from "@ant-design/icons";

const CreditCardPopup = ({
  visible,
  buttons,
  handleOk,
  handleCancel,
  handleClose,
  formFields,
  setFormFields,
}) => {
  const [open, setOpen] = useState(visible);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    const cardNumberElement = elements?.getElement(CardNumberElement);
    const cvvElement = elements?.getElement(CardCvcElement);
    const expiryElement = elements?.getElement(CardExpiryElement);

    const handleElementChange = (element, field) => {
      if (element?.complete) {
        setFormFields((prevFields) => ({
          ...prevFields,
          [field]: true,
        }));
      } else {
        setFormFields((prevFields) => ({
          ...prevFields,
          [field]: false,
        }));
      }
    };

    cardNumberElement?.on("change", (event) => {
      handleElementChange(event, "cardNumber");
    });

    cvvElement?.on("change", (event) => {
      handleElementChange(event, "cvv");
    });

    expiryElement?.on("change", (event) => {
      handleElementChange(event, "expiry");
    });
  }, [elements]);

  useEffect(() => {
    setOpen(visible);
    if (elements && stripe) {
      const cardNumberElement = elements.getElement(CardNumberElement);
      const cardCvcElement = elements.getElement(CardCvcElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);

      if (cardNumberElement && cardCvcElement && cardExpiryElement) {
        cardNumberElement.clear();
        cardCvcElement.clear();
        cardExpiryElement.clear();
      }
    }
  }, [visible, elements, stripe]);

  const handleBlur = () => {
    console.log("[blur]");
  };

  const handleFocus = () => {
    console.log("[focus]");
  };
  const handleReady = () => {
    console.log("[ready]");
  };
  const handleFieldChange = (field, value) => {
    if (field === "cardholderName") {
      // Check if the input value contains only letters and spaces
      if (/^[A-Za-z\s]+$/.test(value) || value === "") {
        setFormFields((prevFields) => ({
          ...prevFields,
          [field]: value,
        }));
      } else {
        setFormFields((prevFields) => ({
          ...prevFields,
          [field]: "",
        }));
      }
    } else if (value.length > 20) {
      // Prevent updating the state if the length exceeds 20 characters
      return;
    } else {
      setFormFields((prevFields) => ({
        ...prevFields,
        [field]: value,
      }));
    }
  };
  const createOptions = (fontSize, padding) => {
    return {
      style: {
        base: {
          fontSize: "20px",
          color: "white",
          letterSpacing: "0.025em",
          border: "2px solid red",

          "::placeholder": {
            color: "#D4D4D4",
            fontSize: "18px",
          },
          padding,
        },
        invalid: {
          color: "#9e2146",
        },
      },
    };
  };
  
  return (
    <div>
      <Modal
        // className="popup text-center"
        onOk={handleOk}
        onCancel={handleCancel}
        open={open}
        footer={null}
        closable={false}
        centered
      >
         <div className="popup-close-button" onClick={handleClose}>
          <CloseOutlined />
          </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mx-auto text-center basic-details-auth">
          <h4
            style={{ fontSize: "32px", marginBottom: "12px", fontWeight: 800,marginTop: "12px" }}
          >
            Enter Cards Details
          </h4>
          <div className="row">
            <div className="col-12">
              <p
                style={{
                  whiteSpace: "normal",
                  maxWidth: "531px",
                  margin: "auto",
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "32px",
                  color: "#dadada",
                  opacity: 0.7,
                }}
              >
                Enter your Credit Card information - iONclassic uses stripe
                online payment system
              </p>
            </div>
          </div>
        </div>
        <div
          className="common-form"
          style={{ maxWidth: "597px", margin: "0 auto" }}
        >
          <form id="payment-form">
            <div style={{ paddingBottom: "32px" }}>
              <label
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: "normal",
                  paddingBottom: 10,
                  color: "white",
                  marginTop: "17px",
                }}
              >
                Cardholder's Name
              </label>
              <input
                className="input-box"
                type="text"
                src=""
                alt=""
                placeholder="Cardholder's Name"
                value={formFields.cardholderName}
                onChange={(event) =>
                  handleFieldChange("cardholderName", event.target.value)
                }
              />
            </div>

            <div style={{ paddingBottom: "32px" }}>
              <label
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: "normal",
                  paddingBottom: 10,
                  color: "white",
                }}
              >
                Card Number
              </label>

              <CardNumberElement
                className="input-box-popup"
                onBlur={handleBlur}
                // onChange={handleChange}
                onChange={(event) =>
                  handleFieldChange("cardNumber", event.error ? "" : "filled")
                }
                onFocus={handleFocus}
                onReady={handleReady}
                options={{
                  ...createOptions(),
                  placeholder: "XXXX XXXX XXXX XXXX",
                }}
                value={formFields.cardNumber}
              />
            </div>

            <div style={{ display: "flex", gap: "10px" }}>
              <div style={{ paddingBottom: "32px", flex: 1 }}>
                <label
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "normal",
                    paddingBottom: 10,
                    color: "white",
                  }}
                >
                  CVV
                </label>

                <CardCvcElement
                  className="input-box-popup"
                  onBlur={handleBlur}
                  onChange={(event) =>
                    handleFieldChange("cvv", event.error ? "" : "filled")
                  }
                  onFocus={handleFocus}
                  onReady={handleReady}
                  options={{
                    ...createOptions(),
                    placeholder: "XXXX",
                  }}
                  value={formFields.cvv}
                />
              </div>

              <div style={{ paddingBottom: "32px", flex: 1 }}>
                <label
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "normal",
                    paddingBottom: 10,
                    color: "white",
                  }}
                >
                  Expiry Date
                </label>

                <CardExpiryElement
                  className="input-box-popup"
                  onBlur={handleBlur}
                  onChange={(event) =>
                    handleFieldChange("expiry", event.error ? "" : "filled")
                  }
                  onFocus={handleFocus}
                  onReady={handleReady}
                  options={createOptions()}
                  value={formFields.expiry}
                />
              </div>
            </div>
          </form>
          <div className="button-container">
            {buttons.map((button, index) => (
              <button
                key={index}
                onClick={button?.func}
                className={button.className}
                disabled={
                  (button.text === "Add Card" && !formFields.cardholderName) ||
                  !formFields.cardNumber ||
                  !formFields.cvv ||
                  !formFields.expiry
                }
              >
                {button.text}
              </button>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreditCardPopup;
