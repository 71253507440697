import React, { useState, useEffect } from "react";
import { Button, Tooltip } from "antd";
import { gql } from "@apollo/client";
import Popup2 from "../../components/popup/Popup2";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

const CarGrid = (props) => {
  const [activeSubscriptionVisible, setActiveSubscriptionVisible] =
    useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const MANAGERS_SUBSCRIPTION = gql`
    mutation {
      managerSubscription {
        success
      }
    }
  `;

  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `${token}`,
  };

  const [managersSubscription] = useMutation(MANAGERS_SUBSCRIPTION, {
    context: {
      headers,
    },
  });

  useEffect(() => {
    managersSubscription()
      .then((response) => {
        console.log("Subscription success:", response.data);
        setHasActiveSubscription(
          response.data?.managerSubscription?.success === true
        );
      })
      .catch((error) => {
        console.error("Error:", error);
        setHasActiveSubscription(false);
      });
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <div className="row categories-row-card categories-row-grid">
        <div
          className="col-12 category-img-div position-relative"
          style={{ width: "435px" }}
        >
          <img
            onClick={() => {
              if (!hasActiveSubscription) {
                setActiveSubscriptionVisible(true);
              } else {
                navigate(`/managers/home/details?id=${props?.id}`);
              }
            }}
            src={props?.heroImageUrl}
            alt=""
            className="img-fluid"
          />
          <div className="category-QR-div">
            <a href={props?.qrCodeDocUrl} target="_blank">
              <Tooltip placement="top" title="Print QR code">
                <img src={props?.qrCodeUrl} alt="" className="img-fluid" />
              </Tooltip>
            </a>
          </div>
        </div>
        <div className="col-12 category-details-div" style={{ width: "435px" }}>
          <h5>{props?.model}</h5>
          <p>{props?.description}</p>
          <Button
            type="link"
            onClick={() => {
              if (!hasActiveSubscription) {
                setActiveSubscriptionVisible(true);
              } else {
                navigate(`/managers/home/details?id=${props?.id}`);
              }
            }}
            className="bg-transparent border-0 p-0 rounded-0 read-more-btn"
          >
            Read More...
          </Button>
          <div className="row product-details-inner">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 d-flex">
              <ul
                className="list-inline mb-0 d-flex"
                style={{ width: "437px" }}
              >
                <li className="list-inline-item">Make:</li>
                <li className="list-inline-item" style={{ width: "113px" }}>
                  {props?.make}
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-12 col-md-6 d-flex">
              <ul className="list-inline mb-0" style={{ width: "188px" }}>
                <li className="list-inline-item">Owner:</li>
                <li className="list-inline-item">{props?.shopOwner}</li>
              </ul>
            </div>
            <div className="col-12 col-sm-12 col-md-6 d-flex">
              <ul className="list-inline mb-0" style={{ width: "132px" }}>
                <li className="list-inline-item">Year:</li>
                <li className="list-inline-item">{props?.year}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Popup2
        title={"Subscribe!"}
        description={
          "Do subscribe to further add or manage content for your shop on  the iONclassic platform."
        }
        visible={activeSubscriptionVisible}
        buttons={[
          {
            text: "Cancel",
            func: () => setActiveSubscriptionVisible(false),
            className: "cancel-button",
          },
          {
            text: "Ok",
            func: async () => {
              navigate("/managers/manage-subcription");
            },

            className: "primary-button",
          },
        ]}
      ></Popup2>
    </>
  );
};

export default CarGrid;
