import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Image as Images } from "../Images";
import { Button } from "antd";
import "../../assets/styles/footer.scss";

const Footer = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div className="container-fluid footer-main-fluid">
      <div className="container">
        <div className="row footer-row-1">
          <div className="col-3 col-sm-2 col-md-2 col-lg-3 col-xl-3 logo-footer">
            <Link to={" "}>
              <img
                src={Images.logo_ion_footer}
                alt=""
                className="img-fluid footer-logo"
              />
            </Link>
          </div>
          <div className="col-9 col-sm-10 col-md-10 col-lg-9 col-xl-9">
            <ul className="list-inline mb-0 text-end">
              <li className="list-inline-item">
                <NavLink to={"/"} onClick={handleScrollToTop}>
                  Home
                </NavLink>
              </li>
              <li className="list-inline-item">
                <NavLink to={"/faq"} onClick={handleScrollToTop}>
                  FAQ
                </NavLink>
              </li>
              <li className="list-inline-item">
                <NavLink to={"/contact-us"} onClick={handleScrollToTop}>
                  Contact us
                </NavLink>
              </li>

              <li className="list-inline-item">
                <NavLink to={"/end-user-Agreement"} onClick={handleScrollToTop}>
                  End User Agreement
                </NavLink>
              </li>
              <li className="list-inline-item">
                <NavLink to={"/PrivacyPolicy "} onClick={handleScrollToTop}>
                  Privacy Policy
                </NavLink>
              </li>
              <li className="list-inline-item">
                <NavLink to={"/terms&conditions "} onClick={handleScrollToTop}>
                  Terms & Conditions
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="row footer-row-2 text-center text-md-start">
          <div className="col-12 col-sm-6">
            <h5 className="text-uppercase">Download iON Classic App</h5>
          </div>
          <div className="col-12 col-sm-6">
            <ul className="list-inline mb-0 text-md-end">
              <li className="list-inline-item me-4">
                <Button className="bg-transparent p-0 border-0 h-auto">
                  <Link
                    to={
                      "https://apps.apple.com/us/app/ion-classic/id1482774542"
                    }
                    target="_blank"
                  >
                    <img
                      src={Images.app_store_logo}
                      alt={""}
                      className="img-fluid"
                    />
                  </Link>
                </Button>
              </li>
              <li className="list-inline-item">
                <Button className="bg-transparent p-0 border-0 h-auto">
                  <Link
                    to={
                      "https://play.google.com/store/apps/details?id=com.ionclassic&pli=1"
                    }
                    target="_blank"
                  >
                    <img
                      src={Images.google_play_store_logo}
                      alt={""}
                      className="img-fluid"
                    />
                  </Link>
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row mx-0 footer-row-3">
        <div className="col-12">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <p className="mb-0">All Right Reserved 2023 © iONClassic</p>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-end">
                <ul className="d-flex justify-content-center justify-content-lg-end mb-0 pe-2 pe-md-0 text-end text-sm-center text-md-end text-lg-end text-xl-end">
                  <li className="list-inline-item">
                    <Link
                      to={"https://www.youtube.com/@ionclassic"}
                      target="_blank"
                    >
                      YouTube
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to={"https://www.instagram.com/ion_classic/"}
                      target="_blank"
                    >
                      Instagram
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to={"https://www.linkedin.com/company/ion-classic/"}
                      target="_blank"
                    >
                      LinkedIn
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link
                      to={
                        "https://www.facebook.com/people/ION-Classic/100063534910393/"
                      }
                      target="_blank"
                    >
                      Facebook
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
