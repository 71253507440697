import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import "./popup.scss";

const Popup2 = ({
  visible,
  title,
  description,
  buttons,
  handleOk,
  handleCancel,
}) => {
  const [open, setOpen] = useState(visible);
  useEffect(() => {
    setOpen(visible);
  }, [visible]);
  // const handelOk =() =>{
  //     setVisible(false)
  // }
  // const handelCancel =() =>{
  //     setVisible(false)
  // }
  return (
    <div>
      <Modal
        className="popup text-center"
        onOk={handleOk}
        onCancel={handleCancel}
        open={open}
        footer={null}
        closable={false}
        centered
      >
        <div className="popup-inner"></div>
        <h2 className="popup-title">{title}</h2>
        <p className="pop-desc">{description}</p>
        <div className="button-container">
          {buttons.map((button, index) => (
            <button
              key={index}
              onClick={button?.func}
              className={button.className}
            >
              {button.text}
            </button>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default Popup2;
