import React, { useState, useEffect } from "react";
import { Button, Form, Input, Select, message } from "antd";
import { Image as Images } from "../Images";
import { CREATE_SHOP } from "../../gqloperations/profile/create-shop.mutation";
import { isApolloError, useMutation } from "@apollo/client";
import CountryData from "../../utils/country_dial_info.json";
import { createSignUpStatus, preventNavigationBack } from "../../utils/helper";
import { useNavigate, useLocation } from "react-router-dom";

const CreateProfileForm = (props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const step = useLocation().search;
  const searchParams = new URLSearchParams(step);
  const [disabledSave, setDisabledSave] = useState(true);
  const [activeMedia, setActiveMedia] = useState("");
  const [socialLink, setSocialLink] = useState("");
  const [countryCode, setCountryCode] = useState("US+1");
  const [image, setImage] = useState("");
  const [urls, setUrls] = useState({
    facebookUrl: "",
    youtubeUrl: "",
    instagramUrl: "",
    snapchatLink: "",
  });
  const [toggleAddLink, setToggleAddLink] = useState(false);
  const [createShop] = useMutation(CREATE_SHOP);

  useEffect(() => {
    const state = JSON.parse(localStorage.getItem("state"));
    if (state?.state > 0 && state?.state < 3) {
      window.location.replace(`/managers/create-profile?state=${state?.state}`);
    }
  }, []);

  //  to upload base64 image and generate s3 url
  const handleFileUpload = (event) => {
    // clean up earliest files
    let myFiles = {};
    // set state of files to false until each of them is processed
    console.log(event);
    const files = event?.nativeEvent.srcElement?.files;
    const filePromises = Object.entries(files).map((item) => {
      return new Promise((resolve, reject) => {
        const [, file] = item;
        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = function (event) {
          // Convert file to Base64 string
          // btoa is built int javascript function for base64 encoding
          myFiles["picture"] = btoa(event?.target.result);

          resolve();
        };
        reader.onerror = function () {
          console.log("can't read the file");
          reject();
        };
      });
    });

    Promise.all(filePromises)
      .then(() => {
        console.log("ready to submit");
        console.log("file", myFiles);
        if (myFiles?.picture) {
          setImage(myFiles?.picture);
        } else {
          // setImage("");
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("something wrong happened");
      });
  };

  const handleChange = (name, e, type) => {
    const TestRegx = {
      number: new RegExp(`^[0-9]*$`),
      alphaNumaric: new RegExp(`^[a-zA-Z0-9s ]*$`),
      letters: new RegExp(`^[a-zA-Z ]*$`),
    };
    if (TestRegx[type].test(e.target.value)) {
      form.setFieldValue(name, e.target.value.trim());
    } else {
      form.setFieldValue(name, e.target.value.slice(0, -1));
    }
  };

  console.log(props, "props");
  const onFinish = (values) => {
    // props.handleNextStep();.
    values.phoneNumber = countryCode + " " + values.phoneNumber;
    createProfile(values);
    sessionStorage.setItem("profile_info", JSON.stringify(values));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo, urls);
    // props.handleNextStep()
  };

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabledSave(hasErrors);
  };

  const createProfile = (values) => {
    const payload = {
      ...values,
      facebookUrl: urls?.facebookUrl,
      instagramUrl: urls?.instagramUrl,
      youtubeUrl: urls?.youtubeUrl,
      snapchatLink: urls?.snapchatLink,
      profileImageBase64: image !== "" ? `data:image/png;base64,${image}` : "",
      // services: [
      //   {
      //     serviceId: 15,
      //     offered: false,
      //   },
      // ],
    };
    console.log("payload", payload);
    // localStorage.setItem("payload", payload);
    createShop({ variables: payload })
      .then((response) => {
        // Handle success
        message.success("Updated successfully");
        localStorage.setItem("shopId", response?.data?.createShop?.shop?.id);
        const form = new FormData();
        form.append("shop_id", response?.data?.createShop?.shop?.id);
        form.append("state", 1);
        const res = createSignUpStatus(form);
        console.log("res");
        if (res) {
          navigate(`/managers/create-profile?state=${1}`);
        }
      })
      .catch((e) => {
        // Handle error isApolloError
        if (isApolloError(e)) {
          for (const gqlError of e.graphQLErrors) {
            console.log("gq", gqlError);
            message.error(gqlError.message);
          }
        }
      });
  };

  const handleSocialLink = () => {
    if (activeMedia === "facebookUrl") {
      setUrls({
        ...urls,
        facebookUrl: socialLink,
      });
      form.setFieldValue("facebookUrl", socialLink);
    }
    if (activeMedia === "youtubeUrl") {
      setUrls({
        ...urls,
        youtubeUrl: socialLink,
      });
      form.setFieldValue("youtubeUrl", socialLink);
    }
    if (activeMedia === "instagramUrl") {
      setUrls({
        ...urls,
        instagramUrl: socialLink,
      });
      form.setFieldValue("instagramUrl", socialLink);
    }
    if (activeMedia === "snapchatLink") {
      setUrls({
        ...urls,
        snapchatLink: socialLink,
      });
      form.setFieldValue("snapchatLink", socialLink);
    }

    setToggleAddLink(false);
  };
  useEffect(() => {
    preventNavigationBack();
    return () => {
      window.onpopstate = null;
    };
  }, []);
  return (
    <div className="row">
      <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mx-auto text-center basic-details-auth">
        <h4>Create Profile</h4>
        <p>Lets Get started - Create your Shop Profile</p>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-8 mx-auto common-form">
        <Form
          name="basic"
          preserve={true}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          // requiredMark={false}
          initialValues={{
            facebookUrl: "",
            youtubeUrl: "",
            instagramUrl: "",
            snapchatLink: "",
          }}
          // validateTrigger={onFinish}
          onFieldsChange={handleFormChange}
        >
          <div className="row">
            <div className="col-12">
              <Form.Item
                label="Profile Image"
                name="profileImageBase64"
                className="text-center"
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                // onChange={() => setImage(image)}
              >
                <div className="icon-inner-box">
                  <div className="upload-btn-wrapper">
                    {
                      <img
                        className="profile-file-upload-handle"
                        src={
                          image !== ""
                            ? `data:image/png;base64,${image}`
                            : "/assets/icons/profile-icon-white.png"
                        }
                        alt="profile image"
                        width={140}
                        height={140}
                      />
                    }
                    <img
                      className="edit-image-icon"
                      src="/assets/icons/edit-image-icon.svg"
                    />
                    <input
                      className="profile-file-upload"
                      onChange={(e) => handleFileUpload(e)}
                      type="file"
                      name="myfile"
                      accept=".jpg, .jpeg, .png"
                    />
                  </div>
                  <div></div>
                </div>
              </Form.Item>
            </div>
            <div className="col-12">
              <Form.Item
                label="Company Name"
                name="companyName"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please enter your company name!",
                  },
                ]}
              >
                <Input placeholder="Enter Company Name" autoComplete="off" />
              </Form.Item>
            </div>
            <div className="col-12">
              <Form.Item
                label="Owner Name"
                name="ownersName"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input your Owner Name!",
                  },
                ]}
              >
                <Input placeholder="Enter Owner Name" />
              </Form.Item>
            </div>
            <div className="col-12">
              <Form.Item
                label="Address"
                name="address"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input your address!",
                  },
                ]}
              >
                <Input.TextArea
                  className="textArea-address"
                  placeholder="Enter Address"
                  autoComplete="off"
                />
              </Form.Item>
            </div>
            <div className="col-12">
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input your city!",
                  },
                ]}
              >
                <Input placeholder="Enter City Name" autoComplete="off" />
              </Form.Item>
            </div>
            <div className="col-12">
              <Form.Item
                label="State"
                name="state"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input your State!",
                  },
                ]}
              >
                <Input placeholder="Enter State Name" autoComplete="off" />
              </Form.Item>
            </div>
            <div className="col-12 row p-0 align-items-center ant-form-item mb-0">
              <div className="col-12 col-md-3 col-xl-2 ps-4 pe-0 ant-form-item-label ">
                <label style={{ whiteSpace: "pre" }}>
                  <span style={{ color: "#dc4446", fontSize: "14px" }}>*</span>
                  ZIP Code
                </label>
              </div>
              <div className="col-12 col-md-9 col-xl-10 ps-5 pe-0">
                <Form.Item
                  className="zip-card-item"
                  name="zip"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input your ZIP Code!",
                    },
                  ]}
                >
                  <Input placeholder="ZIP" autoComplete="off" />
                </Form.Item>
              </div>
            </div>
            <div className="col-12">
              <Form.Item
                label="Country"
                name="country"
                // onChange={(e) => onKeyPress('country', e)}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input your Country Name!",
                  },
                ]}
              >
                <Input placeholder="Enter Country Name" autoComplete="off" />
              </Form.Item>
            </div>
            <div className="col-12">
              <Form.Item
                label="Phone Number"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input your Phone Number!",
                  },
                  {
                    min: 10,
                    max: 10,
                    message:
                      "Phone number should be minimum and maximum 10 digit",
                  },
                ]}
                mask="... ... ...."
              >
                <Input
                  onChange={(e) => handleChange("phoneNumber", e, "number")}
                  maxLength={10}
                  mask="... ... ...."
                  className="Intl-phone-Input"
                  placeholder="XXX XXX XXXX"
                  addonBefore={
                    <Select
                      showSearch
                      onChange={(val) => setCountryCode(val)}
                      onKeyPress={(e) => {
                        // Allow digits (0-9) and the '+' character, prevent default for other keys
                        if (!/^[0-9+]$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      className="tel-add-on"
                      defaultValue="US +1"
                      filterOption={(input, option) =>
                        input &&
                        option.label &&
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {CountryData?.map((item, index) => {
                        return (
                          <Select.Option
                            key={index}
                            value={item?.code + " " + item?.dial_code}
                            label={item?.dial_code}
                          >
                            <i className="flag">{item?.flag}</i>{" "}
                            {item?.dial_code}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  }
                />
              </Form.Item>
            </div>
            <div className="col-12 ant-form-item mb-0 mt-3">
              <div className="ant-form-item-label">
                <label className="">Connect Social Media</label>
              </div>
              <ul className="social-media-row list-inline">
                <li className="list-inline-item ">
                  <Button
                    onClick={() => {
                      setToggleAddLink(true);
                      setActiveMedia("facebookUrl");
                    }}
                    className={`social-media-btn facebookUrl ${
                      activeMedia === "facebookUrl" && "active"
                    }`}
                  >
                    <img
                      src={Images.facebook_white_icon}
                      alt=""
                      className="img-fluid"
                    />
                  </Button>
                  <p style={{ fontSize: "14px" }}>
                    {" "}
                    {urls?.facebookUrl && "@"}
                    {urls?.facebookUrl}
                  </p>
                </li>
                <li className="list-inline-item instagramUrl">
                  <Button
                    onClick={() => {
                      setToggleAddLink(true);
                      setActiveMedia("instagramUrl");
                    }}
                    className={`social-media-btn instagramUrl ${
                      activeMedia === "instagramUrl" && "active"
                    }`}
                  >
                    <img
                      src={Images.instagram_white_icon}
                      alt=""
                      className="img-fluid"
                    />
                  </Button>
                  <p style={{ fontSize: "14px" }}>
                    {urls?.instagramUrl && "@"}
                    {urls?.instagramUrl}
                  </p>
                </li>
                <li className="list-inline-item ">
                  <Button
                    onClick={() => {
                      setToggleAddLink(true);
                      setActiveMedia("youtubeUrl");
                    }}
                    className={`social-media-btn youtubeUrl ${
                      activeMedia === "youtubeUrl" && "active"
                    }`}
                  >
                    <img
                      src={Images.youtube_white_icon}
                      alt=""
                      className="img-fluid"
                    />
                  </Button>
                  <p style={{ fontSize: "14px" }}>
                    {urls?.youtubeUrl && "@"}
                    {urls?.youtubeUrl}
                  </p>
                </li>
                <li className="list-inline-item ">
                  <Button
                    onClick={() => {
                      setToggleAddLink(true);
                      setActiveMedia("snapchatLink");
                    }}
                    className={`social-media-btn snapchatLink ${
                      activeMedia === "snapchatLink" && "active"
                    }`}
                  >
                    <img
                      src={Images.snapchat_white_icon}
                      alt=""
                      className="img-fluid"
                    />
                  </Button>
                  <p style={{ fontSize: "14px" }}>
                    {urls?.snapchatLink && "@"}
                    {urls?.snapchatLink}
                  </p>
                </li>
              </ul>
              {toggleAddLink && (
                <Form.Item name={activeMedia}>
                  <Input
                    onChange={(e) => {
                      setSocialLink(e.target.value);
                      setToggleAddLink(true);
                    }}
                    className="inside-input mb-2"
                    prefix={
                      <>
                        {activeMedia === "facebookUrl" && "facebook.com"}
                        {activeMedia === "youtubeUrl" && "youtube.com"}
                        {activeMedia === "instagramUrl" && "instagram.com"}
                        {activeMedia === "snapchatLink" && "snapchat.com"}
                      </>
                    }
                    suffix={
                      toggleAddLink && (
                        <img
                          onClick={() => handleSocialLink()}
                          src={Images.theme_secondary_check_icon}
                          alt={""}
                          className="img-fluid"
                        />
                      )
                    }
                  />
                </Form.Item>
              )}
            </div>

            <div className="col-12">
              <Button
                className="theme-common-btn w-100"
                type="primary"
                htmlType="submit"
                disabled={disabledSave}
              >
                Create Profile
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateProfileForm;
