import React, { useState } from "react";
import { Button, Checkbox, Form, Input, message } from "antd";
import { Image as Images } from "../../Images";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { SIGNUP_USER } from "../../../gqloperations/mutations";

const SignUpForm = () => {
  const navigate =  useNavigate()
  const [signupUser, { loading, error, data }] = useMutation(SIGNUP_USER);
  console.log(data, "data");
  console.log(error, error)
  const [disabledSave, setDisabledSave] = useState(true);

  const [form] = Form.useForm();
 
  const handleFormChange = () => {
   const hasErrors = !form.isFieldsTouched(true) ||
   form.getFieldsError().filter(({ errors }) => errors.length)
     .length > 0;
   setDisabledSave(hasErrors);
 }
 
  const onFinish = (values) => {
   console.log('Success:', values);
   localStorage.setItem("values",values.email)
  onSubmit(values)
 };
 const onFinishFailed = (errorInfo) => {
   console.log('Failed:', errorInfo);
 };


  const onSubmit = (values) => {
    const newValues = {
      ...values,
      managerType: "owner",
    };
   
    signupUser({ variables: newValues })
    .then((response) => {
      // Handle success
        message.success("Signed up successfully");
        localStorage.setItem("token", response?.data?.createManager?.token)
        localStorage.setItem("shopId", null);
        localStorage.setItem("state", null);
        window.location.replace("/managers/create-profile?step=0");
    })
    .catch((error) => {
      // Handle error
      console.log("Error creating post:", JSON.parse(error?.graphQLErrors[0]?.message)[0]);
      message.error(JSON.parse(error?.graphQLErrors[0]?.message)[0]);
    });
    
  };
  return (
    <div className="auth-form-container mx-auto h-100 position-relative">
      <div className="mt-3 mb-5 pb-5 auth-logo-section">
        <Link to={"/"}>
          <img src={Images.auth_logo} alt="" className="img-fluid" />
        </Link>
      </div>
      <div className="common-form">
      <div className="mb-5 basic-details-auth">
            <h4 className="" >Sign Up</h4>
            <p>
              Sign up for iONclassic and start making documentation easy, fun,
              and engaging for your shop and your customers!
            </p>
      </div>
        <Form
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete={false}
          layout="vertical"
          onFieldsChange={handleFormChange}
          form={form}
          requiredMark={false}
        >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type:"email", message:"Please enter valid email address"
                  },
                  { required: true, message: "Please input your email!" },
                ]}
              >
                <Input placeholder="Enter Email" autoComplete="off" />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                  {min:6, message:"Password must be of atlest 6 characters"},
                ]}
              >
                <Input.Password placeholder="Enter Password" autoComplete="off" />
              </Form.Item>
              <Form.Item name="agreement"
        valuePropName="checked"
        rules={[
          {
            required: true
          },
          {
            validator: (_, value) =>
              value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
          },
        ]} >
                <Checkbox className="fw-light">
                  Agree with <a href="/terms&conditions" target="_blank">Terms & Conditions.</a>
                </Checkbox>
              </Form.Item>
              <Button
                className="theme-common-btn w-100"
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={disabledSave}
              >
                Sign Up
              </Button>
        </Form>
      </div>
      <div className="auth-footer-details">
        <p className="fw-normal mb-0">
          Already have an account: <Link to="/managers/sign-in">Sign In</Link>
        </p>
      </div>
    </div>
  );
};

export default SignUpForm;
