import React from "react";
import { Button } from "antd";
import { Image as Images } from "../Images";
import { Link } from "react-router-dom";
import "../../assets/styles/banner.scss"
const BannerRightContent = () => {
  return (
    <>
      <div className="right-side-content-div">
        <h1 className="d-none d-lg-block">
          <p className="mb-0">
            The <span>Best Way</span> to
          </p>
          <p className="mb-0">Document your </p>
          <p className="mb-0">
            <span>Restoration,</span>{" "}
          </p>
          <p className="mb-0">
            <span>Customs & Builds!</span>
          </p>
        </h1>
        <h1 className="text-center d-block d-lg-none">
          The <span>Best Way</span> to Document your <span> Restoration,</span>{" "}
          <span>Customs & Builds!</span>
        </h1>
        <div className="container w-75">
          <ul className="app-link list-inline text-center text-lg-end">
            <li className="me-4 list-inline-item">
              <Button className="h-100 bg-transparent border-0 p-0 shadow-none">
                <Link to={"https://apps.apple.com/us/app/ion-classic/id1482774542"} target="_blank"  >
                <img src={Images.app_store_logo} alt="" className="img-fluid" />
                </Link>
              </Button>
            </li>
            <li className="list-inline-item">
              <Button className="h-100 bg-transparent border-0 p-0 shadow-none">
                <Link to={"https://play.google.com/store/apps/details?id=com.ionclassic&pli=1"} target="_blank">
                <img
                  src={Images.google_play_store_logo}
                  alt=""
                  className="img-fluid"
                />
                </Link>
              </Button>
            </li>
          </ul>
          <div className="social-media-link d-flex flex-row-reverse">
            <Link className="facebook"
              to={
                "https://www.facebook.com/people/ION-Classic/100063534910393/"
              }
              target="_blank"
            >
              Facebook
            </Link>
            <Link className="linkedin"
              to={"https://www.linkedin.com/company/ion-classic/"}
              target="_blank"
            >
              Linkedin
            </Link>
            <Link className="instagram" to={"https://www.instagram.com/ion_classic/"} target="_blank">
              Instagram
            </Link>
            <Link className="youtube" to={"https://www.youtube.com/@ionclassic"} target="_blank">
              YouTube
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default BannerRightContent;
