import { useEffect } from "react";
import { Navigate } from "react-router-dom";

export default function PublicPath({ children }) {
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      <Navigate to="/managers/home" replace />;
    }
  }, [token]);
  return !token ? children : <Navigate to="/managers/home" replace />;
}
