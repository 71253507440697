import { LOGIN_SUCCESS, LOGOUT, SET_USER, SET_USER_MODE } from "./auth.type";

const INITIAL_STATE = {
  userMode: "",
  user: {
    userId: "",
    email: "",
    firstName: "",
    lastName: "",
    createdAt: "",
    updatedAt: "",
    accessToken: "",
    userType: "",
    screen: "",
  },
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginResponse: action.payload,
      };
    case SET_USER_MODE:
      return {
        ...state,
        userMode: action.payload,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default authReducer;
