import { gql } from "@apollo/client";

export const CREATE_SHOP = gql`
  mutation createShopMutation(
    $companyName: String!
    $address: String!
    $city: String!
    $state: String!
    $zip: String!
    $country: String!
    $ownersName: String!
    $licenseNumber: String
    $insuranceNumber: String
    $phoneNumber: String!
    $profileImageBase64: String
    $facebookUrl: String
    $instagramUrl: String
    $youtubeUrl: String
    $snapchatLink: String
  ) {
    createShop(
      input: {
        companyName: $companyName
        address: $address
        city: $city
        state: $state
        zip: $zip
        country: $country
        ownersName: $ownersName
        licenseNumber: $licenseNumber
        insuranceNumber: $insuranceNumber
        phoneNumber: $phoneNumber
        profileImageBase64: $profileImageBase64
        facebookUrl: $facebookUrl
        instagramUrl: $instagramUrl
        youtubeUrl: $youtubeUrl
        snapchatLink: $snapchatLink
      }
    ) {
      shop {
        id
      }
    }
  }
`;
