import { gql } from "@apollo/client";
export const SEND_INVITE_MECHANICS = gql`
  mutation sendInvitation(
    $phoneNumber: String!
    $firstName: String!
    $lastName: String!
  ) {
    createInvitation(
      input: {
        firstName: $firstName
        lastName: $lastName
        phoneNumber: $phoneNumber
      }
    ) {
      clientMutationId
    }
  }
`;
