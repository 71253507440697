import React, { useEffect, useState } from "react";
import { Input, Form, Select, Button, Spin, message, Result } from "antd";
import Editlogo from "../../assets/images/icons/Edit.png";
import { GetShopInfo } from "../../gqloperations/queries";
import { isApolloError, useMutation } from "@apollo/client";
import { UPDATE_SHOP_INFO } from "../../gqloperations/edit-details/edit-details.mutation";
import AfterLoginHeader from "../common/AfterLoginHeader";
import CountryData from "../../utils/country_dial_info.json";
import "./editdetails.scss";

const EditDetails = () => {
  const shopId = localStorage.getItem("shopId");
  const { shopData, shopLoading, shopError } = GetShopInfo(shopId);
  const [disabledSave, setDisabledSave] = useState(true);
  const [updateShop, { loading }] = useMutation(UPDATE_SHOP_INFO);
  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      lg: {
        span: 10,
      },
      sm: {
        span: 24,
      },
    },
    wrapperCol: {
      lg: {
        span: 14,
      },
      sm: {
        span: 24,
      },
    },
  };
  const [isEditing, setIsEditing] = useState(true);
  const [image, setImage] = useState("");
  const [countryCode, setCountryCode] = useState();

  useEffect(() => {
    console.log("use");
    form.setFields([
      { name: "ownersName", value: shopData?.shop?.ownersName, touched: true },
      { name: "address", value: shopData?.shop?.address, touched: true },
      { name: "city", value: shopData?.shop?.city, touched: true },
      { name: "state", value: shopData?.shop?.state, touched: true },
      { name: "zip", value: shopData?.shop?.zip, touched: true },
      { name: "country", value: shopData?.shop?.country, touched: true },
      {
        name: "phoneNumber",
        value: shopData?.shop?.phoneNumber?.split(" ")[1],
        touched: true,
      },
      {
        name: "facebookUrl",
        value: shopData?.shop?.facebookUrl,
        touched: true,
      },
      {
        name: "instagramUrl",
        value: shopData?.shop?.instagramUrl,
        touched: true,
      },
      { name: "youtubeUrl", value: shopData?.shop?.youtubeUrl, touched: true },
      {
        name: "snapchatLink",
        value: shopData?.shop?.snapchatLink,
        touched: true,
      },
    ]);
    setCountryCode(shopData?.shop?.phoneNumber?.split(" ")[0]);
    console.log("ppp", shopData?.shop?.phoneNumber);
  }, [shopData]);

  const onFinish = (values) => {
    console.log("val", values);
    const phoneNum = countryCode + " " + values.phoneNumber;
    values.phoneNumber = phoneNum;
    const payload = {
      ...values,
      id: shopData?.shop?.id,
      profileImageBase64:
        image !== ""
          ? `data:image/png;base64,${image}`
          : shopData?.shop?.profileImageUrl,
    };
    console.log("val", payload);
    updateShop({ variables: payload })
      .then((response) => {
        message.success("Updated successfully");
        setIsEditing(!isEditing);
        console.log("Post created:", response?.data?.updateShop?.shop);
      })
      .catch((e) => {
        if (isApolloError(e)) {
          for (const gqlError of e.graphQLErrors) {
            console.log("gq", gqlError);
            message.error(gqlError.message);
          }
        }
        console.error("Error creating post:", e);
      });
  };
  const onFinishFailed = (val) => {
    console.log(val);
  };

  const dataObj = {
    ownersName: {
      name: "Owner Name",
      placeholder: "Owner Name",
      value: shopData?.shop?.ownersName,
      require: true,
    },
    address: {
      name: "Address",
      placeholder: "Address",
      value: shopData?.shop?.address,
      require: true,
    },
    city: {
      name: "City",
      placeholder: "City",
      value: shopData?.shop?.city,
      require: true,
    },
    state: {
      name: "State",
      placeholder: "State",
      value: shopData?.shop?.state,
      require: true,
    },
    zip: {
      name: "Zip",
      placeholder: "Zip",
      value: shopData?.shop?.zip,
      require: true,
    },
    country: {
      name: "Country",
      placeholder: "Country",
      value: shopData?.shop?.country,
      require: true,
    },
    phoneNumber: {
      name: "Phone Number",
      placeholder: "Phone Number",
      value: shopData?.shop?.phoneNumber,
      require: true,
    },
    facebookUrl: {
      name: "Facebook",
      placeholder: "Enter facebook profile",
      value: shopData?.shop?.facebookUrl,
      require: false,
    },
    instagramUrl: {
      name: "Instagram",
      placeholder: "Enter instagram profile",
      value: shopData?.shop?.instagramUrl,
      require: false,
    },
    youtubeUrl: {
      name: "Youtube",
      placeholder: "Enter youtube profile",
      value: shopData?.shop?.youtubeUrl,
      require: false,
    },
    snapchatLink: {
      name: "Snapchat Link",
      placeholder: "Enter snapchat profile",
      value: shopData?.shop?.snapchatLink,
      require: false,
    },
  };

  const handleEditClick = (e) => {
    e?.preventDefault();
    setIsEditing(!isEditing);
  };

  const handleFileUpload = (event) => {
    // clean up earliest files
    let myFiles = {};
    // set state of files to false until each of them is processed
    console.log(event);
    const files = event?.nativeEvent.srcElement?.files;
    const filePromises = Object.entries(files).map((item) => {
      return new Promise((resolve, reject) => {
        const [, file] = item;
        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = function (event) {
          // Convert file to Base64 string
          // btoa is built int javascript function for base64 encoding
          myFiles["picture"] = btoa(event?.target.result);

          resolve();
        };
        reader.onerror = function () {
          console.log("can't read the file");
          reject();
        };
      });
    });

    Promise.all(filePromises)
      .then(() => {
        console.log("ready to submit");
        console.log("file", myFiles);
        if (myFiles?.picture) {
          handleEditClick();
          setImage(myFiles?.picture);
          setDisabledSave(false);
        } else {
          // setImage("");
        }
      })
      .catch((error) => {
        console.log(error);
        console.log("something wrong happened");
      });
  };

  const handleFormChange = () => {
    const hasErrors =
      // !form.isFieldsTouched(true) ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabledSave(hasErrors);
  };

  const handleChange = (name, e, type) => {
    const TestRegx = {
      number: new RegExp(`^[0-9]*$`),
      alphaNumaric: new RegExp(`^[a-zA-Z0-9s ]*$`),
      letters: new RegExp(`^[a-zA-Z ]*$`),
    };
    if (TestRegx[type].test(e.target.value)) {
      console.log("true");
      form.setFieldValue(name, e.target.value.trim());
    } else {
      console.log("false");
      form.setFieldValue(name, e.target.value.slice(0, -1));
    }
  };

  if (shopId === null)
    return (
      <>
        <AfterLoginHeader />
        <Result
          status="error"
          title={<h1>Profile Not Created</h1>}
          subTitle={<p className="fs-5 fw-light">Please create the profile.</p>}
          extra={[
            <Button
              onClick={() =>
                window.location.replace("/managers/create-profile")
              }
              type="primary"
              key="console"
            >
              Create Shop
            </Button>,
            <Button
              onClick={() => window.location.replace("/managers/home")}
              key="buy"
            >
              Home
            </Button>,
          ]}
        ></Result>
      </>
    );
  if (shopLoading) return <div>loading...</div>;
  if (shopError) return <p>something went wrong....</p>;
  return (
    <div className="editDetails_container">
      {/* {console.log(shopData)} */}
      <AfterLoginHeader />
      <Spin spinning={loading}>
        <div className="icon-container">
          <div className="icon-inner-box">
            <div className="upload-btn-wrapper">
              {
                <img
                  className="profile-file-upload-handle"
                  src={
                    image !== ""
                      ? `data:image/png;base64,${image}`
                      : shopData?.shop?.profileImageUrl !== ("" || null)
                      ? shopData?.shop?.profileImageUrl
                      : "/assets/icons/profile-icon-white.png"
                  }
                  alt="profile image"
                  width={140}
                  height={140}
                />
              }
              <img
                className="edit-image-icon"
                src="/assets/icons/edit-image-icon.svg"
              />
              <input
                className="profile-file-upload"
                onChange={(e) => handleFileUpload(e)}
                type="file"
                name="myfile"
                accept=".jpg, .jpeg, .png"
              />
            </div>
            <div></div>
            <div className="logo-title mb-5">{shopData?.shop?.companyName}</div>
          </div>
          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onFieldsChange={handleFormChange}
            className="profile-form"
            requiredMark={false}
            initialValues={{
              ownersName: dataObj?.ownersName.value,
              address: dataObj?.address.value,
              city: dataObj?.city.value,
              state: dataObj?.state.value,
              zip: dataObj?.zip.value,
              country: dataObj?.country.value,
              phoneNumber: dataObj?.phoneNumber.value,
              facebookUrl: dataObj?.facebookUrl?.value,
              instagramUrl: dataObj?.instagramUrl?.value,
              youtubeUrl: dataObj?.youtubeUrl?.value,
              snapchatLink: dataObj?.snapchatLink?.value,
            }}
            style={{
              maxWidth: 550,
              width: "100%",
              padding: "0 15px",
            }}
          >
            {Object.entries(dataObj).map(([key, val], index) => (
              <Form.Item
                key={index}
                name={key}
                className="edit-details-form-item"
                label={<p className="edit-detail-label">{val?.name}</p>}
                rules={
                  val?.name === "Phone Number"
                    ? [
                        {
                          required: true,
                          message: "Please input Phone Number!",
                        },
                        {
                          max: 13,
                          min: 10,
                          message:
                            "Phone number should be minimum 10 digit excluding country code",
                        },
                        {
                          validator: (_, value) => {
                            let regex = new RegExp(`^[0-9]*$`);
                            return regex.test(value)
                              ? Promise.resolve()
                              : Promise.reject(
                                  "Please enter phone number in digit only"
                                );
                          },
                        },
                      ]
                    : [
                        {
                          required: val.require,
                        },
                      ]
                }
              >
                {key === "address" ? (
                  <Input.TextArea
                    showCount={false}
                    className="right-value"
                    // style={{
                    //   background: "none",
                    //   border: "none",
                    // }}
                    autoSize={{
                      minRows: 1,
                      maxRows: 5,
                    }}
                    bordered={false}
                    disabled={isEditing}
                    placeholder={val.placeholder}
                  />
                ) : key === "phoneNumber" ? (
                  <Input
                    disabled={isEditing}
                    maxLength={10}
                    mask="... ... ...."
                    className="Intl-phone-Input right-value"
                    placeholder="XXX XXX XXXX"
                    onChange={(e) => handleChange("phoneNumber", e, "number")}
                    addonBefore={
                      <Select
                        showSearch
                        onChange={(val) => setCountryCode(val)}
                        className="tel-add-on"
                        defaultValue="US +1"
                        filterOption={(input, option) =>
                          input &&
                          option.label &&
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onKeyPress={(e) => {
                          // Allow digits (0-9) and the '+' character, prevent default for other keys
                          if (!/^[0-9+]$/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      >
                        {CountryData?.map((item, index) => {
                          return (
                            <Select.Option
                              key={index}
                              value={item?.code + " " + item?.dial_code}
                              label={item?.dial_code}
                            >
                              <i className="flag">{item?.flag}</i>{" "}
                              {item?.dial_code}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    }
                  />
                ) : (
                  <Input
                    className="right-value"
                    placeholder={val.placeholder}
                    bordered={false}
                    disabled={isEditing}
                  />
                )}
              </Form.Item>
            ))}
            <Form.Item className="align-start" labelCol={0} wrapperCol={24}>
              {!isEditing ? (
                <Button
                  disabled={disabledSave}
                  className="edit-button mt-3"
                  htmlType="submit"
                >
                  <span>
                    <img src="/assets/icons/Tick.svg" alt="edit" />
                  </span>
                  <span>Update Details</span>
                </Button>
              ) : (
                <button
                  className="edit-button mt-3"
                  onClick={(e) => handleEditClick(e)}
                  htmlType="button"
                >
                  <span>
                    <img src={Editlogo} alt="edit" />{" "}
                  </span>
                  <span>Edit Details</span>
                </button>
              )}
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default EditDetails;
