import React, { useState, useEffect } from "react";
import "./cardDetails.scss";
import {
  useElements,
  useStripe,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import PaymentCard from "../common/Payment-Card/paymentCard";
import Popup2 from "../../components/popup/Popup2";
import { useNavigate, useLocation } from "react-router-dom";
import carIcon from "../../assets/images/icons/carIcon.png";
import { Image as Images } from "../../components/Images";
import { DeleteFilled, CheckCircleFilled } from "@ant-design/icons";
import "react-credit-cards/es/styles-compiled.css";
import { message } from "antd";
import { gql } from "@apollo/client";
import { useMutation, useQuery } from "@apollo/client";
import CreditCardPopup from "../../../../ion_classic_reactweb/src/components/popup/creditCardPopup";

const loadCardDataFromLocalStorage = () => {
  const cardData = localStorage.getItem("cardData");
  if (cardData) {
    return JSON.parse(cardData);
  }
  return [];
};
const GET_CARDS = gql`
  mutation GetCard($input: GetCardInput!) {
    getCardFromShop(input: $input) {
      clientMutationId
      cards {
        number
        expYear
        expMonth
        brand
        id
        name
        default
      }
    }
  }
`;

const UPDATE_DEFAULT_CARD = gql`
  mutation UpdateDefaultCard($input: UpdateDefaultCardInput!) {
    updateDefaultCard(input: $input) {
      clientMutationId
      success
    }
  }
`;

const CardDetails = () => {
  const elements = useElements();
  const stripe = useStripe();
  const location = useLocation();
  const navigate = useNavigate();
  const { cardDetails } = location.state || {};

  const cardHeading = "Card Details";
  const [formFields, setFormFields] = useState({
    cardholderName: "",
    cardNumber: false,
    cvv: false,
    expiry: false,
  });

  const cardDescription = "Add or change or manage your Credit Card";
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [changeDefault, setChangeDefault] = useState(false);
  const shopId = localStorage.getItem("shopId");
  const [visible, setVisible] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [cardToDeleteId, setCardToDeleteId] = useState(null);
  const [cardToSelectId, setCardToSelectId] = useState(null);
  const buttonText = isButtonClicked ? "Add Card" : "Add Card";
  const [addedCards, setAddedCards] = useState(
    loadCardDataFromLocalStorage().map((card, index) => ({
      ...card,
      isSelected: card.default === "true",
    }))
  );

  const [getCardData] = useMutation(GET_CARDS, {
    variables: {
      input: {
        shopId: shopId,
      },
    },
    context: {
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    },
  });

  const [updateDefaultCard] = useMutation(UPDATE_DEFAULT_CARD, {
    context: {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    },
  });

  const fetchCardData = async () => {
    try {
      const response = await getCardData();
      const cardsFromResponse = response.data?.getCardFromShop?.cards || [];
      const defaultCard = cardsFromResponse.find(
        (card) => card.default === "true"
      );
      const updatedCards = cardsFromResponse.map((card, index) => ({
        ...card,
        isSelected: card === defaultCard,
      }));

      setAddedCards(updatedCards);

      saveCardDataToLocalStorage(updatedCards);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchCardData();
  }, [location]);

  const handleSelect = async (index) => {
    if (!cardToSelectId) {
      return;
    }

    try {
      const response = await updateDefaultCard({
        variables: {
          input: {
            shopId: shopId,
            cardId: cardToSelectId,
          },
        },
      });

      if (response.data?.updateDefaultCard.success === true) {
        console.log("Default card updated successfully");
        setChangeDefault(false);

        // Mark the selected card as "selected"
        const updatedCards = addedCards.map((card, cardIndex) => ({
          ...card,
          isSelected: index === cardToSelectId,
        }));
        setAddedCards(updatedCards);
        fetchCardData();
      } else {
        console.error("Failed to update default card");
      }
    } catch (error) {
      console.error("API request failed:", error);
    }
  };

  const handleDelete = async () => {
    if (!cardToDeleteId) {
      return;
    }

    try {
      const headers = {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      };

      const requestData = {
        card_id: parseInt(cardToDeleteId, 10),
      };

      console.log("Deleting card with ID:", cardToDeleteId); // Debugging

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}v1/stripe/delete_card`,
        JSON.stringify(requestData),
        { headers }
      );

      if (response.status === 200) {
        // Filter out the deleted card by comparing the card IDs
        const updatedCards = addedCards.filter(
          (card) => card.id !== cardToDeleteId
        );

        setAddedCards(updatedCards);

        saveCardDataToLocalStorage(updatedCards);

        setVisibleDelete(false);
        message.success("Card deleted successfully");
        fetchCardData();
      } else {
        message.error("Failed to delete card");
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error("An error occurred while processing your request.");
    }
  };

  const saveCardDataToLocalStorage = (cards) => {
    localStorage.setItem("cardData", JSON.stringify(cards));
  };

  const handleSubmit = async () => {
    try {
      const headers = {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      };
      if (isButtonClicked || !stripe || !elements) {
        setVisible(false);
        console.log("Button is already clicked, not submitting again.");
        return;
      }

      setIsButtonClicked(true);

      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/stripe/create_card`,
        {
          payment_id: payload.paymentMethod?.id,
          shop_id: parseInt(shopId, 10),
          card_holder_name: formFields?.cardholderName,
        },
        { headers }
      );

      if (response.status === 200) {
        console.log("Card creation successful");
        setVisible(false);
        fetchCardData();

        setFormFields({
          cardholderName: "",
          expiry: "",
          cvv: "",
          cardNumber: "",
        });
      } else {
        message.error("Failed to create card");
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error("An error occurred while processing your request.");
    } finally {
      setIsButtonClicked(false);
    }
  };
  const handleClose = () => {
    setVisible(false);
    setFormFields((prevFields) => ({
      ...prevFields,
      cardholderName: "",
    }));
  };
  return (
    // FIRST COMPONENT
    <>
      <div>
        <div className="icon-main-container">
          <div className="car-icon-container">
            <div
              onClick={() => navigate(`/managers/home`)}
              className="slideshow-cancel-btn"
            >
              <img src={Images.previous_arrow} alt="previous btn" />
            </div>
            <div className="centered-car-icon">
              <img src={carIcon} alt="car icon" width={121} height={58} />
            </div>
          </div>
          <div className="inner-box">
            <div className="card-desc">
              <div className="card-heading">{cardHeading}</div>
              <div className="card-description">{cardDescription}</div>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {addedCards.map((card, index) => (
                <div sx={{ flex: 1 }}>
                  <div className="inner-box">
                    <div className="active-container">
                      <div
                        className="check-icon"
                        onClick={() => {
                          setCardToSelectId(card.id);
                          setChangeDefault(true);
                          console.log(card.id);
                        }}
                      >
                        {card?.isSelected ? (
                          <CheckCircleFilled
                            style={{ color: "#AFFF06", fontSize: "21px" }}
                          />
                        ) : (
                          <span className="circle-placeholder"></span>
                        )}
                      </div>

                      <div className="card-container">
                        {card?.isSelected ? null : (
                          <div
                            className="delete-icon"
                            data-index={index}
                            onClick={() => {
                              setCardToDeleteId(card.id);
                              setVisibleDelete(true);
                              console.log(card.id);
                            }}
                          >
                            <DeleteFilled
                              style={{ fontSize: "21px" }}
                              className="delete-filled-icon"
                            />
                          </div>
                        )}

                        <PaymentCard
                          cardNumber={`XXXX XXXX XXXX ${card.number}`}
                          holderName={card?.name || "Holder's Name"}
                          brand={card.brand}
                          expiration={`${String(card.expMonth).padStart(
                            2,
                            "0"
                          )}/${String(card.expYear)
                            .slice(-2)
                            .padStart(2, "0")}`}
                          // cvc={formFields.cvv}
                        />
                      </div>
                    </div>
                  </div>
                  <Popup2
                    title={"Are you sure you want to delete the card?"}
                    description={""}
                    visible={visibleDelete}
                    buttons={[
                      {
                        text: "Cancel",
                        func: () => setVisibleDelete(false),
                        className: "cancel-button",
                      },
                      {
                        text: "Delete",
                        func: () => handleDelete(index),
                        className: "subscription-button",
                      },
                    ]}
                  ></Popup2>
                  <Popup2
                    title={"Are you sure you want change default payment card?"}
                    description={""}
                    visible={changeDefault}
                    buttons={[
                      {
                        text: "Cancel",
                        func: () => setChangeDefault(false),
                        className: "cancel-button",
                      },
                      {
                        text: "Change",
                        func: () => handleSelect(index),
                        className: "primary-button",
                      },
                    ]}
                  ></Popup2>
                </div>
              ))}

              <div
                onClick={() => {
                  setVisible(true);
                }}
                className="add-cred-card-button"
              >
                +
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreditCardPopup
        visible={visible}
        formFields={formFields}
        handleClose={handleClose}
        setFormFields={setFormFields}
        buttons={[
          {
            text: buttonText,
            func: (e) => handleSubmit(e),
            className: "primary-button-card",
            disabled: isButtonClicked,
          },
        ]}
      />
    </>
  );
};

export default CardDetails;
