import React, { useEffect, useState } from "react";
import { Button, Spin, message } from "antd";
import { Image as Images } from "./../Images";
import { GetCarImageCategoryList } from "../../gqloperations/cars/get-car-image-category-query";
import { imageToBase64 } from "../../utils/helper";
import { useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { ADD_CAR_IMAGE } from "../../gqloperations/cars/add-Car-Image-mutation";
import { DELETE_CAR_IMAGE } from "../../gqloperations/cars/delete-car-image-mutation";
import { DELETE_ALL_CAR_IMAGE } from "../../gqloperations/cars/delete-all-car-image-mutation";
import { useDispatch } from "react-redux";
import { setSuccessAlert } from "../../redux/successModal/successModal.action";

const UploadImage = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [categoryList, setCategoryList] = useState([]);
  const [doneCategory, setDoneCategory] = useState([]);
  const [activeCategory, setActiveCategory] = useState("");
  const [uploadedImage, setUploadedImage] = useState([]);
  const [dummyState, setDummyState] = useState(true);
  const [loading, setLoading] = useState(false);
  const { data } = GetCarImageCategoryList();

  const [addImageFromShop] = useMutation(ADD_CAR_IMAGE);
  const [deleteImageFromShop] = useMutation(DELETE_CAR_IMAGE);
  const [deleteAllImageFromCategory] = useMutation(DELETE_ALL_CAR_IMAGE);

  const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: [],
      };
    }, initialValue);
  };

  useEffect(() => {
    let tempArr = [];
    setCategoryList(data?.carImageCategories);
    if (data?.carImageCategories) {
      tempArr = convertArrayToObject(data?.carImageCategories, "id");
      setUploadedImage(tempArr);
    }
  }, [data]);

  const handleFileUpload = async (event) => {
    const allowedExtensions = ["jpg", "jpeg", "png", "svg"];
    let files = [];
    if (!event.target.files) {
      return;
    }
    const selectedFiles = Array.from(event.target.files);
    const selectedFilesPreview = selectedFiles.map((fileItem) => {
      const type = fileItem.type.split("/")[0];
      const extension = fileItem.type.split("/")[1];
      //const extension = filesItem.name.split('.').pop();

      const ensureAllowExtensions = allowedExtensions.find(
        (allowedExtensionsItem) => allowedExtensionsItem === extension
      );

      return new Promise((resolve, reject) => {
        if (!ensureAllowExtensions) {
          reject(`file type not allowed: ${allowedExtensions.join(", ")}`);
        }

        if (type === "image") {
          const image = new Image();
          image.onload = () => {
            fileToBase64();
          };
          image.onerror = () => {
            reject("Image Erro");
            console.log("Error");
          };
          image.src = window.URL.createObjectURL(fileItem);
        } else {
          fileToBase64();
        }

        function fileToBase64() {
          const reader = new FileReader();
          reader.readAsDataURL(fileItem);

          reader.onloadend = function () {
            const base64data = reader.result;
            let result = base64data;
            resolve(result);
          };
        }
      });
    });
    Promise.all(selectedFilesPreview)
      .then((values) => {
        files = [...files, ...values];
      })
      .catch((err) => {
        console.log("err", err);
      })
      .then(() => {
        handleUploadCarImage(files);
      });
  };

  const handleUploadCarImage = (res) => {
    const payload = {
      carId: props?.carID,
      imageBase64: res,
      carImageCategoryId: +activeCategory,
    };
    console.log("payload", payload);
    setLoading(true);
    addImageFromShop({ variables: payload })
      .then((response) => {
        console.log("res", response);
        const dataPayload = uploadedImage;
        response?.data?.addImageFromShop?.carImage?.length > 0 &&
          response?.data?.addImageFromShop?.carImage.map((img) => {
            dataPayload[activeCategory].push(img);
          });
        setUploadedImage(dataPayload);
        setDoneCategory([...doneCategory, activeCategory]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleDeleteImage = (carImageId) => {
    setLoading(true);
    const payload = {
      carId: props?.carID,
      carImageId: carImageId,
    };
    console.log("carImageId", carImageId);

    deleteImageFromShop({ variables: payload })
      .then((res) => {
        console.log("res", res);
        if (res?.data?.deleteImageFromShop?.success) {
          const tempArr = uploadedImage;
          const newTempArr = tempArr[activeCategory].filter(
            (item) => item.id !== carImageId
          );
          tempArr[activeCategory] = newTempArr;
          setUploadedImage(tempArr);
          console.log("tempArr", tempArr, uploadedImage);
          if (tempArr[activeCategory].length === 0) {
            const updateDoneCategory = doneCategory.filter(function (letter) {
              return letter !== activeCategory;
            });
            setDoneCategory(updateDoneCategory);
          }
          setDummyState(!dummyState);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleDeleteAllImage = () => {
    setLoading(true);
    const tempArr = uploadedImage[activeCategory]?.map((item) => {
      return item.id;
    });
    const payload = {
      ids: tempArr,
    };
    deleteAllImageFromCategory({ variables: payload })
      .then((res) => {
        console.log("res", res);
        const tempArr = uploadedImage;
        tempArr[activeCategory] = [];
        setUploadedImage(tempArr);
        const doneCategoryArr = doneCategory.filter((item) => {
          return item !== activeCategory;
        });
        setDoneCategory(doneCategoryArr);
        console.log("doneCategoryArr", doneCategoryArr);

        setDummyState(!dummyState);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleSkip = () => {
    // localStorage.removeItem("carId");
    props.handleNext();
  };

  const handleSave = () => {
    dispatch(
      setSuccessAlert({
        successAlertMsg: "Your images has been added successfully.",
        successAlertOpen: true,
      })
    );
    setTimeout(() => {
      navigate("/managers/home");
    }, 300);
    // handleSkip()
  };

  return (
    <div className="row">
      <div className="col-12 text-center basic-details-auth">
        <h4>Upload Image</h4>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-8 mx-auto">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p>Select a category to add pictures to</p>
          </div>
        </div>
      </div>
      <Spin tip="Uploading Image..." size="large" spinning={loading}>
        <div className="col-12 common-form">
          <div className="row">
            <div className="col-12 multiple-check-div text-center">
              {categoryList?.length > 0 &&
                categoryList?.map((data, index) => {
                  return (
                    <Button
                      onClick={() => setActiveCategory(data.id)}
                      className={`category-list-btn mx-2 my-2 text-capitalize 
                      ${doneCategory.includes(data?.id) ? "done" : ""}
                      ${activeCategory === data?.id ? "active" : ""}
                      `}
                      shape="round"
                      size="large"
                    >
                      {data.name}
                    </Button>
                  );
                })}
            </div>
            {!uploadedImage[activeCategory]?.length > 0 && (
              <div className="car-image-upload-container">
                <input
                  className="car-image-upload"
                  disabled={activeCategory===''}
                  onChange={(e) => handleFileUpload(e)}
                  type="file"
                  name="myfile"
                  multiple
                  accept=".jpg, .jpeg, .png"
                />
                <Button
                  className="image-Upload-btn"
                  disabled={activeCategory===''}
                  style={{ borderRadius: "4px" }}
                  icon={
                    <img
                      src={Images.upload_white_icon}
                      alt=""
                      className="img-fluid"
                    />
                  }
                >
                  Upload images
                </Button>
              </div>
            )}

            <div className="col-12 images-upload-list d-flex align-items-center justify-content-center">
              <ul className="list-inline mb-0 text-center d-flex align-items-center px-3 pb-4">
                {uploadedImage[activeCategory]?.length > 0 &&
                  uploadedImage[activeCategory]?.map((img, index) => {
                    return (
                      <ImageComponent
                        deleteImage={handleDeleteImage}
                        data={img}
                      />
                    );
                  })}
              </ul>
              {uploadedImage[activeCategory]?.length > 0 && (
                <div className="d-flex align-items-center justify-content-center pb-4">
                  <Button
                    onClick={() => handleDeleteAllImage()}
                    type="link"
                    style={{ height: "unset" }}
                  >
                    <svg
                      width="56"
                      height="56"
                      viewBox="0 0 56 56"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="56"
                        height="56"
                        rx="28"
                        fill="#51545E"
                        fill-opacity="0.51"
                      />
                      <path
                        d="M35.5304 21.5303C35.8232 21.2374 35.8232 20.7626 35.5304 20.4697C35.2375 20.1768 34.7626 20.1768 34.4697 20.4697L28 26.9394L21.5303 20.4697C21.2374 20.1768 20.7626 20.1768 20.4697 20.4697C20.1768 20.7626 20.1768 21.2374 20.4697 21.5303L26.9394 28L20.4697 34.4697C20.1768 34.7626 20.1768 35.2374 20.4697 35.5303C20.7626 35.8232 21.2375 35.8232 21.5304 35.5303L28 29.0607L34.4697 35.5303C34.7626 35.8232 35.2374 35.8232 35.5303 35.5303C35.8232 35.2374 35.8232 34.7626 35.5303 34.4697L29.0607 28L35.5304 21.5303Z"
                        fill="#DB524E"
                      />
                    </svg>
                  </Button>
                  <div className="car-image-upload-container">
                    <input
                      className="car-image-upload"
                      onChange={(e) => handleFileUpload(e)}
                      type="file"
                      name="myfile"
                      multiple
                      accept=".jpg, .jpeg, .png"
                    />
                    <Button
                      className="image-Upload-btn"
                      style={{ borderRadius: "75px" }}
                      icon={
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 12H20M12 20V12L12 4"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      }
                    >
                      Add More
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div className="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6 mx-auto">
              <Button
                disabled={
                  uploadedImage[activeCategory]?.length === 0 ||
                  activeCategory === ""
                }
                onClick={() => handleSave()}
                className="theme-common-btn subscribe-btn w-100"
                type="primary"
              >
                Save
              </Button>
            </div>
            <div className="col-12 col-sm-12 col-md-10 col-lg-12 col-xl-12 mt-3 mx-auto">
              {!doneCategory.length > 0 && (
                <Button
                  type="link"
                  onClick={() => handleSkip()}
                  className="fs-5 w-100 text-white"
                >
                  Skip
                </Button>
              )}
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

const ImageComponent = (props) => {
  const [hovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <li className="list-inline-item">
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="images-section position-relative overflow-hidden"
      >
        <img
          src={props.data.imageUrl}
          alt={props.data.imageUrl}
          className="img-fluid"
        />
        {hovered && (
          <div className="part-overlay position-absolute">
            <Button
              onClick={() => props.deleteImage(props.data.id)}
              className="p-0 bg-transparent shadow-none border-0 h-auto"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.2871 5.24297C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871ZM18.8058 19.134C19.1102 16.2971 19.6432 9.55712 19.6432 9.48913C19.6626 9.28313 19.5955 9.08813 19.4623 8.93113C19.3193 8.78413 19.1384 8.69713 18.9391 8.69713H5.06852C4.86818 8.69713 4.67756 8.78413 4.54529 8.93113C4.41108 9.08813 4.34494 9.28313 4.35467 9.48913C4.35646 9.50162 4.37558 9.73903 4.40755 10.1359C4.54958 11.8992 4.94517 16.8102 5.20079 19.134C5.38168 20.846 6.50498 21.922 8.13206 21.961C9.38763 21.99 10.6811 22 12.0038 22C13.2496 22 14.5149 21.99 15.8094 21.961C17.4929 21.932 18.6152 20.875 18.8058 19.134Z"
                  fill="#AFFF06"
                />
              </svg>
            </Button>
          </div>
        )}
      </div>
    </li>
  );
};

export default UploadImage;
