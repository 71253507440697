
import React from "react";
import { Button, Form, Input } from "antd";


import { Image as Images } from "../Images";





import "../../assets/styles/form.scss";
import "../../assets/styles/auths.scss";
import { Link} from "react-router-dom";
import { useNavigate } from "react-router-dom";

const FormTwo = (props) => {
    const navigate = useNavigate();
   
  return (
  
  
          
    <div className="container-fluid auths-main-fluid auths-main-profile overflow-auto">
    
    <div className="auth-shadow-effect" >
        <img src="/static/media/auth-shadow-effect.b6a52928baa04fae196daaf7b7143c9f.svg" alt="" class="img-fluid"/>

       
        </div>
    <div className="row mx-0 position-relative">
      <div className="col-12 col-sm-12 col-xl-9 col-lg-12 col-md-10 mx-auto auth-left-div">
      <div className="auth-logo-section" style={{display:"flex",justifyContent:"center"}}>
        <Link to={"/"}>
          <img src={Images.auth_logo} alt="" className="img-fluid" />
        </Link>
      </div>
  <div className="row">
    <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8 mx-auto text-center basic-details-auth">
      <h4>Enter Cards Details</h4>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-8 mx-auto">
          <p>
            Enter your Credit Card information - iONclassic uses stripe online
            pay ent system
          </p>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-8 mx-auto common-form">
      <Form
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        //onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-12">
            <Form.Item
              label="Cardholder's Name"
              name="card_holder_name"
              rules={[
                {
                  required: false,
                  message: "Please input your Cardholder's Name!",
                },
              ]}
            >
              <Input placeholder="Cardholder's Name" />
            </Form.Item>
          </div>
          <div className="col-12">
            <Form.Item
              label="Card Number"
              name="card_number"
              rules={[
                {
                  required: false,
                  message: "Please input your Card Number!",
                },
              ]}
            >
              <Input placeholder="XXXX  XXXX  XXXX  XXXX" />
            </Form.Item>
          </div>
          <div className="col-12 col-sm-6">
            <Form.Item
              label="CVV"
              name="cvv"
              rules={[
                {
                  required: false,
                  message: "Please input your CVV!",
                },
              ]}
            >
              <Input placeholder="XXX" />
            </Form.Item>
          </div>
          <div className="col-12 col-sm-6">
            <Form.Item
              label="Expiry Date"
              name="expiry_date"
              rules={[
                {
                  required: false,
                  message: "Please input your Expiry Date!",
                },
              ]}
            >
              <Input placeholder="MM/YY" />
            </Form.Item>
          </div>

          <div className="col-12">
            <Button
              className="theme-common-btn subscribe-btn w-100"
              // disabled
              type="primary"
              // onClick={(e) => {
              //   e.preventDefault();
              //   props.handleNextStep();
              // }}
              htmlType="submit"
            >
              Subscribe Now
            </Button>
          </div>
        </div>
      </Form>
    </div>
  </div>
  </div>
  </div>
  </div>




  )}
  export default FormTwo ;