import { SET_SUCCESS_MODAL, REMOVE_SUCCESS_MODAL } from "./successModal.type";
export const setSuccessAlert = (payload) => async (dispatch) => {
  setTimeout(() => {
    dispatch({
      type: REMOVE_SUCCESS_MODAL,
    });
  }, 3 * 1000);
  dispatch({
    type: SET_SUCCESS_MODAL,
    payload: payload,
  });
};

export const removeSuccessAlert = () => async (dispatch) => {
  dispatch({
    type: REMOVE_SUCCESS_MODAL,
  });
};
