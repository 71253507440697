import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { Image as Images } from "../../components/Images";
import AfterLoginHeader from "../../components/common/AfterLoginHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Modal } from "antd";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { EyeFilled } from "@ant-design/icons";

function ViewSlideShowHighLight() {
  const navigate = useNavigate();
  const location = useLocation();
  const id = useLocation().search;
  const searchParams = new URLSearchParams(id);
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [visibleImageUrl, setVisibleImageUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const transformComponentRef1 = useRef("transformComponentRef1");

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleOpen = () => {
    setIsModalOpen(true);
    console.log("tt", transformComponentRef1?.current);
    if (
      transformComponentRef1?.current !==
      ("" || null || undefined || "transformComponentRef1")
    ) {
      transformComponentRef1?.current?.resetTransform();
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <AfterLoginHeader />
      <div className="container-fluid shops-highlights-fluid pt-0 position-relative">
        <div onClick={() => navigate(-1)} className="slideshow-cancel-btn">
          <img src="/assets/icons/cancel-slide-show-btn.png" alt="cancel btn" />
        </div>
        {location?.state?.car?.carImages?.length > 0 ? (
          <>
            <Slider
              arrows={false}
              asNavFor={nav2}
              ref={(slider1) => setNav1(slider1)}
              infinite={false}
              loop={false}
              dots={false}
              adaptiveHeight={false}
              className="view-slide-slider"
            >
              {location?.state?.car?.carImages?.length > 0 &&
                location?.state?.car?.carImages?.map((item, index) => {
                  return (
                    <div key={index} className="position-relative">
                      <img
                        onClick={() => {
                          setVisibleImageUrl(item.imageUrl);
                          handleOpen();
                        }}
                        src={item?.imageUrl}
                        alt={item?.id}
                        className="
                              mx-auto
                              shopHighlightImg img-slide-box"
                      />
                    </div>
                  );
                })}
            </Slider>
            <Slider
              responsive={[
                {
                  breakpoint: 1650,
                  settings: {
                    slidesToShow: 7,
                    adaptiveHeight: false,
                  },
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 3,
                    adaptiveHeight: false,
                  },
                },
              ]}
              asNavFor={nav1}
              ref={(slider2) => setNav2(slider2)}
              nextArrow={
                <img
                  src={Images.primary_secondary_right_icon}
                  alt=""
                  className="img-fluid"
                />
              }
              prevArrow={
                <img
                  src={Images.primary_secondary_left_icon}
                  alt=""
                  className="img-fluid"
                />
              }
              arrows={true}
              slidesToShow={7}
              focusOnSelect={true}
              centerMode={true}
              infinite={true}
              loop={true}
              dots={false}
              adaptiveHeight={false}
              className="track-slider"
            >
              {location?.state?.car?.carImages?.length > 0 &&
                location?.state?.car?.carImages?.map((item, index) => {
                  return (
                    <div key={index} className="track-img position-relative">
                      <img
                        src={item?.imageUrl}
                        alt={item?.id}
                        className="img-fluid"
                      />
                    </div>
                  );
                })}
            </Slider>

            <Modal
              title=""
              className="image-modal"
              centered={true}
              open={isModalOpen}
              onOk={handleOk}
              footer={false}
              onCancel={handleCancel}
            >
              <TransformWrapper ref={transformComponentRef1} initialScale={1}>
                <TransformComponent>
                  <img
                    className="image-fluid w-100"
                    src={visibleImageUrl}
                    alt=""
                  />
                </TransformComponent>
              </TransformWrapper>
            </Modal>
          </>
        ) : (
          <div className="no-image-container d-flex justify-content-center align-items-center vh-100">
            <div>
              <h4>No images added!</h4>
              <Button
                onClick={() =>
                  navigate(
                    `/managers/home/details/edit-images?id=${searchParams.get(
                      "id"
                    )}`
                  )
                }
                className="add-restoration-btn border-0"
              >
                <img src={Images.add_icon_white} alt="" className="img-fluid" />
                Add Images
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewSlideShowHighLight;
