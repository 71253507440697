import React from "react";
import CarGrid from "./CarGrid";

const CarGridView = ({ cars }) => {
  return (
    <>
      <div className="row">
        {cars.length > 0 ? (
          cars?.map((eachCar) => {
            return (
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <CarGrid
                  id={eachCar.id}
                  heroImageUrl={eachCar.heroImageUrl}
                  model={eachCar.model}
                  description={eachCar.description}
                  make={eachCar.make}
                  shopOwner={eachCar.shopOwner}
                  year={eachCar.year}
                  qrCodeUrl={eachCar.qrCodeUrl}
                  qrCodeDocUrl={eachCar?.qrCodeDocUrl}
                />
              </div>
            );
          })
        ) : (
          <div className="no-car-msg">{/* <p>No car available</p> */}</div>
        )}
      </div>
    </>
  );
};

export default CarGridView;
