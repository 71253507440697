import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Button, Spin } from "antd";
import { Image as Images } from "../../components/Images";
import "../../assets/styles/category.scss";
import AfterLoginHeader from "../../components/common/AfterLoginHeader";
import CarListView from "../../components/car-views/CarListView";
import CarGridView from "../../components/car-views/CarGridView";
import { useNavigate } from "react-router-dom";
import { gql } from "@apollo/client";
import Popup2 from "../../components/popup/Popup2";
import { GET_CAR_LIST } from "../../gqloperations/cars/get-car-list-mutation";
import { useMutation, useQuery } from "@apollo/client";
import { preventNavigationBack } from "../../../../ion_classic_reactweb/src/utils/helper";

const HomeCarsList = () => {
  useEffect(() => {
    preventNavigationBack();
    return () => {
      window.onpopstate = null;
    };
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const [getCarList, {}] = useMutation(GET_CAR_LIST);
  const shopId = localStorage.getItem("shopId");
  const [isCategoryView, setIsCategoryView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [carList, setCarList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  
  

  const handleToggleCategoryView = () => {
    setIsCategoryView((current) => !current);
  };

  const MANAGERS_SUBSCRIPTION = gql`
    mutation {
      managerSubscription {
        success
      }
    }
  `;

  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `${token}`,
  };

  const [managersSubscription, { error, data }] = useMutation(
    MANAGERS_SUBSCRIPTION,
    {
      context: {
        headers,
      },
    }
  );

  console.log("error:", error);
  console.log("raw response data:", data);

  useEffect(() => {
    managersSubscription()
      .then((response) => {
        console.log("Subscription success:", response.data);
        setHasActiveSubscription(
          response.data?.managerSubscription?.success === true
        );
      })
      .catch((error) => {
        console.error("Error:", error);
        setHasActiveSubscription(false);
      });
  }, []);

  useEffect(() => {
    if (shopId) {
      setLoading(true);
      getCarList({ variables: { shopId: shopId } })
        .then((res) => {
          console.log("res", res);
          const dataCars = res?.data?.getCarFromShop?.cars?.reverse();
          setCarList(dataCars);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error("Error fetching car list:", err);
        });
    }
  }, [shopId]);

  return (
    <>
      <AfterLoginHeader />
      <div className="container-fluid categories-main-fluid">
        <div className="container">
          <div className="row categories-row-heading">
            <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
              <h1 className="mb-0">Company Restorations</h1>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
              <ul className="list-inline mb-0 text-end">
                <li className="list-inline-item">
                  <Button
                    onClick={handleToggleCategoryView}
                    className="grid-list-view-btn border-0"
                  >
                    {isCategoryView ? (
                      <img
                        src={Images.list_view_white_icon}
                        alt=""
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        src={Images.grid_view_icon_white}
                        alt=""
                        className="img-fluid"
                      />
                    )}
                    {isCategoryView ? "List View" : "Grid View"}
                  </Button>
                </li>

                <li className="list-inline-item">
                  <Button
                    className="add-restoration-btn border-0"
                    onClick={() => {
                      if (!hasActiveSubscription) {
                        setVisible(true); // Show the pop-up
                      } else {
                        navigate("/managers/add-restorations");
                        console.log("Add Restoration");
                      }
                    }}
                  >
                    <img
                      src={Images.add_icon_white}
                      alt=""
                      className="img-fluid"
                    />
                    Add Restoration
                  </Button>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {loading ? (
                <div className="text-center">
                  <Spin size="large" />
                </div>
              ) : (
                <>
                  {isCategoryView ? (
                    <CarGridView loading={loading} cars={carList} />
                  ) : (
                    <CarListView loading={loading} cars={carList} />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Popup2
        title={"Subscribe!"}
        description={
          "Do subscribe to further add or manage content for your shop on  the iONclassic platform."
        }
        visible={visible}
        buttons={[
          {
            text: "Cancel",
            func: () => setVisible(false),
            className: "cancel-button",
          },
          {
            text: "Ok",
            func: async () => {
              navigate("/managers/manage-subcription");
            },

            className: "primary-button",
          },
        ]}
      ></Popup2>
    </>
  );
};

export default HomeCarsList;
