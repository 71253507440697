import { gql, useQuery } from "@apollo/client";
export const GET_CAR_IMAGE_CATEGORY_LIST = gql`
  query getCarImageCategoryList {
    carImageCategories {
      id
      name
    }
  }
`;
export const GetCarImageCategoryList = () => {
  const { data, loading, error } = useQuery(GET_CAR_IMAGE_CATEGORY_LIST, {
    fetchPolicy: "network-only",
  });

  return {
    data,
    loading,
    error,
  };
};
