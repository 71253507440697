import auth_logo from "../assets/images/ion-logo-auth.svg";
import auth_shadow_effect from "../assets/images/auth-shadow-effect.svg";
import auth_slider_img_1 from "../assets/images/auth-slider-img-1.svg";
import auth_slider_img_2 from "../assets/images/auth-slider-img-2.svg";
import auth_slider_img_3 from "../assets/images/auth-slider-img-3.svg";
import auth_slider_img_4 from "../assets/images/auth-slider-img-4.svg";
import login_slider_img_1 from "../assets/images/login-slider-1.svg";
import login_slider_img_2 from "../assets/images/login-slider-2.svg";
import login_slider_img_3 from "../assets/images/login-slider-3.svg";
import facebook_white_icon from "../assets/images/icons/facebook-icon.svg";
import instagram_white_icon from "../assets/images/icons/instagram-icon.svg";
import youtube_white_icon from "../assets/images/icons/youtube-icon.svg";
import apple_white_icon from "../assets/images/icons/apple-icon.svg";
import snapchat_white_icon from "../assets/images/icons/snapchat-icon.svg";
import theme_secondary_check_icon from "../assets/images/icons/theme-secondary-check.svg";
import check_icon_circle from "../assets/images/icons/check-secondary-circle-icon.svg";
import check_icon_circle_primary from "../assets/images/icons/check-circle-primary-success.svg";
import select_arrow_down from "../assets/images/icons/select-arrow-down.svg";
import tick_circle_white from "../assets/images/icons/tick-circle-white.svg";
import upload_white_icon from "../assets/images/icons/upload-white-icon.svg";
import part_img_1 from "../assets/images/part-img-1.svg";
import part_img_2 from "../assets/images/part-img-2.svg";
import part_img_3 from "../assets/images/part-img-3.svg";
import part_img_4 from "../assets/images/part-img-4.svg";
import close_icon_red from "../assets/images/icons/close-icon-red.svg";
import add_icon_white from "../assets/images/icons/add-icon-white.svg";
import logo_main_white from "../assets/images/logo-main-white.svg";
import app_store_logo from "../assets/images/icons/app-store-logo.svg";
import google_play_store_logo from "../assets/images/icons/google-play-store-logo.svg";
import slider_banner_img_1 from "../assets/images/slider-banner-1.png";
import slider_banner_img_2 from "../assets/images/slider-banner-2.png";
import slider_banner_img_3 from "../assets/images/slider-banner-3.png";
import slider_banner_img_4 from "../assets/images/slider-banner-4.png";
import arrow_gray_left_big from "../assets/images/icons/arrow-left-gray-big.svg";
import arrow_white_right_big from "../assets/images/icons/arrow-right-white-big.svg";
import slick_carousel_img_1 from "../assets/images/slick-carousel-small-img-1.svg";
import slick_carousel_img_2 from "../assets/images/slick-carousel-small-img-2.svg";
import slick_carousel_img_3 from "../assets/images/slick-carousel-small-img-3.svg";
import slick_carousel_img_4 from "../assets/images/slick-carousel-small-img-4.svg";
import fill_rounded_right_arrow_white from "../assets/images/icons/right-fill-rounded-arrow-white.svg";
import right_tools_mobile_img from "../assets/images/right-tools-mobile-img.png";
import job_details_img from "../assets/images/job-details-img.png";
import job_details_icon_0 from "../assets/images/icons/job-details/job-details-icon-0.svg"
import job_details_icon_1 from "../assets/images/icons/job-details/job-details-icon-1.svg";
import job_details_icon_2 from "../assets/images/icons/job-details/job-details-icon-2.svg";
import job_details_icon_3 from "../assets/images/icons/job-details/job-details-icon-3.svg";
import job_details_icon_4 from "../assets/images/icons/job-details/job-details-icon-4.svg";
import job_details_icon_5 from "../assets/images/icons/job-details/job-details-icon-5.svg";
import job_details_icon_6 from "../assets/images/icons/job-details/job-details-icon-6.svg";
import automotive_digital_img from "../assets/images/automotive-digital-img.svg";
import engage_customers_icon from "../assets/images/icons/engage-customers-icon.svg";
import digital_documentation_icon from "../assets/images/icons/digital-documentation-icon.svg";
import promote_your_shop_icon from "../assets/images/icons/promote-your-shop.svg";
import client_img_1 from "../assets/images/client-img-1.svg";
import client_img_2 from "../assets/images/client-img-2.svg";
import client_img_3 from "../assets/images/client-img-3.svg";
import client_img_4 from "../assets/images/client-img-4.svg";
import client_img_5 from "../assets/images/client-img-5.svg";
import shops_highlights_slider_img_1 from "../assets/images/shops-highlights-slider-img-1.svg";
import arrow_right_up_primary from "../assets/images/icons/arrow-right-up-primary.svg";
import highlight_slick_img_1 from "../assets/images/highlight-slick-img-1.png";
import highlight_slick_img_2 from "../assets/images/highlight-slick-img-2.png";
import highlight_slick_img_3 from "../assets/images/highlight-slick-img-3.png";
import highlight_slick_img_4 from "../assets/images/highlight-slick-img-4.png";
import highlight_slick_img_5 from "../assets/images/highlight-slick-img-5.png";
import highlight_slick_img_6 from "../assets/images/highlight-slick-img-6.png";
import highlight_slick_img_7 from "../assets/images/highlight-slick-img-7.png";
import primary_secondary_left_icon from "../assets/images/icons/primary-secondary-left-icon.svg";
import primary_secondary_right_icon from "../assets/images/icons/primary-secondary-right-icon.svg";
import star_secondary_icon from "../assets/images/icons/star-secondary-icon.svg";
import community_member_img_1 from "../assets/images/community-member-1.svg";
import community_member_img_2 from "../assets/images/community-member-2.svg";
import mobile_mockup_ios_img from "../assets/images/mobile-mockup-ios.png";
import mobile_mockup_android_img from "../assets/images/mobile-mockup-android.png";
import logo_ion_footer from "../assets/images/logo-ion-footer.svg";
import faq_banner_img from "../assets/images/faq-banner-img.png";
import right_arrow_theme_secondary from "../assets/images/icons/right-arrow-theme-secondary.svg";
import project_app_demo_1 from "../assets/images/project-app-demo-1.png";
import project_app_demo_2 from "../assets/images/project-app-demo-2.png";
import few_picture_mobile_app_flow from "../assets/images/few-picture-mobile-app-flow.png";
import document_app_img_dummy from "../assets/images/document-app-img-dummy.png";
import vehicle_sell_flow_1 from "../assets/images/vehicle-sell-steps-1.svg";
import vehicle_sell_flow_2 from "../assets/images/vehicle-sell-steps-2.svg";
import vehicle_sell_flow_3 from "../assets/images/vehicle-sell-steps-3.svg";
import vehicle_sell_flow_4 from "../assets/images/vehicle-sell-steps-4.svg";
import regards_big_img from "../assets/images/regards-big-img.svg";
import regards_sign from "../assets/images/regards-sign.png";
import facebook_white_icon_circle from "../assets/images/icons/facebook-white-icon.svg";
import facebook_update_slider_1 from "../assets/images/facebook-update-slider-1.png";
import facebook_update_slider_2 from "../assets/images/facebook-update-slider-2.png";
import facebook_update_slider_3 from "../assets/images/facebook-update-slider-3.png";
import category_img_1 from "../assets/images/category-img-1.png";
import category_img_2 from "../assets/images/category-img-2.png";
import category_img_3 from "../assets/images/category-img-3.png";
import category_img_4 from "../assets/images/category-img-4.png";
import qr_scan_img from "../assets/images/qr-scan-img.png";
import login_user_img from "../assets/images/icons/profile-icon-white.png";
import grid_view_icon_white from "../assets/images/icons/grid-view-img.svg";
import profile_icon_white from "../assets/images/icons/profile-icon-white.svg";
import manage_subscription_icon_white from "../assets/images/icons/manage-subscription-icon-white.svg";
import credit_card_icon_white from "../assets/images/icons/credit-card-icon-white.svg";
import log_out_icon_red from "../assets/images/icons/log-out-icon-red.svg";
import list_view_white_icon from "../assets/images/icons/list-view-white-icon.svg";
import delete_icon from "../assets/images/icons/delete_icon.svg";
import tick_circle from "../assets/images/icons/Tick Circle.svg";
import previous_arrow from "../assets/images/icons/previous-arrow.svg";
import paymentCardIcon from "../assets/images/icons/payment-card-background.svg";
import chip from "../assets/images/icons/chip.svg";
import payPass from "../assets/images/icons/paypass.svg";
import visa from "../assets/images/icons/visa.svg";
import mastercard from "../assets/images/icons/mastercard.svg";
import americanexpress from "../assets/images/icons/americanexpress.svg"
import discover from "../assets/images/icons/discover.svg";
import unionpay from "../assets/images/icons/unionpay.svg";
import jcb from "../assets/images/icons/jcb.svg";
import dinerclub from "../assets/images/icons/dinersclub.svg"


export let Image = {
  tick_circle,
  visa,
  unionpay,
  mastercard,
  chip,
  payPass,
  dinerclub,
  discover,
  jcb,
  americanexpress,
  paymentCardIcon,
  previous_arrow,
  app_store_logo,
  mobile_mockup_ios_img,
  mobile_mockup_android_img,
  primary_secondary_left_icon,
  primary_secondary_right_icon,
  engage_customers_icon,
  digital_documentation_icon,
  promote_your_shop_icon,
  arrow_gray_left_big,
  arrow_white_right_big,
  google_play_store_logo,
  auth_logo,
  auth_shadow_effect,
  auth_slider_img_1,
  auth_slider_img_2,
  auth_slider_img_3,
  auth_slider_img_4,
  login_slider_img_1,
  login_slider_img_2,
  login_slider_img_3,
  facebook_white_icon,
  instagram_white_icon,
  youtube_white_icon,
  apple_white_icon,
  theme_secondary_check_icon,
  snapchat_white_icon,
  check_icon_circle,
  check_icon_circle_primary,
  select_arrow_down,
  tick_circle_white,
  upload_white_icon,
  part_img_1,
  part_img_2,
  part_img_3,
  part_img_4,
  close_icon_red,
  add_icon_white,
  logo_main_white,
  slider_banner_img_1,
  slider_banner_img_2,
  slider_banner_img_3,
  slider_banner_img_4,
  slick_carousel_img_1,
  slick_carousel_img_2,
  slick_carousel_img_3,
  slick_carousel_img_4,
  fill_rounded_right_arrow_white,
  right_tools_mobile_img,
  job_details_img,
  job_details_icon_0,
  job_details_icon_1,
  job_details_icon_2,
  job_details_icon_3,
  job_details_icon_4,
  job_details_icon_5,
  job_details_icon_6,
  automotive_digital_img,
  client_img_1,
  client_img_2,
  client_img_3,
  client_img_4,
  client_img_5,
  shops_highlights_slider_img_1,
  arrow_right_up_primary,
  highlight_slick_img_1,
  highlight_slick_img_2,
  highlight_slick_img_3,
  highlight_slick_img_4,
  highlight_slick_img_5,
  highlight_slick_img_6,
  highlight_slick_img_7,
  star_secondary_icon,
  community_member_img_1,
  community_member_img_2,
  logo_ion_footer,
  faq_banner_img,
  right_arrow_theme_secondary,
  project_app_demo_1,
  project_app_demo_2,
  document_app_img_dummy,
  few_picture_mobile_app_flow,
  vehicle_sell_flow_1,
  vehicle_sell_flow_2,
  vehicle_sell_flow_3,
  vehicle_sell_flow_4,
  regards_big_img,
  regards_sign,
  facebook_white_icon_circle,
  facebook_update_slider_1,
  facebook_update_slider_2,
  facebook_update_slider_3,
  category_img_1,
  category_img_2,
  category_img_3,
  category_img_4,
  qr_scan_img,
  grid_view_icon_white,
  login_user_img,
  profile_icon_white,
  manage_subscription_icon_white,
  credit_card_icon_white,
  log_out_icon_red,
  list_view_white_icon,
  delete_icon
};
