import { gql, useQuery } from "@apollo/client";
export const GET_CURRENT_MANAGER = gql`
  query getCurrentManager {
    currentManager {
      email
      id
      shop {
        id
        companyName
      }
    }
  }
`;
export const GetCurrentManager = (id) => {
  const { data, loading, error } = useQuery(GET_CURRENT_MANAGER, {
    variables: {
      id,
    },
  });

  return {
    managerData: data,
    managerLoading: loading,
    managerError: error,
  };
};
