import React from "react";
import { Button, Carousel, Rate } from "antd";
import { Image as Images } from "../Images";

const OurCommunity = () => {
  const carouselRef = React.createRef();
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const onChange = (currentSlide) => {
    console.log(currentSlide);
    setCurrentSlide(currentSlide);
  };

  const ourCommumityData = [
    {
      name: "Allen Arguijo - Member since 2019",
      img: "/assets/images/fb-slider/Allens%20Jeep.jpeg",
      rating: 5,
      word: "Using iONclassic to keep track of all the mods for my Jeep has turned out to be fun as friends have followed my modifications ",
    },
    {
      name: "Clive Hallet - Member since 2020",
      img: "/assets/images/fb-slider/Clives%20Jag.jpeg",
      rating: 5,
      word: "I now have my mechanic using iONclassic when he works on my E type and best of all I get a real time record of the work he does",
    },
    {
      name: "Nick Martin - Member since 2019",
      img: "/assets/images/fb-slider/Nicks%20Bugeye.jpeg",
      rating: 4,
      word: "Using iONclassic to track all the repairs on my Bug Eye Race Car, really works well when the judges ask for a list of modifications - Nick Martin",
    },
    {
      name: "Philippe Duchamp - Member since 2020",
      img: "/assets/images/fb-slider/Philippe%20Lotus.jpeg",
      rating: 4,
      word: "Was looking for a way to keep all my records for my modern day classic Lotus, when I hand this car down it will have a perfect record of all repairs and modifications",
    },
    {
      name: "Steve Sobecki - Member since 2023",
      img: "/assets/images/fb-slider/Steves%20Truck.jpeg",
      rating: 5,
      word: "Been looking for an App like this to keep track of all the pictures I'm planning to take as I rebuild this 1959 Apache",
    },
    {
      name: "Tommy Nye - Member since 2022",
      img: "/assets/images/fb-slider/Tommy's%20Model%20T.jpg",
      rating: 5,
      word: "Documenting my 1927 Model T Hotrod, handed down from my Dad so the next person will have a complete record of all modifications, great app no matter where your starting out",
    },
  ];

  return (
    <>
      <div className="row position-relative">
        <div className="arrow-with-count position-absolute">
          <ul className="list-inline">
            <li className="list-inline-item">
              <Button
                onClick={() => {
                  !(currentSlide === 0) &&
                    carouselRef.current.goTo(currentSlide - 1);
                }}
                className="bg-transparent border-0 p-0 shadow-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="87"
                  height="12"
                  viewBox="0 0 87 12"
                  fill="none"
                >
                  <path
                    id="Arrow 2"
                    opacity="0.5"
                    d="M0.469673 5.46967C0.176773 5.76256 0.176773 6.23744 0.469673 6.53033L5.24264 11.3033C5.53554 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53554 0.403806 5.24264 0.696699L0.469673 5.46967ZM87 5.25L1 5.25V6.75L87 6.75V5.25Z"
                    fill={currentSlide <= 0 ? "#D4D4D4" : "#FFFFFF"}
                    style={{ opacity: currentSlide > 0 && 1 }}
                  />
                </svg>
              </Button>
            </li>
            <li className="list-inline-item">
              <span>0{currentSlide + 1}</span> / 0{ourCommumityData.length}
            </li>
            <li className="list-inline-item">
              <Button
                onClick={() => {
                  !(currentSlide === ourCommumityData.length - 1) &&
                    carouselRef.current.goTo(currentSlide + 1);
                }}
                className="bg-transparent border-0 p-0 shadow-none"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="172"
                  height="12"
                  viewBox="0 0 172 12"
                  fill="none"
                >
                  <path
                    d="M171.53 6.53035C171.823 6.23745 171.823 5.76258 171.53 5.46968L166.757 0.696714C166.464 0.40382 165.99 0.40382 165.697 0.696714C165.404 0.989607 165.404 1.46448 165.697 1.75737L169.939 6.00001L165.697 10.2427C165.404 10.5355 165.404 11.0104 165.697 11.3033C165.99 11.5962 166.464 11.5962 166.757 11.3033L171.53 6.53035ZM-6.55671e-08 6.75L171 6.75001L171 5.25001L6.55671e-08 5.25L-6.55671e-08 6.75Z"
                    fill={
                      currentSlide === ourCommumityData.length - 1
                        ? "#D4D4D4"
                        : "#FFFFFF"
                    }
                    style={{
                      opacity:
                        currentSlide === ourCommumityData.length - 1 ? 0.5 : 1,
                    }}
                  />
                </svg>
                {/* <img
                    src={Images.arrow_white_right_big}
                    alt=""
                    className="img-fluid"
                  /> */}
              </Button>
            </li>
          </ul>
        </div>
        <Carousel
          slidesToShow={2}
          slidesToScroll={2}
          afterChange={onChange}
          dots={false}
          infinite={false}
          ref={carouselRef}
          responsive={[
            {
              breakpoint: 1080,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
          ]}
        >
          {ourCommumityData.map((data, index) => {
            return (
              <div key={index}>
                <div key={index} className="community-slider-data">
                  <div className="community-member-img float-start">
                    <img src={data?.img} alt="" className="img-fluid" />
                  </div>
                  <div className="community-details-div float-start">
                    <p>{data?.word}</p>
                    <Rate
                      character={
                        <img
                          src={Images.star_secondary_icon}
                          alt=""
                          class="img-fluid"
                        ></img>
                      }
                      className="custom-rating"
                      count={data?.rating}
                    />

                    <h6 className="mb-0 text-uppercase">{data?.name}</h6>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

export default OurCommunity;
