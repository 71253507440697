import { gql, useQuery } from "@apollo/client";
export const GET_CAR_CATEGORIES_IMAGE_LIST = gql`
  query getCarCategoriesImageList($id: ID!) {
    car(id: $id) {
      id
      make
      model
      isPublic
      qrCodeUrl
      carImageCategories {
        id
        images(carId: $id) {
          carImageCategoryId
          id
          imageUrl
        }
        name
      }
    }
  }
`;
export const GetCarCategoriesImageList = (id) => {
  const { data, loading, error } = useQuery(GET_CAR_CATEGORIES_IMAGE_LIST, {
    variables: {
      id,
    },
    fetchPolicy: "network-only",
  });

  return {
    CarImageData: data?.car,
    loading,
    error,
  };
};
