import { gql } from "@apollo/client";

export const GET_CAR_LIST = gql`
  mutation getCarListMutation($shopId: ID!) {
    getCarFromShop(input: { shopId: $shopId }) {
      cars {
        id
        year
        make
        model
        vin
        chassis
        exteriorColor
        interiorColor
        driveType
        transmission
        bodyStyle
        engine
        fuel
        mileage
        isPublic
        shopOwner
        heroImageUrl
        description
        qrCodeUrl
        qrCodeDocUrl
        qrCodePdfUrl
        comments
      }
    }
  }
`;
