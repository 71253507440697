import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { connect, useDispatch } from 'react-redux';
import { removeSuccessAlert } from '../../redux/successModal/successModal.action';
import './success.modal.scss'
const ModalComponent = ({successAlert}) => {
  const dispatch = useDispatch();
  window.scrollTo(0,0);


  return (
    <>
      <Modal
        centered
        open={successAlert?.successAlertOpen}
        className='success-popup'
        footer={false}
        title={false}
        closeIcon={""}
        cancelButtonProps={""}
        onOk={() => {dispatch(removeSuccessAlert());}}
        onCancel={() => {dispatch(removeSuccessAlert());}}
        style={{width:"100vW", height:"100vH"}}
      >
        <div>
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M49.9987 91.6665C73.0106 91.6665 91.6654 73.0117 91.6654 49.9998C91.6654 26.988 73.0106 8.33316 49.9987 8.33316C26.9868 8.33316 8.33203 26.988 8.33203 49.9998C8.33203 73.0117 26.9868 91.6665 49.9987 91.6665ZM73.1603 39.5843C74.3115 38.2984 74.2023 36.3228 72.9165 35.1716C71.6306 34.0204 69.655 34.1295 68.5038 35.4154L54.4656 51.0953C51.621 54.2725 49.7054 56.401 48.0675 57.7776C46.5073 59.089 45.5909 59.3748 44.7904 59.3748C43.9898 59.3748 43.0734 59.089 41.5132 57.7776C39.8754 56.401 37.9597 54.2725 35.1151 51.0953L31.4936 47.0502C30.3424 45.7644 28.3667 45.6552 27.0809 46.8064C25.7951 47.9577 25.6859 49.9333 26.8371 51.2191L30.6134 55.437C33.2628 58.3964 35.4726 60.8649 37.4918 62.562C39.6278 64.3573 41.9317 65.6248 44.7904 65.6248C47.649 65.6248 49.9529 64.3574 52.0889 62.562C54.1081 60.8649 56.3179 58.3965 58.9673 55.437L73.1603 39.5843Z" fill="url(#paint0_linear_99_4105)"/>
<defs>
<linearGradient id="paint0_linear_99_4105" x1="8.5" y1="18.5" x2="84" y2="84" gradientUnits="userSpaceOnUse">
<stop stop-color="#AFFF06"/>
<stop offset="1" stop-color="#00A0FF"/>
</linearGradient>
</defs>
</svg>

<p className='title'>
    {successAlert?.successAlertMsg}
</p>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    successAlert: state.successAlert,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeSuccessAlert: () => dispatch(removeSuccessAlert()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalComponent);