import { gql } from "@apollo/client";
// const { auth_token } = JSON.parse(localStorage.getItem("userDetails"));
export const SIGNUP_USER = gql`
  mutation MyMutation(
    $email: String!
    $password: String!
    $managerType: String!
  ) {
    createManager(
      input: {
        username: $email
        password: $password
        managerType: $managerType
      }
    ) {
      token
    }
  }
`;
