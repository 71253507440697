import React from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import "../assets/styles/faq.scss";
import { Image as Images } from "./Images";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "antd";

const Faq = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div className="container-fluid faq-heading-fluid">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>
                <span className="shadow-text">Our cars have many lives,</span>
                <span className="theme-secondary-text">
                  lets document them
                </span>{" "}
                for the next person.
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid faq-banner-fluid">
        <div className="row mx-0">
          <div className="col-12 p-0">
            <img src={Images.faq_banner_img} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="container-fluid faq-details-fluid">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="faq-details-row">
                <div className="col-12">
                  <h5 className="d-flex align-items-center">
                    <img
                      src={Images.right_arrow_theme_secondary}
                      alt=""
                      className="img-fluid"
                    />
                    Welcome to iON Classic
                  </h5>
                  <p>
                    We are excited that you have selected to use iON Classic,
                    the first and only mobile application designed with
                    restoration shops and custom builders in mind. iONclassic
                    was designed from the start with a focus on helping shops
                    and builders create, manage and offer their customers
                    complete digital documentation for their projects.
                  </p>
                  <p>
                    iONclassic helps your shop create a highly valued digital
                    diary of the project, all the time keeping customer
                    informed, engaged, and excited. Features such as a shop
                    digital showroom, QR codes, social media integration and
                    sharing all enhance the experience and allows iONclassic to
                    attract customers to your shop.
                  </p>
                  <p>
                    When you deliver the amazing car to its new owner, you are
                    one email away from delivering all of the projects pictures
                    and documentation while iONclassic keeps your shop connected
                    to the car where ever it goes.
                  </p>
                </div>
              </div>
              <div className="faq-details-row">
                <div className="col-12">
                  <h5 className="d-flex align-items-center">
                    <img
                      src={Images.right_arrow_theme_secondary}
                      alt=""
                      className="img-fluid"
                    />
                    Getting Started
                  </h5>
                  <ul>
                    <li>
                      I’m an individual looking to document my personal car:
                    </li>
                    <li>
                      Once you have downloaded the iON Classic app from the
                      Apple/Android app store
                    </li>
                    <li>
                      Create an account with an email address and password
                    </li>
                    <li>
                      Create a Profile and add a profile picture for your
                      account
                    </li>
                  </ul>
                  <h6>GREAT YOU’RE IN!</h6>
                  <ul>
                    <li>
                      It was that easy, you are now connected with a wide range
                      of fellow enthusiasts, shops, and mechanics where you can
                      follow their projects! The classic automotive culture is
                      full of owners and non-owners alike, the iON Classic
                      platform was designed to allow you to participate and
                      enjoy the hobby!
                    </li>
                  </ul>
                  <h6>BUT I’M A SHOP AND WANT TO GET STARTED:</h6>
                  <ul>
                    <li>
                      Don’t worry we have you covered, it you’re a shop looking
                      to use iONclassic for your shop, please download our{" "}
                      <Link
                        to={"/docs/iONclassic Quick Start Guide.pdf"}
                        target="_blank"
                      >
                        “Shop Quick Guide”
                      </Link>{" "}
                      located here:
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row add-vehicle-row">
            <div className="col-12 col-sm-12 col-md-12 col-xl-10 col-lg-10 mx-auto">
              <h2 className="text-uppercase">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                DO YOU HAVE A VEHICLE YOU <br />
                <span>WOULD LIKE TO ADD?</span> IT'S EASY!
              </h2>
              <p>
                It was that easy, you are now connected with a wide range of
                fellow enthusiasts, shops, and mechanics where you can follow
                their projects! The classic automotive culture is full of owners
                and non-owners alike, the iON Classic platform was designed to
                allow you to participate and enjoy the hobby regardless if you
                are an owner or not!
              </p>
              <h3 className="text-uppercase">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                LET'S GET STARTED WITH CREATING YOUR CLASSIC OR PROJECT'S
                PROFILE...
              </h3>
              <div className="row project-overview-row">
                <div className="col-12 col-sm-6 col-md-5 mx-auto text-center">
                  <div className="row project-demo-card">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 project-img">
                          <img
                            src={Images.project_app_demo_1}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-12 project-demo-details">
                          <h5>
                            SELECT ADD CAR TO ADD YOUR VEHICLE PROFILE TO THE
                            iON CLASSIC APP
                          </h5>
                          <p>Don’t want to add a Vehicle now?</p>
                          <span>No problem, just skip this step</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-5 mx-auto text-center">
                  <div className="row project-demo-card">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 project-img">
                          <img
                            src={Images.project_app_demo_2}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-12 project-demo-details">
                          <h5>
                            ADD YOUR VEHICLE’S INFORMATION AND MOST IMPORTANTLY
                            A PICTURE!
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row more-details-demo">
                <div className="col-12">
                  <p>
                    The power of the platform is when you use it, invite your
                    shops and mechanics to help you keep an up to date
                    documented history. Having some work done, snap a picture,
                    have some motor work done, have the mechanic take a few pics
                    for you, before you know it you have full digital
                    documentation of your project or classic. Of course, iON
                    Classic also allows you to create a completely private
                    profile while using the power of the iON Classic app to
                    create your digital documentation, how social you want to be
                    is up to you!
                  </p>
                  <p>
                    Now’s a{" "}
                    <Link style={{ textDecoration: "none" }} to={" "}>
                      GREAT TIME TO ADD SOME PICTURES,
                    </Link>{" "}
                    so find those pictures on your phone or simply take a few
                    new pictures and let’s get started. We will walk you through
                    it!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="picture-app-flow-bg w-100">
          <div className="container mx-auto row few-picture-row align-items-center">
            <div className="mt-5  col-12 col-sm-12 col-md-12 col-xl-6 col-lg-6">
              <h2 className="text-uppercase">
                LET'S <span>ADD A FEW PICTURES TO GET STARTED</span> - WE'VE GOT
                YOU COVERED, IT'S EASY!
              </h2>
              <p className="mb-0 me-md-5">
                iONclassic makes documenting your restorations as easy as,
                selecting your car profile, selecting the pre-determined
                category and you’re on your way to creating a fully transferable
                set of documentation of your customers build.
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-xl-7 col-lg-7">
              <img
                style={{ position: "relative", right: "-25px" }}
                className="img-fluid d-block d-sm-none"
                src={Images.few_picture_mobile_app_flow}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="container text-center app-flow-description pt-5 mt-3">
          <p>
            At this point depending on your settings and how widely your profile
            is shared, your friends and fellow enthusiasts are automatically
            alerted to your latest update and can follow along with you on the
            progress you are making. The more you use iON Classic, the more
            valuable the digital documentation becomes, all along having fun
            with the social aspects of iON Classic!
          </p>
          <p>
            That’s it, we made it easy to add a picture from your phone or take
            one and add to your vehicle.
          </p>
        </div>
      </div>
      <div className="container-fluid faq-documentation-fluid">
        <div className="container">
          <div className="row">
            <div className="document-heading col-12 col-sm-12 col-md-12 col-xl-10 col-lg-10 mx-auto text-center">
              <h3 className="text-uppercase">
                YOU ARE NOW ON YOUR WAY TO{" "}
                <span>DOCUMENTING YOUR VEHICLE WITH iONCLASSIC</span>
              </h3>
              <p>
                iON Classic makes it so easy for you to add pictures and keep
                your digital diary up to date on all aspects of your vehicle.
                Fellow enthusiasts will be able to follow along and watch your
                progress!
              </p>
            </div>
            <div className="col-12">
              <div className="row align-items-center document-details-inner">
                <div className="col-12 d-flex align-items-center">
                  <div className="docs-section-details float-start">
                    <ul className="mb-0 text-end list-inline">
                      <li>
                        <span>DETAILS</span> is where you see your specific
                        information as well as others as they add information on
                        their classic or project.
                      </li>
                      <li>
                        Use the <span>EXPLORE</span> Function to see a list of
                        all the Vehicles, Vehicles, Vehicles, Shops and Owners
                        using the iON depending on their privacy settings of
                        course.
                      </li>
                      <li>
                        We previously covered the <span>CAMERA</span> Function,
                        that was easy!
                      </li>
                      <li>
                        <span>ALERTS</span> are where you can check in on all of
                        your favorites and get a running list of all the latest
                        activity
                      </li>
                    </ul>
                  </div>
                  <div className="docs-section-img-div float-start">
                    <img
                      src={Images.document_app_img_dummy}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <div className="docs-section-details float-start">
                    <ul className="mb-0 text-start list-inline">
                      <li>
                        Uses can select the iONclassic <span>QR code,</span>{" "}
                        download, print. Share your car with other enthusiasts.
                      </li>
                      <li>
                        Users can use the <span>SLIDESHOW</span> button to sit
                        back and watch an automated slideshow of the vehicle,
                        use it to show off your ride!
                      </li>
                      <li>
                        Your <span>PROFILE</span> is where your personal account
                        information is kept and can be viewed and or edited.
                      </li>
                      <li>
                        Your <span>PROFILE</span> is where your personal account
                        information is kept and can be viewed and or edited.
                      </li>
                      <li>
                        iONclassic <span>TAGS</span> your shop to all of your
                        restorations and builds so anyone that views these cars
                        can find your shop instantly.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid faq-details-fluid faq-des-fluid">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="faq-details-row">
                <div className="col-12">
                  <h5 className="d-flex align-items-center">
                    <img
                      src={Images.right_arrow_theme_secondary}
                      alt=""
                      className="img-fluid"
                    />
                    WANT TO GET EVENT MORE FUNCTIONALITY AND STORAGE?
                  </h5>
                  <p>
                    iON Classic has been built with the average restorer in
                    mind. Allowing the users to store up to 500 pictures free of
                    charge, allowing for a robust profile and a set of pictures
                    in all of the pre-defined categories, or place pictures in
                    one category, such as a new paint job, engine build or
                    redoing an interior.
                  </p>
                </div>
              </div>
              <div className="faq-details-row border-bottom-details">
                <div className="col-12">
                  <h5 className="d-flex align-items-center">
                    <img
                      src={Images.right_arrow_theme_secondary}
                      alt=""
                      className="img-fluid"
                    />
                    Do I need a subscription to get started?
                  </h5>
                  <ul>
                    <li>
                      Great news – NO, as a user you can use iON Classic without
                      a subscription and create documentation for a
                      single-vehicle undergoing a restoration. You will have
                      access to all the current functionality and enjoy the iON
                      Classic mobile application at no cost to you.
                    </li>
                    <li>
                      The additional iON Classic subscription allows enthusiasts
                      and shops to add an unlimited number of vehicles, pictures
                      as well as add mechanics. If you would like to explore a
                      subscription, please select “Sign up” below.
                    </li>
                    <li>
                      What if I’m a restoration shop, custom builder, or have
                      more than one car to document? iONclassic offers an
                      unlimited subscription per month with no long-term
                      obligation. Want to learn more, select Sign Up and we will
                      explain!
                    </li>
                  </ul>
                </div>
              </div>
              <div className="signup-btn-div">
                <Button onClick={() => navigate("/managers/sign-up")}>
                  Sign Up
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row add-vehicle-row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-11 mx-auto">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 mx-auto">
                  <h2 className="text-uppercase">
                    WHAT <span>IF I SELL MY VEHICLE,</span> WHAT SHOULD I DO
                    WITH MY WITH MY DIGITAL DOCUMENTATION?
                  </h2>
                </div>
              </div>
              <p>
                Users have a few options, first, the iON Classic platform was
                designed as a living digital diary for your vehicle. We made it
                easy for you to transfer your digital documentation at any
                point, simply with an email of the new user, PLEASE NOTE: the
                new user must have an iON Classic account if the vehicle being
                transferred. The options are:
              </p>
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  Transfer the Vehicle Profile
                </li>
                <li className="list-inline-item">|</li>
                <li className="list-inline-item">Delete the Profile</li>
                <li className="list-inline-item">|</li>
                <li className="list-inline-item">Close Your Account</li>
              </ul>
              <div className="row project-overview-row project-overview-update">
                <div className="col-12 col-sm-6 col-md-3 text-center">
                  <div className="row project-demo-card project-demo-update demo-row-1">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 project-img">
                          <img
                            src={Images.vehicle_sell_flow_1}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-12 project-demo-details">
                          <p>Select Details and then the Edit function</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 text-center">
                  <div className="row project-demo-card project-demo-update">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 project-img">
                          <img
                            src={Images.vehicle_sell_flow_2}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-12 project-demo-details">
                          <p>
                            Select Transfer Car Profile Or Delete Car Profile
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 text-center">
                  <div className="row project-demo-card project-demo-update demo-row-1">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 project-img">
                          <img
                            src={Images.vehicle_sell_flow_4}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-12 project-demo-details">
                          <p>
                            Transferring Your Car Profile Just Requires The New
                            User’s Email Address –the New User Must Have an iON
                            Classic Account Already.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 text-center">
                  <div className="row project-demo-card project-demo-update">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 project-img">
                          <img
                            src={Images.vehicle_sell_flow_3}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-12 project-demo-details">
                          <p>
                            Users can sign out or delete their profile by
                            selecting the Profile icon and the gear in the upper
                            left.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row regards-row-main">
            <div className="col-12 col-sm-6 regards-big-img">
              <img src={Images.regards_big_img} alt="" className="img-fluid" />
            </div>
            <div className="col-12 col-sm-6 position-relative">
              <div className="row mx-auto regards-details-row">
                <div className="col-12">
                  <p>
                    We know there are enough pressures in managing a shop, so we
                    set out to make taking pictures, keeping customers informed,
                    engaged, and excited easy and fun. iONclassic automated the
                    task of taking, storing, managing, sharing and most
                    important of all transferring all your customers pictures
                    and documentation.{" "}
                  </p>
                  <p>
                    Added features like our digital showroom and unique QR codes
                    help your shop show potential customers the amazing work
                    your shop produces. Our QR codes drive customers back to
                    your shops when your customers show their cars at shows and
                    events.
                  </p>
                  <p>
                    As your shop uses iONclassic to document your customers
                    projects, you’re not only keeping them engaged, but you’re
                    also building a very valuable set of digital documents. With
                    a simple email address, iONclassic allows you to transfer
                    the ownership of the complete car profile while your shop
                    stays associated and follows along
                  </p>
                  <p>
                    Thank you again for selecting iON Classic for your digital
                    documentation needs, it’s our mission to make automotive
                    digital documentation fun and easy, we look forward to
                    serving you and your customers, we are here to help!
                  </p>
                  <p>
                    Please send all questions to{" "}
                    <Link target="_blank" to={"mailto:info@ionclassic.com"}>
                      info@ionclassic.com
                    </Link>
                  </p>
                </div>
                <div className="col-12 text-end regards-sign-div">
                  <h6>Regards,</h6>
                  <img src={Images.regards_sign} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
