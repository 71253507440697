import React, { useEffect, useState } from "react";
import CardDetailsForm from "./plans/CardDetailsForm";
import SelectPlans from "./plans/SelectPlans";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import stripeConfig from "./stripeConfig";

const stripePromise = loadStripe(stripeConfig.apiKey);

const SelectPlansCardDetails = (props) => {
  const [cardVisible, setCardVisible] = useState(true);

  return cardVisible ? (
    <SelectPlans setCardVisible={setCardVisible} />
  ) : (
    <Elements stripe={stripePromise}>
      <CardDetailsForm {...props} />
    </Elements>
  );
};

export default SelectPlansCardDetails;
