import { SET_SUCCESS_MODAL, REMOVE_SUCCESS_MODAL } from "./successModal.type";

const INITIAL_STATE = {
  successAlertMsg: "",
  successAlertOpen: false,
};

const SuccessAlertReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SUCCESS_MODAL:
      return {
        ...state,
        alertType: action.payload.alertType,
        successAlertMsg: action.payload.successAlertMsg,
        successAlertOpen: action.payload.successAlertOpen,
      };
    case REMOVE_SUCCESS_MODAL:
      return {
        ...state,
        successAlertOpen: false,
      };
    default:
      return state;
  }
};

export default SuccessAlertReducer;
