import React, { useState, useEffect } from "react";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setSuccessAlert } from "../../redux/successModal/successModal.action";
import { Button, Modal, message } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";

const CardDetailsPopup = ({
  visible,
  setVisible,
  handleOk,
  handleCancel,
  subscriptionCanceled,
  setSubscriptionCanceled,
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(visible);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formFields, setFormFields] = useState({
    cardholderName: "",
    cardNumber: false,
    cvv: false,
    expiry: false,
  });

  useEffect(() => {
    const cardNumberElement = elements?.getElement(CardNumberElement);
    const cvvElement = elements?.getElement(CardCvcElement);
    const expiryElement = elements?.getElement(CardExpiryElement);
  
    const handleElementChange = (element, field) => {
      if (element?.complete) {
        setFormFields((prevFields) => ({
          ...prevFields,
          [field]: true,
        }));
      } else {
        setFormFields((prevFields) => ({
          ...prevFields,
          [field]: false,
        }));
      }
    };
  
    cardNumberElement?.on("change", (event) => {
      handleElementChange(event, "cardNumber");
    });
  
    cvvElement?.on("change", (event) => {
      handleElementChange(event, "cvv");
    });
  
    expiryElement?.on("change", (event) => {
      handleElementChange(event, "expiry");
    });
  }, [elements]);

  useEffect(() => {
    setOpen(visible);
    if (!visible) {
      setFormFields((prevFields) => ({
        ...prevFields,
        cardholderName: "",
      }));
    }
  }, [visible]);

  useEffect(() => {
    setOpen(visible);
    if (elements && stripe) {
      const cardNumberElement = elements.getElement(CardNumberElement);
      const cardCvcElement = elements.getElement(CardCvcElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
  
      if (cardNumberElement && cardCvcElement && cardExpiryElement) {
        cardNumberElement.clear();
        cardCvcElement.clear();
        cardExpiryElement.clear();
      }
    }
  }, [visible, elements, stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting || !stripe || !elements) {
      return;
    }

    setIsSubmitting(true);
    

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });

    try {
      // Construct the GraphQL mutation
      const mutation = `
        mutation CreateShopStripeSubscription($input: CreateShopStripeSubscriptionInput!) {
          createShopStripeSubscription(input: $input) {
            clientMutationId
          }
        }
      `;

      // Set up API request headers
      const headers = {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      };

      const input = {
        paymentId: payload.paymentMethod?.id,
        cardHolderName: formFields?.cardholderName,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/graphql`,
        {
          query: mutation,
          variables: { input },
        },
        { headers }
      );

      if (
        response.status === 200 &&
        response.data.data.createShopStripeSubscription !== null
      ) {
        dispatch(
          setSuccessAlert({
            successAlertMsg: "Your Subscription has been upgraded!",
            successAlertOpen: true,
          })
        );
        setOpen(false);
        handleOk();
        setSubscriptionCanceled(!subscriptionCanceled);
        setVisible(false);
        setFormFields({
          cardholderName: "",
        });
        localStorage.setItem("subscriptionCanceled", false);

        sessionStorage.removeItem("profile_info");
      } else if (response.data.errors && response.data.errors[0]) {
        message.error(`Failed to create subscription`);
        // Handle failure with specific error message
      } else {
        const genericErrorMessage = "Unknown error occurred";
        localStorage.setItem("subscriptionCanceled", true);
        message.error(`Failed to create subscription: ${genericErrorMessage}`);
        // Handle failure with a generic error message
      }
    } catch (error) {
      localStorage.setItem("subscriptionCanceled", true);
      message.error("An error occurred while processing your request.");
      // Handle errors here
    } finally {
      setIsSubmitting(false); // Reset form submission state
    }
  };
  const handleBlur = () => {
    console.log("[blur]");
  };

  const handleFocus = () => {
    console.log("[focus]");
  };
  const handleReady = () => {
    console.log("[ready]");
  };
  
  const handleFieldChange = (field, value) => {
    if (field === "cardholderName") {
      // Check if the input value contains only letters and spaces
      if (/^[A-Za-z\s]+$/.test(value) || value === "") {
        setFormFields((prevFields) => ({
          ...prevFields,
          [field]: value,
        }));
      } else {
        setFormFields((prevFields) => ({
          ...prevFields,
          [field]: "",
        }));
      }
    } else if (value.length > 20) {
      // Prevent updating the state if the length exceeds 20 characters
      return;
    } else {
      setFormFields((prevFields) => ({
        ...prevFields,
        [field]: value,
      }));
    }
  };
  
  

 
  const createOptions = (fontSize, padding) => {
    return {
      style: {
        base: {
          fontSize: "20px",
          color: "white",
          letterSpacing: "0.025em",
          border: "2px solid red",

          "::placeholder": {
            color: "#D4D4D4",
            fontSize: "18px",
          },
          padding,
        },
        invalid: {
          color: "#9e2146",
        },
      },
    };
  };
  // const handleClose = () => {
  //   setOpen(false);
  //   setFormFields((prevFields) => ({
  //     ...prevFields,
  //     cardholderName: "",
  //   }));
  // };
  
  return (
    <>
      <Modal
        onOk={handleOk}
        onCancel={handleCancel}
        open={open}
        footer={null}
        closable={false}
        centered
        // maskClosable={false}
      >
       
          {/* <div className="popup-close-button" onClick={handleClose}>
          <CloseOutlined />
          </div> */}
       
        <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mx-auto text-center basic-details-auth">
          <h4
            style={{
              fontSize: "32px",
              marginBottom: "12px",
              fontWeight: 800,
              marginTop: "5px",
            }}
          >
            Enter Cards Details
          </h4>
          <div className="row">
            <div className="col-12">
              <p
                style={{
                  whiteSpace: "normal",
                  maxWidth: "531px",
                  margin: "auto",
                  fontSize: "12px",
                  fontWeight: 500,
                  lineHeight: "32px",
                  color: "#dadada",
                  opacity: 0.7,
                }}
              >
                Enter your Credit Card information - iONclassic uses stripe
                online payment system
              </p>
            </div>
          </div>
        </div>
        <div
          className="common-form"
          style={{ maxWidth: "597px", margin: "0 auto" }}
        >
          <form id="payment-form" onSubmit={handleSubmit}>
            <div style={{ paddingBottom: "32px" }}>
              <label
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: "normal",
                  paddingBottom: 10,
                  color: "white",
                  marginTop: "17px",
                }}
              >
                Cardholder's Name
              </label>
              <input
                className="input-box-popup"
                type="text"
                src=""
                alt=""
                value={formFields.cardholderName} 
                placeholder="Cardholder's Name"
                onChange={(event) =>
                  handleFieldChange(
                    "cardholderName",
                    event.target.value
                  )
                }
              />
            </div>

            <div style={{ paddingBottom: "32px" }}>
              <label
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: "normal",
                  paddingBottom: 10,
                  color: "white",
                }}
              >
                Card Number
              </label>
              <CardNumberElement
                className="input-box-popup"
                onBlur={handleBlur}
                // onChange={handleChange}
                onChange={(event) =>
                  handleFieldChange("cardNumber", event.error ? "" : "filled")
                }
                onFocus={handleFocus}
                onReady={handleReady}
                options={{
                  ...createOptions(),
                  placeholder: "XXXX XXXX XXXX XXXX",
                }}
              />
            </div>

            <div style={{ display: "flex", gap: "10px" }}>
              <div style={{ paddingBottom: "32px", flex: 1 }}>
                <label
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "normal",
                    paddingBottom: 10,
                    color: "white",
                  }}
                >
                  CVV
                </label>
                <CardCvcElement
                  className="input-box-popup"
                  onBlur={handleBlur}
                  onChange={(event) =>
                    handleFieldChange("cvv", event.error ? "" : "filled")
                  }
                  onFocus={handleFocus}
                  onReady={handleReady}
                  options={{
                    ...createOptions(),
                    placeholder: "XXXX",
                  }}
                />
              </div>

              <div style={{ paddingBottom: "32px", flex: 1 }}>
                <label
                  style={{
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "normal",
                    paddingBottom: 10,
                    color: "white",
                  }}
                >
                  Expiry Date
                </label>
                <CardExpiryElement
                  className="input-box-popup"
                  onBlur={handleBlur}
                  onChange={(event) =>
                    handleFieldChange("expiry", event.error ? "" : "filled")
                  }
                  onFocus={handleFocus}
                  onReady={handleReady}
                  options={createOptions()}
                />
              </div>
            </div>

            <div className="col-12">
              <Button
                className="theme-common-btn subscribe-btn w-100"
                type="primary"
                htmlType="submit"
                disabled={
                  !formFields.cardholderName ||
                  !formFields.cardNumber ||
                  !formFields.cvv ||
                  !formFields.expiry
                }
              >
                {isSubmitting ? "Subscribe Now" : "Subscribe Now"}
              </Button>
            </div>
          </form>
        </div>
      </Modal>
      {/* Success Modal */}
    </>
  );
};
export default CardDetailsPopup;
