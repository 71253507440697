import React from "react";
import { Carousel } from "antd";
import { Image as Images } from "../../Images";

const LoginSliderRight = () => {
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  return (
    <Carousel afterChange={onChange}>
      <div>
        <div className="mx-0">
          <div className="p-0 position-relative fix-height">
            <img
              src={Images.login_slider_img_1}
              alt=""
              className="fix-height"
            />
            <div className="auth-carousel-effect position-absolute">
              <p>
                <span>
                  Let iONclassic help your attract new customers from car
                  events, shows, concours around town and around the country.
                </span>
                Use the iONclassic QR code in all your customers cars, when car
                enthusiasts use them your shop is connected.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="mx-0">
          <div className="p-0 position-relative fix-height">
            <img
              src={Images.login_slider_img_2}
              alt=""
              className="fix-height"
            />
            <div className="auth-carousel-effect position-absolute">
              <p>
                Your custom car Tag goes inside your windshield.
                <span>
                  Anyone with a smartphone can scan the Tag to reveal the
                  photos, specs and story of your car.
                </span>
                Car shows, museums, concours & collections just got a whole lot
                cooler.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="mx-0">
          <div className="p-0 position-relative fix-height">
            <img
              src="/assets/images/auth-sliders/Orange Karma.jpg"
              alt="Orange Karma"
              className="fix-height"
            />
            <div className="auth-carousel-effect position-absolute">
              <p>
                <span>
                  Let iONclassic help your attract new customers from car
                  events, shows, concours around town and around the country.
                </span>
                Use the iONclassic QR code in all your customers cars, when car
                enthusiasts use them your shop is connected.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="mx-0">
          <div className="p-0 position-relative fix-height">
            <img
              src="/assets/images/auth-sliders/img0.png"
              alt=""
              className="fix-height"
            />
            <div className="auth-carousel-effect position-absolute">
              <p>
                Your custom car Tag goes inside your windshield.
                <span>
                  Anyone with a smartphone can scan the Tag to reveal the
                  photos, specs and story of your car.
                </span>
                Car shows, museums, concours & collections just got a whole lot
                cooler.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

export default LoginSliderRight;
