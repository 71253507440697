import React, { useEffect } from "react";
import { Button, Dropdown, Space, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import AfterLoginHeader from "../../components/common/AfterLoginHeader";
import { Image as Images } from "../../components/Images";
import { Breadcrumb } from "antd";
import { GET_CAR_CATEGORIES_IMAGE_LIST } from "../../gqloperations/cars/get-car-categories-image.mutation";
import { DeleteOutlined } from "@ant-design/icons";
import { DELETE_ALL_CAR_IMAGE } from "../../gqloperations/cars/delete-all-car-image-mutation";
import { useMutation, useQuery } from "@apollo/client";
import "../../assets/styles/categories.scss";
import Popup2 from "../../components/popup/Popup2";

export default function CarCategoriesList() {
  const navigate = useNavigate();
  const id = useLocation().search;
  const [visible, setVisible] = React.useState(false);
  const [deleteImageIds, setDeleteImageIds] = React.useState();
  const searchParams = new URLSearchParams(id);
  const { data, loading, error } = useQuery(GET_CAR_CATEGORIES_IMAGE_LIST, {
    variables: {
      id: searchParams.get("id"),
    },
    fetchPolicy: "network-only",
  });
  const [deleteAllImageFromCategory] = useMutation(DELETE_ALL_CAR_IMAGE);

  useEffect(() => {
    console.log("data", data?.car);
  }, [data?.car]);

  const handleDeleteCategoryImage = () => {
    console.log("item", deleteImageIds);
    const Ids = deleteImageIds?.images?.map((i) => {
      return i.id;
    });
    console.log("Ids", Ids);
    const payload = {
      ids: Ids,
    };
    deleteAllImageFromCategory({ variables: payload })
      .then((res) => {
        if (res?.data?.removeAllImage?.success) {
          setVisible(false);
          message.success("Deleted successfully!");
          setTimeout(() => {
            window.location.reload();
          }, 150);
        }
      })
      .catch((err) => {
        message.error("something went wrong, please try again!");
      });
  };

  return (
    <div>
      <AfterLoginHeader />
      <div className="container-fluid categories-main-fluid pt-5">
        <div className="container">
          <div className="mb-3">
            <Breadcrumb
              className="iON-breadcrumb"
              separator=">"
              items={[
                {
                  title: (
                    <p
                      onClick={() =>
                        navigate(`/managers/home/details?id=${data?.car?.id}`)
                      }
                    >
                      {data?.car?.model}
                    </p>
                  ),
                },
                {
                  title: "Category",
                },
              ]}
            />
          </div>
          <div className="row categories-row-heading">
            <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
              <h1
                className="mb-0"
                style={{ fontFamily: "Manrope", textTransform: "capitalize" }}
              >
                Categories
              </h1>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-xl-6 col-lg-6">
              <ul className="list-inline mb-0 text-end">
                <li className="list-inline-item">
                  <Button
                    className="add-restoration-btn border-0"
                    onClick={() =>
                      navigate(
                        `/managers/home/details/edit-images?id=${data?.car?.id}`
                      )
                    }
                  >
                    <img
                      src={Images.add_icon_white}
                      alt=""
                      className="img-fluid"
                    />
                    Add Categories
                  </Button>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div className="row">
              {data?.car?.carImageCategories?.map((item, index) => {
                return (
                  <>
                    {item.images?.length > 0 && (
                      <div key={index} className="col-6 col-md-3 mb-3">
                        <div className="category-image-container">
                          <>
                            <Dropdown
                              menu={{
                                items: [
                                  {
                                    label: (
                                      <Button
                                        onClick={() => {
                                          setVisible(true);
                                          setDeleteImageIds(item);
                                        }}
                                        type="link"
                                        danger
                                      >
                                        <DeleteOutlined /> Delete
                                      </Button>
                                    ),
                                    key: "0",
                                  },
                                ],
                              }}
                              overlayClassName="img-menu"
                              trigger={["click"]}
                              placement="bottomLeft"
                            >
                              <Space>
                                <a onClick={(e) => e.preventDefault()}>
                                  <img
                                    className="menu-icon"
                                    src="/assets/icons/menu-dot-icon.svg"
                                  />
                                </a>
                              </Space>
                            </Dropdown>
                            <img
                              className="cover-image"
                              src={item.images[0].imageUrl}
                            />
                            <div
                              onClick={() =>
                                navigate(
                                  `/managers/home/details/categories/images?id=${searchParams.get(
                                    "id"
                                  )}&name=${item?.name}`
                                )
                              }
                              className="image-label text-capitalize"
                            >
                              <div>{item?.name}</div>
                            </div>
                          </>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
          <Popup2
            title={"Are you sure you want to delete category?"}
            description={""}
            visible={visible}
            // handleOk={() => handleLogoutBtn()}
            // handleCancel={() => }
            buttons={[
              {
                text: "Cancel",
                func: () => setVisible(false),
                className: "cancel-button",
              },
              {
                text: "Delete",
                func: () => handleDeleteCategoryImage(),
                className: "subscription-button",
              },
            ]}
          ></Popup2>
        </div>
      </div>
    </div>
  );
}
