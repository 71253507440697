import React, { useState } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { Button, Form, Input, message } from "antd";
import { Link } from "react-router-dom";
import "../assets/styles/contact.scss";
import "../assets/styles/form.scss";
import axios from "axios";
const ContactUs = () => {
  const [disabledSave, setDisabledSave] = useState(true);

  const [form] = Form.useForm();

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(true) ||
      form.getFieldsError().filter(({ errors }) => errors.length).length > 0;
    setDisabledSave(hasErrors);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
    // doLogin(values);
    submitContactForm(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const phoneNumber = "813-576-9335";

  const submitContactForm = (values) => {
    const data = {
      email: values.email,
      name: values.name,
      subject: values.subject,
      message: values.message,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}v1/users/contact_us`, data)
      .then((response) => {
        console.log("response", response);
        message.success("email sent!");
        form.resetFields();
      })
      .catch((err) => {
        message.error(err?.response?.data?.error);
        console.log("err", err);
        form.resetFields();
      });
  };

  return (
    <>
      <Header />
      <div className="container-fluid contact-us-fluid">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div className="row mx-0 contact-form-row">
                <div className="col-12">
                  <h1>Get In Touch</h1>
                </div>
                <div className="col-12 common-form">
                  <Form
                    name="basic"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    layout="vertical"
                    onFieldsChange={handleFormChange}
                    form={form}
                    requiredMark={false}
                  >
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <Form.Item
                          label="Your Name"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your name!",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Your Name" />
                        </Form.Item>
                      </div>
                      <div className="col-12 col-sm-6">
                        <Form.Item
                          label="Your Email"
                          name="email"
                          rules={[
                            {
                              type: "email",
                              message: "Please enter a valid E-mail.",
                            },
                            {
                              required: true,
                              message: "Please input your email!",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Your Email" />
                        </Form.Item>
                      </div>
                      <div className="col-12">
                        <Form.Item
                          label="Subject"
                          name="subject"
                          rules={[
                            {
                              required: true,
                              message: "Please input your subject!",
                            },
                          ]}
                        >
                          <Input placeholder="Enter Subject" />
                        </Form.Item>
                      </div>
                      <div className="col-12">
                        <Form.Item
                          label="Your Message"
                          name="message"
                          rules={[
                            {
                              required: true,
                              message: "Please input your message!",
                            },
                          ]}
                        >
                          <Input.TextArea
                            className="textArea-address textArea-contact"
                            placeholder="I would like to write about....."
                          />
                        </Form.Item>
                      </div>
                      <div className="col-12 margin-top-contact">
                        <Button
                          className="theme-common-btn w-100"
                          // disabled
                          type="primary"
                          htmlType="submit"
                          // loading={loading}
                          disabled={disabledSave}
                        >
                          Send
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div className="contact-form-details row">
                <div className="col-12">
                  <h2 className="text-uppercase">
                    Our team is available to answer any questions you may have
                    about iON Classic, we will get back to you as soon as we
                    can. Thank you!
                  </h2>
                  <ul className="list-inline">
                    <li className="list-inline-item youtube">
                      <Link
                        to={"https://www.youtube.com/@ionclassic "}
                        target="_blank"
                      >
                        YouTube
                      </Link>
                    </li>
                    <li className="list-inline-item instagram">
                      <Link
                        to={"https://www.instagram.com/ion_classic/"}
                        target="_blank"
                      >
                        Instagram
                      </Link>
                    </li>
                    <li className="list-inline-item linkedin">
                      <Link
                        to={"https://www.linkedin.com/company/ion-classic/"}
                        target="_blank"
                      >
                        LinkedIn
                      </Link>
                    </li>
                    <li className="list-inline-item facebook">
                      <Link
                        to={
                          "https://www.facebook.com/people/ION-Classic/100063534910393/"
                        }
                        target="_blank"
                      >
                        Facebook
                      </Link>
                    </li>
                  </ul>
                  <h6>Contact</h6>
                  <p className="mb-0">
                    <Link to={"mailto:info@ionclassic.com"} target="_blank">
                      info@ionclassic.com
                    </Link>
                  </p>
                  <p className="mb-0">
                    {/* <Link to={" "}>813-576-9335</Link> */}
                    <a href={`tel:${phoneNumber}`}>813-576-9335</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
