import React, { useState } from "react";
import Header from "./common/Header";
import "../assets/styles/home.scss";
import { Image as Images } from "./Images";
import BannerLeftSlider from "./home/BannerLeftSlider";
import BannerRightContent from "./home/BannerRightContent";
import { Button, Carousel, Collapse } from "antd";
import Footer from "./common/Footer";
import DownloadApp from "./home/DownloadApp";
import OurCommunity from "./home/OurCommunity";
import ShopHighLight from "./ShopHighLight";

const Home = () => {
  const carouselRef = React.createRef();
  const [open, setOpen] = useState(["1"]);
  const [currentSlide, setCurrentSlide] = useState(0);

  const AcoordBody = (label, icon, img) => {
    return (
      <div
        onClick={() => setOpen([])}
        className="d-flex px-xl-5 pt-xl-5 align-items-center justify-content-between"
      >
        <div className="d-flex align-items-center">
          <div className="me-2">
            <img src={Images[icon]} alt="icon" />
          </div>
          <div>
            <p className="color-white title mb-0">{label}</p>
          </div>
        </div>
        <div>
          <img
            className="p-img"
            src={`/assets/images/right-job/${img}`}
            alt="p img"
          />
        </div>
      </div>
    );
  };
  const rightToolData = [
    {
      key: "1",
      icon: "job_details_icon_0",
      label: "Transfer Restoration Records to New Owners",
      img: "transferRestoration.png",
    },
    {
      key: "2",
      icon: "job_details_icon_1",
      label: "Share and Store Photos of Each Phase of a Restoration",
      img: "shareAndStore.png",
    },
    {
      key: "3",
      icon: "job_details_icon_2",
      label: "Follow Cars, Owners, and Shops.",
      img: "followCars.png",
    },
    {
      key: "4",
      icon: "job_details_icon_3",
      label: "Use iONclassic QR Codes for your shop and customers",
      img: "qrCode.png",
    },
    {
      key: "4",
      icon: "job_details_icon_4",
      label: "No Sign Up Fee for Car Restoration Enthusiasts",
      img: "noSignupFee.png",
    },
    {
      key: "5",
      icon: "job_details_icon_6",
      label: "For Car Owners, Shop Managers, and Mechanics",
      img: "forCarOwners.png",
    },
    {
      key: "6",
      icon: "job_details_icon_4",
      label: "Monthly shop subscriptions available",
      img: "monthlySubscription.png",
    },
  ];
  const item = [];
  rightToolData.map((data, index) => {
    const { label, icon, img } = data;
    const dataComp = {
      key: index + 1,
      showArrow: false,
      label: (
        <div
          onClick={() => setOpen([index + 1])}
          className="opacity-100 fw-normal d-flex align-items-center"
        >
          <div className="me-2">
            <img src={Images[icon]} alt={icon} />
          </div>
          <div>
            <p className="fw-normal opacity-100 color-white title mb-0">
              {data.label}
            </p>
          </div>
        </div>
      ),
      children: (
        <div className="accord-body">{AcoordBody(label, icon, img)}</div>
      ),
    };
    item.push(dataComp);
    return null;
  });

  const fbPostSlider = [
    {
      img: "Restoration.jpg",
      title: "Restoration",
    },
    {
      img: "Custom Build.jpg",
      title: "Custom Build",
    },
    {
      img: "Body Work.jpg",
      title: "Body Work",
    },

    {
      img: "EV.jpeg",
      title: "EV",
    },
    {
      img: "Resto-Mod.jpg",
      title: "Resto-Mod",
    },
  ];

  const onChange = (currentSlide) => {
    console.log(currentSlide);
    setCurrentSlide(currentSlide);
  };

  return (
    <>
      <Header />
      <div className="container-fluid home-main-banner-fluid">
        <div className="row">
          <div className="col-lg-7 col-xl-6">
            <BannerLeftSlider />
          </div>
          <div className="col-lg-5 col-xl-6">
            <BannerRightContent />
          </div>
        </div>
      </div>
      <div className="container-fluid right-tool-for-job-fluid position-relative">
        <div className="container">
          <div className="jobs-des">
            <p>
              iONclassic was designed from the start with a focus on helping
              shops document restorations, customs and builds quickly and
              easily. Use iONclassic's digital showroom and QR codes to promote
              your shop and engage customers.
            </p>
          </div>
          <div className="right-tool-img-div">
            <img
              src={Images.right_tools_mobile_img}
              alt={Images.right_tools_mobile_img}
              className="img-fluid"
            />
          </div>
          <div className="row align-items-center tools-job-details">
            <div className="col-12 col-lg-5 col-xl-5">
              <h2 style={{ maxWidth: "495px" }}>
                <span>THE</span> RIGHT TOOL <span>FOR THE JOB</span>
              </h2>
              <p>
                iONclassic features automate the process of keeping customers
                engaged and informed, let us handle the documentation allowing
                you more time to create masterpieces!
              </p>
            </div>
            <div className="col-12 col-lg-7 col-xl-7">
              <Collapse
                className="how-to-accordion"
                // accordion
                activeKey={open}
                items={item}
              ></Collapse>
            </div>
          </div>
          <div className="mt-5 row automotive-digital-documentation-details">
            <div className="col-12 position-relative">
              <div className="automotive-digital-img-div position-relative float-start">
                <img
                  src={Images.automotive_digital_img}
                  alt="Images.automotive_digital_img"
                  className="img-fluid"
                />
                <div className="trusted-shops-worldwide-div position-absolute">
                  <div className="row mx-0">
                    <div className="col-12 text-center">
                      <h3>Trusted by Shops Worldwide</h3>
                      <div className="trusted-client-list">
                        <span className="client-img-tag">
                          <img
                            src={Images.client_img_5}
                            alt="client_img_5"
                            className="img-fluid"
                          />
                        </span>
                        <span className="client-img-tag">
                          <img
                            src={Images.client_img_1}
                            alt="client_img_1"
                            className="img-fluid"
                          />
                        </span>
                        <span className="client-img-tag">
                          <img
                            src={Images.client_img_2}
                            alt="client_img_2"
                            className="img-fluid"
                          />
                        </span>
                        <span className="client-img-tag">
                          <img
                            src={Images.client_img_3}
                            alt="user 1"
                            className="img-fluid"
                          />
                        </span>
                        <span className="client-img-tag">
                          <img
                            src={Images.client_img_4}
                            alt="user"
                            className="img-fluid"
                          />
                        </span>
                        <span className="client-total-count-div d-flex align-items-center justify-content-center">
                          +2k
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="automotive-digital-details float-start">
                <h2>
                  Automotive{" "}
                  <span className="primary-span">
                    Digital{" "}
                    <span className="documentation-span">Documentation</span>
                  </span>{" "}
                  <span className="in-hand-span">in Your Hand.</span>
                </h2>
                <div className="automotive-digital-steps">
                  <div className="automotive-steps-card">
                    <div className="text-center">
                      <img
                        src={Images.engage_customers_icon}
                        alt="engage_customers_icon"
                        className="img-fluid"
                      />
                      <h6 className="mb-0 text-uppercase">Engage Customers</h6>
                    </div>
                  </div>
                  <div className="automotive-steps-card automotive-steps-card-primary">
                    <div className="text-center">
                      <img
                        src={Images.digital_documentation_icon}
                        alt="digital_documentation_icon"
                        className="img-fluid"
                      />
                      <h6 className="mb-0 text-uppercase">
                        Digital Documentation
                      </h6>
                    </div>
                  </div>
                  <div className="automotive-steps-card">
                    <div className="text-center">
                      <img
                        src={Images.promote_your_shop_icon}
                        alt=""
                        className="img-fluid"
                      />
                      <h6 className="mb-0 text-uppercase">Promote your Shop</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShopHighLight />
      <div className="container-fluid our-community-fluid">
        <div className="container">
          <div className="row">
            <div className="col-12 community-heading">
              <h3>
                Hear From <br /> <span>Our Community</span>
              </h3>
            </div>
          </div>
          <OurCommunity />
        </div>
        <div className="container">
          <div className="row facebook-update-main-row align-items-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 fb-update-heading">
              <h5>
                We post lots of <span>update on Facebook!</span> Let’s connect
                there.
              </h5>
              <Button
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/people/ION-Classic/100063534910393/",
                    "_blank"
                  )
                }
                className="connect-fb-btn d-flex align-items-center justify-content-center"
              >
                <img
                  src={Images.facebook_white_icon_circle}
                  alt=""
                  className="img-fluid"
                />
                Let’s connect on Facebook
              </Button>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 position-relative">
              <Carousel
                dots={false}
                ref={carouselRef}
                slidesToShow={3}
                slidesToScroll={1}
                afterChange={onChange}
                width="390"
                minHeight="390"
                swipe={true}
                infinite={false}
                responsive={[
                  {
                    breakpoint: 1080,
                    minHeight: 390,
                    width: 390,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1,
                    },
                  },
                  {
                    breakpoint: 767,
                    minHeight: 300,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    },
                  },
                ]}
                className="fb-slider-carousel"
              >
                {fbPostSlider.map((data, index) => {
                  return (
                    <div
                      key={index}
                      style={{ marginLeft: "15px" }}
                      className="fb-slider-row"
                    >
                      <div className="fb-slide-inner">
                        <div className="position-relative">
                          <img
                            style={{ minHeight: "300px" }}
                            src={`assets/images/fb-slider/custom/${data?.img}`}
                            alt=""
                            className="img-fluid"
                          />
                          <div className="highlights-slider-content-overlay position-absolute">
                            <div className="row">
                              <div className="col-12">
                                <h5 className="text-uppercase">
                                  {data?.title}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div style={{ marginLeft: "15px" }} className="fb-slider-row">
                  <div className="fb-slide-inner">
                    <div className="position-relative">
                      <div className="highlights-slider-content-overlay position-absolute">
                        <div className="row">
                          <div className="col-12">
                            <h5 className="text-uppercase"></h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel>
              <div className="arrow-with-count">
                <ul className="list-inline justify-content-end">
                  <li className="list-inline-item">
                    <Button
                      onClick={() => {
                        !(currentSlide === 0) &&
                          carouselRef.current.goTo(currentSlide - 1);
                      }}
                      className="bg-transparent border-0 p-0 shadow-none"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="87"
                        height="12"
                        viewBox="0 0 87 12"
                        fill="none"
                      >
                        <path
                          id="Arrow 2"
                          opacity="0.5"
                          d="M0.469673 5.46967C0.176773 5.76256 0.176773 6.23744 0.469673 6.53033L5.24264 11.3033C5.53554 11.5962 6.01041 11.5962 6.3033 11.3033C6.59619 11.0104 6.59619 10.5355 6.3033 10.2426L2.06066 6L6.3033 1.75736C6.59619 1.46447 6.59619 0.989593 6.3033 0.696699C6.01041 0.403806 5.53554 0.403806 5.24264 0.696699L0.469673 5.46967ZM87 5.25L1 5.25V6.75L87 6.75V5.25Z"
                          fill={currentSlide <= 0 ? "#D4D4D4" : "#FFFFFF"}
                          style={{ opacity: currentSlide > 0 && 1 }}
                        />
                      </svg>
                    </Button>
                  </li>
                  <li className="list-inline-item">
                    <Button
                      onClick={() => {
                        !(currentSlide === fbPostSlider.length + 1 - 1) &&
                          carouselRef.current.goTo(currentSlide + 1);
                      }}
                      className="bg-transparent border-0 p-0 shadow-none"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="172"
                        height="12"
                        viewBox="0 0 172 12"
                        fill="none"
                      >
                        <path
                          d="M171.53 6.53035C171.823 6.23745 171.823 5.76258 171.53 5.46968L166.757 0.696714C166.464 0.40382 165.99 0.40382 165.697 0.696714C165.404 0.989607 165.404 1.46448 165.697 1.75737L169.939 6.00001L165.697 10.2427C165.404 10.5355 165.404 11.0104 165.697 11.3033C165.99 11.5962 166.464 11.5962 166.757 11.3033L171.53 6.53035ZM-6.55671e-08 6.75L171 6.75001L171 5.25001L6.55671e-08 5.25L-6.55671e-08 6.75Z"
                          fill={
                            currentSlide === fbPostSlider.length - 2
                              ? "#D4D4D4"
                              : "#FFFFFF"
                          }
                          style={{
                            opacity:
                              currentSlide === fbPostSlider.length - 2
                                ? 0.5
                                : 1,
                          }}
                        />
                      </svg>
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <DownloadApp />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
