import React, { useEffect, useState } from "react";
import { Image as Images } from "../../components/Images";
import { Progress } from "antd";
import AddCar from "../../components/profile-components/AddCar";
import UploadImage from "../../components/profile-components/UploadImage";
import AfterLoginHeader from "../../components/common/AfterLoginHeader";
import { useNavigate } from "react-router-dom";

export default function AddRestorationPage() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [carID, setCarID] = useState("");
  useEffect(() => {
    const carId = localStorage.getItem("carId");
    if (carId && currentStep !== 1) {
      setCurrentStep(currentStep + 1);
    }
  }, []);
  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const goNext = (id) => {
    console.log("cID", id);
    setCarID(id);
    setCurrentStep(currentStep + 1);
  };

  const stepperArr = [
    {
      key: 1,
      component: <AddCar handleNext={goNext} />,
    },
    {
      key: 2,
      component: (
        <UploadImage
          carID={carID}
          handleNext={() => navigate("/managers/home")}
        />
      ),
    },
  ];
  return (
    <>
      {currentStep > 0 && <AfterLoginHeader />}
      <div className="container-fluid auths-main-fluid auths-main-profile overflow-auto">
        <div className="row mx-0 position-relative">
          <div className="auth-shadow-effect">
            <img src={Images.auth_shadow_effect} alt="" className="img-fluid" />
          </div>
          <div className="col-12 col-sm-12 col-xl-9 col-lg-12 col-md-10 mx-auto auth-left-div">
            <div className="row">
              {!currentStep > 0 && (
                <div className="col-12 text-center auth-logo-section">
                  <a href="/managers/home">
                    <img src={Images.auth_logo} alt="" className="img-fluid" />
                  </a>
                </div>
              )}
              <div
                style={{ marginBottom: "-20px" }}
                className="progress-steps-div col-12 col-sm-5 mx-auto"
              >
                <Progress
                  percent={(100 / stepperArr.length) * (currentStep + 1)}
                />
                <div className="steps-count">
                  <span className="active-count">0{currentStep + 1}</span>\
                  <span className="normal-count">0{stepperArr?.length}</span>
                </div>
              </div>
              <div className="col-12">
                {React.cloneElement(
                  stepperArr[currentStep]?.component,
                  { handleNextStep: handleNextStep },
                  null
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
