import React, { useEffect, useState } from "react";
import "./Mechanics.scss";
import { gql } from "@apollo/client";
import AfterLoginHeader from "../common/AfterLoginHeader";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { GET_MECHANICS } from "../../gqloperations/mechanics/getMechanicsList.mutation";
import Popup2 from "../popup/Popup2";
import { DELETE_MECHANICS } from "../../gqloperations/mechanics/remove-mechanics.mutation";
import { message, Button } from "antd";

const Mechanics = () => {
  const shopId = localStorage.getItem("shopId");
  const [getMechanics, {}] = useMutation(GET_MECHANICS);
  const [deleteMechanics, {}] = useMutation(DELETE_MECHANICS);
  const [mechanicsData, setMechanicsData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteMechanicID, setDeleteMechanicID] = useState("");
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const navigate = useNavigate();

  const MANAGERS_SUBSCRIPTION = gql`
    mutation {
      managerSubscription {
        success
      }
    }
  `;

  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `${token}`,
  };

  const [managersSubscription, { error, data }] = useMutation(
    MANAGERS_SUBSCRIPTION,
    {
      context: {
        headers,
      },
    }
  );

  console.log("error:", error);
  console.log("raw response data:", data);

  useEffect(() => {
    managersSubscription()
      .then((response) => {
        console.log("Subscription success:", response.data);
        setHasActiveSubscription(
          response.data?.managerSubscription?.success === true
        );
      })
      .catch((error) => {
        console.error("Error:", error);
        setHasActiveSubscription(false);
      });
  }, []);

  useEffect(() => {
    fetchMechanics();
  }, [shopId]);
  const fetchMechanics = () => {
    if (shopId) {
      getMechanics({ variables: { shopId: String(shopId) } })
        .then((res) => {
          setMechanicsData(res?.data?.findMechanic?.mechanics);
          console.log("res", res?.data?.findMechanic?.mechanics);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getPhoneNumber = (number) => {
    console.log(number.startsWith("+91"));
    if (number.startsWith("+91")) {
      return `(${number.slice(3, 6)}) ${number.slice(6, 9)}-${number.slice(9)}`;
    } else if (number.startsWith("+1")) {
      return `(${number.slice(2, 5)}) ${number.slice(5, 8)}-${number.slice(8)}`;
    } else {
      return number;
    }
  };

  const handleDeleteMechanic = () => {
    deleteMechanics({ variables: { id: deleteMechanicID } })
      .then((res) => {
        message.success("Mechanic Deleted Successfully!");
        setVisible(false);
        setDeleteMechanicID("");
        fetchMechanics();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Check if the user has an active subscription

  return (
    <>
      <AfterLoginHeader />
      <div className="container">
        <div className="mechanics_container">
          <div className="mechanics-main-container">
            <div className="first-content">
              <div className="heading">Mechanics</div>

              <Button
                onClick={() => {
                  if (!hasActiveSubscription) {
                    setVisible(true); // Show the pop-up
                  } else {
                    navigate("/managers/add-mechanics");
                    console.log("Add Restoration");
                  }
                }}
                className="add-restoration-btn border-0"
              >
                + Add Mechanics
              </Button>
            </div>
            {mechanicsData?.length > 0 ? (
              mechanicsData?.map((item, index) => (
                <div className="second-content" key={index}>
                  <div className="inner-box-content">
                    <div className="title-content">
                      {item.firstName} {item.lastName}
                    </div>
                    <div className="number-content">
                      {getPhoneNumber(item.phoneNumber)}
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setDeleteMechanicID(item?.id);
                      setVisible(true);
                    }}
                    className="mechanics-remove"
                  >
                    <span className="me-2">
                      <svg
                        width="18"
                        height="20"
                        viewBox="0 0 18 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M17.2871 3.24297C17.6761 3.24297 18 3.56596 18 3.97696V4.35696C18 4.75795 17.6761 5.09095 17.2871 5.09095H0.713853C0.32386 5.09095 0 4.75795 0 4.35696V3.97696C0 3.56596 0.32386 3.24297 0.713853 3.24297H3.62957C4.22185 3.24297 4.7373 2.82197 4.87054 2.22798L5.02323 1.54598C5.26054 0.616994 6.0415 0 6.93527 0H11.0647C11.9488 0 12.7385 0.616994 12.967 1.49699L13.1304 2.22698C13.2627 2.82197 13.7781 3.24297 14.3714 3.24297H17.2871ZM15.8058 17.134C16.1102 14.2971 16.6432 7.55712 16.6432 7.48913C16.6626 7.28313 16.5955 7.08813 16.4623 6.93113C16.3193 6.78413 16.1384 6.69713 15.9391 6.69713H2.06852C1.86818 6.69713 1.67756 6.78413 1.54529 6.93113C1.41108 7.08813 1.34494 7.28313 1.35467 7.48913C1.35646 7.50162 1.37558 7.73903 1.40755 8.13594C1.54958 9.89917 1.94517 14.8102 2.20079 17.134C2.38168 18.846 3.50498 19.922 5.13206 19.961C6.38763 19.99 7.68112 20 9.00379 20C10.2496 20 11.5149 19.99 12.8094 19.961C14.4929 19.932 15.6152 18.875 15.8058 17.134Z"
                          fill="#FF4F4A"
                        />
                      </svg>
                    </span>
                    Remove
                  </div>
                </div>
              ))
            ) : (
              <div className="second-content">
                <div className="inner-box-content">
                  <div className="title-content">No Mechanics added</div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Popup2
          title={`${!hasActiveSubscription ? "Subscribe!" : "Are you sure!"}`}
          description={`${
            !hasActiveSubscription
              ? "Do subscribe to further add or manage content for your shop on the iONclassic platform."
              : "Click remove if you want to remove mechanic"
          }`}
          visible={visible}
          buttons={[
           
            {
              text: "Cancel",
              func: () => setVisible(false),
              className: "cancel-button",
            },
            {
              text: !hasActiveSubscription ? "Ok" : "Remove",
              func: !hasActiveSubscription
                ? () => {
                  navigate("/managers/manage-subcription");  
                  }
                : () => handleDeleteMechanic(), 
              className: !hasActiveSubscription ? "primary-button" :"subscription-button",
            },
          ]}
        ></Popup2>
      </div>
    </>
  );
};

export default Mechanics;
