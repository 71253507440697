import React from "react";
import "./Subscription.scss";
const Subscription = () => {
  const subscriptionPlans = [
    {
      title: "$09.95/month",
      name: "Popular plan",
      features: [
        "Unlimited cars, mechanics and pictures",
        "Unique QR codes",
        "Digital Showroom",
        "Link social media accounts",
        "Social Media features",
        "eMail transfer for customer data",
      ],
    },
  ];

  return (
    <div className="subscription-container">
      <div className="navbar_container">
        <div style={{ background: "blue", height: "100px" }}>NAVBAR</div>
      </div>
      <div className="subscription-main-container">
        <div className="first-subs-content">
          <div className="subs-title">Select Plan</div>
          <div className="subs-text">
            Need to change this page to look like the initial subscription page
            and add a Cancel Subscription button.
          </div>
        </div>
        {subscriptionPlans.map((plan, index) => (
          <div className="second-subs-content" key={index}>
            <div className="subs-title">{plan.title}</div>
            <div className="subs-name">{plan.name}</div>
            {plan.features.map((feature, index) => (
              <div className="inner-main-card" key={index}>
                <div className="subs-check-icon">&#10003;</div>
                <div>{feature}</div>
              </div>
            ))}
            <div className="subs-button">Get Started</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Subscription;
