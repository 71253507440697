import React from "react";
import { Carousel } from "antd";
import { Image as Images } from "../../Images";
const SignUpSliderRight = () => {
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  return (
    <Carousel afterChange={onChange} autoplay>
      <div>
        <div className="row mx-0">
          <div className="col-12 p-0 position-relative fix-height">
            <img src={Images.auth_slider_img_1} alt="" className="fix-height" />
            <div className="auth-carousel-effect position-absolute">
              <p>
                iONclassic, the only mobile digital documentation app designed
                with automotive restoration shops and custom builders in mind.
                <span>
                  We make taking, storing, managing, and sharing pictures and
                  documentation easy so you can focus on building great cars!
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="row mx-0">
          <div className="col-12 p-0 position-relative fix-height">
            <img src={Images.auth_slider_img_2} alt="" className="fix-height" />
            <div className="auth-carousel-effect position-absolute">
              <p>
                <span>
                  Attract customers to your shop using our unique QR codes.
                </span>
                ONclassic is focused on helping shops and builders with the task
                of keeping customers up to date, engaged and excited about their
                projects!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="row mx-0">
          <div className="col-12 p-0 position-relative fix-height">
            <img
              src="/assets/images/auth-sliders/Green Plymonth.jpg"
              alt="Green Plymonth"
              className="fix-height"
            />
            <div className="auth-carousel-effect position-absolute">
              <p>
                <span>
                  Attract customers to your shop using our unique QR codes.
                </span>
                ONclassic is focused on helping shops and builders with the task
                of keeping customers up to date, engaged and excited about their
                projects!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="row mx-0">
          <div className="col-12 p-0 position-relative fix-height">
            <img
              src="/assets/images/auth-sliders/Cuda Purple.jpg"
              alt="Cuda Purple"
              className="fix-height"
            />
            <div className="auth-carousel-effect position-absolute">
              <p>
                <span>
                  Attract customers to your shop using our unique QR codes.
                </span>
                ONclassic is focused on helping shops and builders with the task
                of keeping customers up to date, engaged and excited about their
                projects!
              </p>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

export default SignUpSliderRight;
