import React, { useState, useEffect } from "react";
import AddCar from "./AddCar";
import UploadImage from "./UploadImage";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { preventNavigationBack, updateSignUpStatus } from "../../utils/helper";
import { useDispatch } from "react-redux";
import { removeSuccessAlert } from "../../redux/successModal/successModal.action";

const AddRestoration = (props) => {
  useEffect(() => {
    preventNavigationBack();
    return () => {
      window.onpopstate = null;
    };
  }, []);
  const state = JSON.parse(localStorage.getItem("state"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [carID, setCarID] = useState("");
  const goNext = (id) => {
    console.log("cID", id);
    setCarID(id);
    setStep(1);
  };
  const handleSkip = () => {
    if (+state?.state > 2) {
      const form = new FormData();
      form.append("state_id", state?.id);
      form.append("state", +state?.state + 1);
      const res = updateSignUpStatus(form);
      console.log("res");
      if (res) {
        state.state = Number(state?.state) + 1;
        localStorage.setItem("state", JSON.stringify(state));
        setTimeout(() => {
          dispatch(removeSuccessAlert());
          window.location.replace("/managers/home");
        }, 2000);
      }
    } else {
      dispatch(removeSuccessAlert());
      window.location.replace("/managers/home");
    }
  };
  return (
    <>
      {step === 0 && (
        <>
          <Button type="link" onClick={() => handleSkip()} className="skip-btn">
            Skip
          </Button>
          <AddCar handleNext={goNext} skip={() => handleSkip()} />
        </>
      )}
      {step === 1 && (
        <>
          <UploadImage
            carID={carID}
            handleNext={() => handleSkip()}
            // handleSave={() => handleSave()}
          />
        </>
      )}
    </>
  );
};

export default AddRestoration;
