import { gql, useQuery } from "@apollo/client";
export const GET_SHOP_IMAGE = gql`
  query getShopProfileImage($id: ID!) {
    shop(id: $id) {
      profileImageUrl
    }
  }
`;
export const GetShopProfileImage = (id) => {
  const { data, loading, error } = useQuery(GET_SHOP_IMAGE, {
    variables: {
      id,
    },
    fetchPolicy: "network-only",
  });

  return {
    data,
    loading,
    error,
  };
};
